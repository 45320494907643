<template>
  <div class="performance">
    <Header :headerGrande="false" :exibeAutorizadas="true" />

    <Breadcrumb :bold="true" paginaAtual="Performance" />

    <div class="performance__components">
      <div>
        <h1 class="titulo-pagina">Performance</h1>
      </div>
      <div class="performance__itens">
        <Rankings />
        <Desempenho />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { Header, Footer, Breadcrumb } from '@/components/elementos'
import Rankings from "@/components/elementos/performance/Rankings.vue"
import Desempenho from "@/components/elementos/performance/Desempenho.vue"
import { mapState, mapActions } from 'vuex'
export default {
  name: "Performance",

  components: {
    Header,
    Footer,
    Breadcrumb,
    Rankings,
    Desempenho,
  },

  computed: {
    ...mapState({
      autorizaTelas: state => state.usuario.autorizaTelas
    }),
  },

  beforeRouteLeave(to, from, next) {
    this.defineTabUsuarios(false);
    next();
  },

  created() {
		if (this.autorizaTelas == false || this.autorizaTelas == null) {
			this.$router.push("/");
      return
		}
	},

  methods: {
    ...mapActions({
      defineTabUsuarios: "usuario/defineTabUsuarios"
    }),
  },
}
</script>

<style lang="scss" scoped>
  .performance {
    margin-top: 80px;
    &__components {
      @media(min-width: 768px) {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    &__itens {
      display: flex;
      flex-direction: column;
      @media(min-width: 768px) {
        flex-direction: row;
      }
    }
    .titulo{
      font-size: 24px;
      font-weight: 600;
      margin: revert;
      text-align: center;
      text-transform: uppercase;
      @media(min-width: 768px) {
        text-align: left;
        margin-left: 10px;
      }
    }
    .subtitulo{
      font-size: 16px;
      color:$laranja;
      margin-top: 69px;
      margin-bottom: 32px;
      margin-left: 40px;
      strong{
        font-weight: revert;
      }
    }

    .h3 {
      margin-left: 10px;
      margin-bottom: 16px;
    }
  }
</style>