<template>
  <div class="modalParabens">
    <div class="modalParabens__container">
      <img src="/img/icons/excluirConta.png" />
      <h1>QUER MESMO SAIR DO PROGRAMA TEC CLUB?</h1>
      <div>
        <div class="modalParabens__mensagem">
          <p>
            Além de não poder concorrer aos prêmios e não ganhar pontos com seus
            serviços, essa exclusão será definitiva e, uma vez excluído, seu
            perfil perderá todo o histórico e acesso aos pontos, além de não
            conseguir retornar ao programa.
          </p>
        </div>
      </div>

      <div class="container-excluir-inputs">
        <div>
          <label>Senha</label>
          <input :type="passwordField" v-model.trim="senha" />
          <img class="pass-hide" @click="showPass" :src="imgShow" alt="" />
        </div>
      </div>

      <div class="modalParabens__btn">
        <button @click="fecharModal" class="continuar">NÃO, QUERO CONTINUAR</button>
        <button @click="excluirConta" class="continuar">SIM, QUERO SAIR</button>
      </div>
    </div>
  </div>
</template>

<script>

import { inativarConta } from "@/api/usuario";
export default {
  name: "ModalExcluirContaMeuPerfil",

  data() {
    return {
      senha: null,
      passwordField: "password",
      imgShow: "/img/icons/hide.png",
    };
  },

  methods: {
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_EXCLUIR_CONTA", {
        ativado: false,
        mensagem: "Excluir",
        tipo: "",
      });
    },

    excluirConta() {
      fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        const idUser =  localStorage.getItem('idUser')
        const data = {
          senha: this.senha,
          id: idUser,
          ip: ip,
        }
        inativarConta(data).then((resp) => {
          if(resp !== undefined) {
            this.$store.commit("interacoes/SET_MODAL_EXCLUIR_CONTA", {
              ativado: false,
              mensagem: "Excluir",
              tipo: "",
            });
            this.$store.commit("interacoes/SET_MODAL_CONTA_EXCLUIDA", {
              ativado: true,
              mensagem: "Excluida",
              tipo: "",
            });
  
            localStorage.removeItem("token");
          }
        })
      });
    },

    showPass() {
      this.passwordField =
      this.passwordField === "password" ? "text" : "password";
      this.imgShow =
      this.imgShow === "/img/icons/hide.png"
        ? "/img/icons/show.png"
        : "/img/icons/hide.png";
    },
  },
};
</script>


<style lang="scss" scoped>
  .pass-hide {
    width: 30px;
    position: absolute;
    right: 10px;
    top: 29px;
    cursor: pointer;
  }
.container-excluir-inputs {
    margin-bottom: 30px;
    position: relative;

    div {
      display: flex;
      flex-direction: column;
      input {
        border: 1px solid $grafite;
        height: 50px;
        border-radius: 5px;
        width: 450px;
        @media(max-width: 768px) {
          width: 300px;
        }
      }

      label {
        margin-bottom: 5px;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
    }

    .input-medio {
      width: 48%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .input-50 {
      width: 48%;
    }

    label {
      font-weight: 500;
      font-size: 14px;
      color: $grafite;
    }
  }
.ps {
  height: 200px;
}
.modalParabens {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    // width: 812px;
    width: 712px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    // padding: 138px;
    padding: 30px 80px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 24px;
    }

    img {
      margin-bottom: 30px;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }
  }

  &__mensagem {
    padding: 0 45px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__btn {
    display: flex;
    align-items: center;

    button {
      width: 210px !important;
      &:nth-child(2) {
        background: transparent;
        color: $laranja;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 24px;
      flex-direction: column;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 260px;
      // margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;
      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>