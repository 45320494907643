import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/plugins/_conf";
import "@/registerServiceWorker";

import ZoomOnHover from "vue-zoom-on-hover";
import vuetify from "./plugins/vuetify";
import VueGtm from "vue-gtm";
import VueApexCharts from 'vue-apexcharts';

Vue.use(ZoomOnHover);
Vue.use(VueGtm, {
	id: "GTM-MQFVHFP",
	enabled: true,
	debug: true,
	loadScript: true
});
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount("#app");
