<template>
  <div>
    <v-expansion-panels v-for="pergunta in faq" :key="pergunta.id">
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ pergunta.pergunta }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-html="pergunta.resposta" />
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import faq from "@/assets/json/faq.json";

export default {
  name: "Accordions",
  components: {
  },
  data() {
    return {
      faq: faq,
    }
  }
}
</script>

<style lang="scss" scoped>
.faq {

  .v-expansion-panel-header {
    justify-content: space-between;
    border: 1px solid $grafite;
    border-radius: 5px;
    font-size: initial;
  }

  .v-item-group {
    margin: 10px 0;
    text-align: left;
  }

  .v-expansion-panel-content {
    line-height: 20px;
    display: block;
    padding: 0 24px;
  }

  .v-expansion-panel--active>.v-expansion-panel-header {
    border: none;
  }

  .v-expansion-panel::before {
    box-shadow: none;
  }

}
</style>