import client from "@/api";
import store from "@/store";
import { _rotaFaleConoscoAssuntos, _rotaFaleConosco } from "@/api/_caminhosApi";

export async function listarAssuntos() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(_rotaFaleConoscoAssuntos())
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function faleConosco(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaFaleConosco(), data)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}
