<template>
  <div>
    <modal-status-usuario v-if="alteraStatus" @fechaModal="alteraStatus = $event.value"
      :usuarioParticipanteId="usuarioParticipanteId" :motivos="motivos" :statusUsuario="statusUsuario" />
    <div class="status-usuario">
      <div class="flex">
        <img :src="iconesImgs.iconeStatusUsuario" class="icone-treinamentos" />
        <h2 class="titulo">Alteração de Status</h2>
        <div class="busca-treinamento desktop">
          <input type="text" class="input-buscar" placeholder="Pesquisar" v-model="buscaUsuario" />
          <button v-if="!buscaFeita" class="btn-buscar" @click="buscarUsuario(1)">
            Buscar
          </button>
          <button v-if="buscaFeita" class="btn-buscar" @click="limparBusca()">
            Limpar busca
          </button>
        </div>
      </div>
      <div class="busca-treinamento mobile">
        <div class="btn-input">
          <input type="text" class="input-buscar" placeholder="Pesquisar" v-model="buscaUsuario" />
          <button class="btn-buscar-mobile" @click="buscarUsuario(1)" v-if="!buscaFeita">
            <img :src="iconesImgs.iconeBusca" alt="Clique para buscar" title="Clique para buscar" />
          </button>
          <button v-if="buscaFeita" class="btn-buscar" @click="limparBusca()">
            Limpar busca
          </button>
        </div>
      </div>
      <div class="barra-titulo">
        <p class="status">STATUS</p>
        <p class="cpf">CPF</p>
        <p class="nome">NOME</p>
        <p class="cargo">CARGO</p>
        <p class="btn-editar sem-fundo"></p>
      </div>
      <h2 class="h2" v-if="usuariosStatus.length == 0">
        Não encontramos usuários para listar.
      </h2>
      <div v-if="usuariosStatus.length > 0">
        <div v-for="(item, index) in usuariosStatus" :key="index" class="item">

          <div v-if="item.status === 'Ativo'" class="icone status ativo">
            <img :src="iconesImgs.iconeIRealizado" />
            {{ item.status }}
          </div>
          <div v-else-if="item.status === 'Inativo'" class="icone status inativo">
            <img :src="iconesImgs.iconeIPendente" />
            {{ item.status }}
          </div>
          <div v-else-if="item.status === 'Somente catálogo'" class="icone status catalogo">
            <img :src="iconesImgs.iconeIPendente" />
            {{ item.status }}
          </div>
          <div v-else-if="item.status === 'Afastado'" class="icone status afastado">
            <img :src="iconesImgs.iconeIPendente" />
            {{ item.status }}
          </div>
          <div v-else-if="item.status === 'Bloqueado'" class="icone status bloqueado">
            <img :src="iconesImgs.iconeIPendente" />
            {{ item.status }}
          </div>

          <p class="cpf">{{ formataCPF(item.cpf) }}</p>

          <p class="nome">{{ retornaPrimeiroUltimonome(item.nome) }}</p>

          <p class="cargo">{{ item.cargo }}</p>

          <p class="btn-editar link" @click="modalStatusUsuario(item.usuarioParticipanteId, item.status)">
            <img :src="iconesImgs.iconeStatusAlteracao">
          </p>
          

        </div>
      </div>
      <transition name="fade-left" mode="out-in">
        <div class="paginacao">
          <button :disabled="arrayBusca.pagina == 1" @click="pagAnterior()">
            Anterior
          </button>
          <p>{{ arrayBusca.pagina }} - {{ totalobjetos }}</p>
          <button @click="proxPagina()">Próxima</button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { formataCPF } from "@/utils/masks.js"
import { buscaListaUsuariosComStatus, listaMotivosAfastamento } from "@/api/usuario"
import { mapState } from "vuex";
import ModalStatusUsuario from "@/components/modal/ModalStatusUsuario.vue"
import iconesImgs from "@/utils/icones.js";
export default {

  components: { ModalStatusUsuario },

  computed: {
    ...mapState({
      autorizadaAtiva: state => state.usuario.autorizadaAtiva
    }),
  },

  data() {
    return {
      alteraStatus: false,
      buscaFeita: false,
      iconesImgs,
      usuarioParticipanteId: "",
      statusUsuario: "",
      pagina: 1,
      totalobjetos: 0,
      ultimoItem: false,
      motivos: [],
      motivosFiltro: [],
      buscaUsuario: "",
      usuariosStatus: [],
      arrayBusca: {
        nome: "",
        cpf: "",
        idStatus: 0,
        pagina: 1,
        qtdPorPagina: 5
      },
      formataCPF
    }
  },
  created() {
    this.buscaUsuariosComStatus(1);
    this.motivosAfastamento();
  },
  watch: {
    autorizadaAtiva() {
      this.buscaUsuariosComStatus(1);
    }
  },
  methods: {
    retornaPrimeiroUltimonome(value) {
      let nome = value.split(" ");
      return `${nome[0]} ${nome[nome.length - 1]}`;
    },
    modalStatusUsuario(id, status) {
      this.alteraStatus = true;
      this.usuarioParticipanteId = id;
      this.statusUsuario = status
    },
    buscarUsuario() {
      let busca = this.buscaUsuario;
      busca = busca.replaceAll(".", "").replace("-", "");
      isNaN(busca) ? this.arrayBusca.nome = busca : this.arrayBusca.cpf = busca;
      this.buscaUsuariosComStatus(1);
      this.buscaFeita = true;
    },
    motivosAfastamento() {
      listaMotivosAfastamento().then((resp) => {
        this.motivos = resp.data
      })
    },
    limparBusca() {
      this.buscaFeita = false;
      this.buscaUsuario = "";
      this.arrayBusca = {
        nome: "",
        cpf: "",
        idStatus: 0,
        pagina: 1,
        qtdPorPagina: 5
      }
      this.buscaUsuariosComStatus(1)
    },
    pagAnterior() {
      this.ultimoItem = false;
      this.arrayBusca.pagina = this.arrayBusca.pagina - 1
      buscaListaUsuariosComStatus(this.arrayBusca).then(resp => {
        this.usuariosStatus = resp.data.lista;
      })
    },
    proxPagina() {
      if (this.arrayBusca.pagina < this.totalobjetos) {
        this.arrayBusca.pagina = this.arrayBusca.pagina + 1
        this.buscaUsuariosComStatus(0)
      }
      if (this.arrayBusca.pagina == this.totalobjetos) {
        this.ultimoItem = true
      }
    },
    buscaUsuariosComStatus(pag) {
      pag == 0 ? '' : this.arrayBusca.pagina = pag;
      this.arrayBusca.autorizadaID = parseInt(this.autorizadaAtiva);
      buscaListaUsuariosComStatus(this.arrayBusca).then(resp => {
        if (resp.data.lista != null)
          this.usuariosStatus = resp.data.lista;
        if (resp.data.paginacao != null) {
          this.totalobjetos = resp.data.paginacao.totalPaginas;
          this.ultimoItem = true;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}

.paginacao {
  display: flex;
  justify-content: center;
  margin: 25px auto 10px auto;
  padding-top: 5px;

  button {
    padding: 6px;
    background: none;
    border: none;
    color: $cinza-interno;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
    transition: 0.3s;

    &:disabled {
      cursor: default;
    }
  }
}

.status-usuario {
  background-color: $azul-claro;
  margin: 5px;
  padding: 15px 0;
  border-radius: 5px;
  position: relative;

  .titulo {
    margin-bottom: 0;
    min-width: 300px;
    font-size: 24px;
    margin-left: 30px;
    width: auto;
  }

  .busca-treinamento {
    width: 100%;
    text-align: right;
    padding: 0 10px;

    @media (max-width: 768px) {
      margin: 16px auto 0 auto;
      width: 100%;
    }

    .btn-input {
      width: 100%;
      max-width: 350px;
      display: flex;
      justify-content: center;
      position: relative;
      margin: auto;
    }

    .input-buscar {
      border: 0;
      border-radius: 5px;
      background: $branco;
      height: 40px;
      padding: 0 10px;

      @media (max-width: 768px) {
        width: 300px;
        margin: 0 auto;
        background: $cinza-claro;
      }
    }

    .btn-buscar {
      color: $grafite;
      height: 40px;
      text-align: center;
      background-color: $neutro6;
      font-size: 14px;
      width: 100px;
      border-radius: 5px;
      margin-left: 10px;
    }

    .btn-buscar-mobile {
      width: 40px;
      position: absolute;
      right: 8%;
      top: 8px;
      background: $cinza-claro;
    }
  }

  .icone-treinamentos {
    margin-left: 20px;
    margin-right: 10px;
  }

  .barra-titulo {
    background: $dark-cinza;
    margin-top: 20px;
    margin-bottom: 25px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: $branco;
    padding: 0 15px;
    justify-content: space-between;
  }

  .status {
    width: 100px;
    
    @media(max-width: 768px) {
      width: 70px;
    }
  }

  .cpf {
    width: 150px;

    @media(max-width: 768px) {
      width: 110px;
    }
  }

  .nome {
    width: 150px;

    @media(max-width: 768px) {
      width: 100px;
    }
  }

  .cargo {
    width: 100px;
    @media(max-width: 768px) {
      width: 70px;
    }
  }

  .btn-editar,
  .icone {
    background: $bg-verde;
    width: 80px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0 5px 5px 0;
    font-size: 12px;
    color: $branco;
    row-gap: 10px;
    margin-right: 15px;
    text-align: center;

    @media (max-width: 768px) {
      width: 70px;
      margin-right: 5px;
    }

    &.status {
      border-radius: 5px 0 0 5px;
    }

    &.ativo {
      background: $verde-claro;
    }

    &.inativo {
      background: $neutro1;
    }

    &.catalogo {
      background: $amarelo-vivo;
    }

    &.afastado {
      background: $laranja-pessego;
    }

    &.bloqueado {
      background: $cor-laranja;
    }

  }

  .link {
    cursor: pointer;
  }

  .btn-editar {
    background: $cinza;
    width: 80px;
    margin-right: 0;
    padding-left: 5px;
    @media(max-width: 768px) {
      width: 50px;
    }
  }

  .sem-fundo {
    background: none;
  }

  .item {
    background: $neutro6;
    margin: 5px 0;
    display: flex;
    align-items: center;
    height: 66px;
    justify-content: space-between;
    border-radius: 5px;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }

  .h2 {
    text-align: center;
    margin: 20px 0;
  }
}
</style>