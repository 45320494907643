export function emailLoginErro() {
    let erro = "";
    const email = this.$v.cadastro.email;
    if (!email.$dirty) {
      return erro;
    }
    if (!email.required) {
      erro = "E-mail é obrigatório";
    } else if (!email.email) {
      erro = "Insira um E-mail válido";
    }
    return erro;
  }
  export function senhaLoginErro() {
    let erro = "";
    const senha = this.$v.cadastro.senha;
    if (!senha.$dirty) {
      return erro;
    }
    if (!senha.required) {
      erro = "Senha é obrigatória";
    }
    return erro;
  }
  export function senhaExcluirErro() {
    let erro = "";
    const senha = this.$v.excluir.senha;
    if (!senha.$dirty) {
      return erro;
    }
    if (!senha.required) {
      erro = "Senha é obrigatória";
    }
    return erro;
  }
  export function cpfErro() {
    let erro = "";
    const cpf = this.$v.cadastro.cpf;
    if (!cpf.$dirty) {
      return erro;
    }
    if (!cpf.required) {
      erro = "CPF é obrigatório";
    }
    return erro;
  }
  export function nomeErro() {
    let erro = "";
    const nome = this.$v.cadastro.nome;
    if (!nome.$dirty) {
      return erro;
    }
    if (!nome.required) {
      erro = "Nome é obrigatório";
    }
    return erro;
  }
  export function cepErro() {
    let erro = "";
    const nome = this.$v.cadastro.cep;
    if (!nome.$dirty) {
      return erro;
    }
    if (!nome.required) {
      erro = "CEP é obrigatório";
    }
    return erro;
  }
  export function enderecoErro() {
    let erro = "";
    const nome = this.$v.cadastro.endereco;
    if (!nome.$dirty) {
      return erro;
    }
    if (!nome.required) {
      erro = "Endereço é obrigatório";
    }
    return erro;
  }
  export function numeroErro() {
    let erro = "";
    const nome = this.$v.cadastro.numero;
    if (!nome.$dirty) {
      return erro;
    }
    if (!nome.required) {
      erro = "Número é obrigatório";
    }
    return erro;
  }
  export function bairroErro() {
    let erro = "";
    const nome = this.$v.cadastro.bairro;
    if (!nome.$dirty) {
      return erro;
    }
    if (!nome.required) {
      erro = "Bairro é obrigatório";
    }
    return erro;
  }
  export function cidadeErro() {
    let erro = "";
    const nome = this.$v.cadastro.cidade;
    if (!nome.$dirty) {
      return erro;
    }
    if (!nome.required) {
      erro = "Cidade é obrigatório";
    }
    return erro;
  }
  export function ufErro() {
    let erro = "";
    const nome = this.$v.cadastro.uf;
    if (!nome.$dirty) {
      return erro;
    }
    if (!nome.required) {
      erro = "UF é obrigatório";
    }
    return erro;
  }
  export function dataErro() {
    let erro = "";
    const data = this.$v.cadastro.dataNascimento;
    if (!data.$dirty) {
      return erro;
    }
    if (!data.required) {
      erro = "Data de Nascimento é obrigatória";
    }
    return erro;
  }
  export function celularErro() {
    let erro = "";
    const celular = this.$v.cadastro.celular;

    if (!celular.$dirty) {
      return erro;
    }
    if (!celular.required) {
      erro = "Celular é obrigatório";
    }
    return erro;
  }
  export function confirmaEmailErro() {
    let erro = "";
    const confirmaEmail = this.$v.cadastro.emailConfirma;
    const email = this.$v.cadastro.email;

    if (!confirmaEmail.$dirty) {
      return erro;
    }
    if (!confirmaEmail.required) {
      erro = "Confirmar o E-mail é obrigatório";
    }
    if (confirmaEmail.$model !== email.$model) {
        erro = "O E-mail está diferente";
      }
    return erro;
  }

  export function confirmaSenhaErro() {
    let erro = "";
    const confirmaSenha = this.$v.cadastro.senhaConfirma;
    const senha = this.$v.cadastro.senha;
    if (!confirmaSenha.$dirty) {
      return erro;
    }
    if (!confirmaSenha.required) {
      erro = "Confirmar a Senha é obrigatório";
    }
    if (confirmaSenha.$model !== senha.$model) {
        erro = "A Senha está diferente";
      }
    return erro;
  }


  export function regulamentoErro() {
    let erro = "";
    const regulamentoPromo = this.$v.cadastro.regulamento;
   
    if (!regulamentoPromo.checked) {
      erro = "";
    }
    return erro;
  }
  export function politicaErro() {
    let erro = "";
    const regulamentoPolitica = this.$v.cadastro.politica;
    if (!regulamentoPolitica.checked) {
      erro = "";
    }
    return erro;
  }

  export function emailEsqueciErro() {
    let erro = "";
    const email = this.$v.emailEsqueci;
    if (!email.$dirty) {
      return erro;
    }
    if (!email.required) {
      erro = "E-mail é obrigatório";
    } else if (!email.email) {
      erro = "Insira um E-mail válido";
    }
    return erro;
  }
  export function assistenciaErro() {
    let erro = "";
    const assistencia = this.$v.cadastro.assistencia;
   
    if (!assistencia.selected) {
      erro = "";
    }
    return erro;
  }