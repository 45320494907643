import client from "@/api";
import store from "@/store";
import {
  _rotaListarUsuarios,
  _rotaGerarRelatorioUsuarios,
  _rotaBloquearUsuario,
  _rotaDesbloquearUsuario,
  _rotaLogin,
  _rotaInformarGanhador,
  _rotaModerar,
  _rotaObterDadosModeracao,
  _rotaEsqueciSenha,
  _rotaCadastrarUsuario,
  _rotaAtivarCadastro,
  _rotaBuscarUsuarioPorId,
  _rotaAtualizarCadastro,
  _rotaCadastroDoUsuario,
  _rotaExtratoTodos,
  _rotaExtratoGanhos,
  _rotaExtratoResgate,
  _rotaExcluirCadastro,
  _rotaListarNotificacao,
  _rotaMarcarNotificacao,
  _rotaValidarNome,
  _rotaValidarIdade,
  _rotaValidarCpf,
  _rotaValidarEmail,
  _rotaRedefinirSenhaMeuPerfil,
  _rotaBlackList,
  _rotaBuscarPerfilUsuario,
  _rotaObterDadosUsuario,
  _rotaObterDadosUsuarioporAutorizada,
  _rotaExtratoPontosAExpirar,
  _rotaUltimaAtualizacaoExtrato,
  _rotaAtualizarStatusUsuario,
  _rotaListaUsuariosComStatus,
  _rotaListaMotivosAfastamento,
  _rotaDownloadPlanilhaUsuarios,
  _rotaExtratoTodosporAutorizada,
  _rotaInfoCampanha
  // _rotaValidarCelular,
} from "@/api/_caminhosApi";
import { limpaCPF } from "@/utils/masks";
//
export function usuarioListarTodos(page, filtros) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("usuario/SET_TABELA", "");
  Object.keys(filtros).map(item => {
    if (filtros[item] == "") {
      filtros[item] = null;
    }
  });
  if (filtros.cpf) {
    filtros.cpf = filtros.cpf !== undefined ? limpaCPF(filtros.cpf) : filtros.cpf;
  }
  client
    .post(`${_rotaListarUsuarios()}?pagina=${page}`, filtros)
    .then(resp => {
      store.commit("usuario/SET_TABELA", resp.data.lista);
      store.commit("usuario/SET_DATA_PAGINACAO", resp.data.paginacao);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export async function downloadPlanilhaUsuarios(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaDownloadPlanilhaUsuarios(), data, {responseType: 'blob'});
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export function bloquearUsuario(idUser, texto) {
  if (!texto) {
    store.commit("interacoes/SET_MODAL", {
      ativado: true,
      mensagem: "Descreva o motivo do bloqueio.",
      tipo: "erro",
    });
    return
  }
  store.commit("interacoes/SET_LOADING", true);
  client
    .patch(_rotaBloquearUsuario(idUser, texto))
    .then(() => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Usuário bloqueado com sucesso!",
        tipo: "sucesso",
      });
      setTimeout(() => {
        document.location.reload(true);
      }, 2000)
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
      this.$store.commit('interacoes/SET_MODAL_BLOQUEIO', {
        ativado: false,
        mensagem: '',
        tipo: ''
      });
    });
}
export function desbloquearUsuario(idUser) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .patch(_rotaDesbloquearUsuario(idUser))
    .then(() => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Usuário desbloqueado com sucesso!",
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function moderarUsuario(idUsuario, descricao, isBloqueado) {
  const idUsuarioAdmin = window.localStorage.getItem('idUser')
  store.commit("interacoes/SET_LOADING", true);
  client
    .put(_rotaModerar(idUsuario, idUsuarioAdmin, descricao, isBloqueado))
    .then(() => {
      if (isBloqueado) {
        store.commit("interacoes/SET_MODAL", {
          ativado: true,
          mensagem: "Usuário bloqueado com sucesso",
          tipo: "sucesso",
        });
      } else {
        store.commit("interacoes/SET_MODAL", {
          ativado: true,
          mensagem: "Usuário desbloqueado com sucesso!",
          tipo: "sucesso",
        });
      }

      setTimeout(() => {
        document.location.reload(true);
      }, 2000)
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
      store.commit("interacoes/SET_LOADING", false);
      this.$store.commit('interacoes/SET_MODAL_BLOQUEIO', {
        ativado: false,
        mensagem: '',
        tipo: ''
      });
    });
}

export function gerarRelatorio() {
  return new Promise((resolve) => {
    store.commit("interacoes/SET_LOADING_RELATORIO", true);
    client
      .get(_rotaGerarRelatorioUsuarios())
      .then(resp => {
        console.log(resp)
        resolve(resp);
      })
      .catch(erro => {
        console.log(erro);
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING_RELATORIO", false);
      });
  });
}
export function esqueciSenha(email) {
  return new Promise((resolve) => {
    store.commit("interacoes/SET_LOADING_RELATORIO", true);
    client
      .get(_rotaEsqueciSenha(email))
      .then(resp => {
        resolve(resp);
      })
      .catch(erro => {
        console.log(erro);
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING_RELATORIO", false);
      });
  });
}

export function obterDadosModeracao(idUsuario) {
  return new Promise((resolve) => {
    store.commit("interacoes/SET_LOADING_RELATORIO", true);
    client
      .get(_rotaObterDadosModeracao(idUsuario))
      .then(resp => {
        store.commit("usuario/SET_DADOS_BLOQUEIO", resp.data);
        resolve(resp.data);
      })
      .catch(erro => {
        console.log(erro);
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING_RELATORIO", false);
      });
  });
}

export function informarGanhador(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(_rotaInformarGanhador(), data)
    .then(() => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Ganhador salvo com sucesso!",
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function validarGanhador(senha, numeroSorte, cpf) {
  store.commit("interacoes/SET_LOADING", true);

  let newData = {
    email: localStorage.email,
    senha: senha,
  };

  let dataGanhador = {
    cpfUsuarioGanhador: cpf,
    numeroOrdem: Number(numeroSorte),
    idUsuarioAdm: JSON.parse(localStorage.idUser),
  };

  client
    .post(_rotaLogin(), newData)
    .then(resp => {
      informarGanhador(dataGanhador);
      store.commit("user/SET_LOGADO", true);
      window.localStorage.setItem("perfilId", resp.data.perfilAcessoPainel.id);
      window.localStorage.setItem("idUser", resp.data.usuario.id);
      window.localStorage.setItem("email", resp.data.usuario.usuario.email);
      window.localStorage.setItem("token", resp.data.token);
    })
    .catch(erro => {
      console.log("erro", erro);
      if (!erro.response || erro.response.status == 404) {
        store.commit("interacoes/SET_MODAL", {
          ativado: true,
          mensagem: "Não foi possível conectar ao servidor.",
          tipo: "erro",
        });
      } else {
        store.commit("user/SET_LOGADO", false);
        store.commit("user/SET_PERFIL", "");
        window.localStorage.removeItem("token");
        store.commit("interacoes/SET_MODAL", {
          ativado: true,
          mensagem: "Senha inválida",
          tipo: "erro",
        });
      }
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}


// Novas chamadas
//Cadastrar usuário
export async function cadastrarUsuario(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaCadastrarUsuario(), data)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.mensagem
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function utualizarCadastroUsuario(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaAtualizarCadastro(), data)
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "sucesso",
      mensagem: resp.data.mensagem
    });
    return data;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.mensagem
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function ativarCadastro(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.put(_rotaAtivarCadastro(), data)
    return resp;
  } catch (error) {
    return error.response.data.message;
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function inativarConta(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.put(_rotaExcluirCadastro(), data)
    return resp;
  } catch (erro) {
    if (erro.response.status === 400) {
      store.commit("SET_ALERTA", {
        show: true,
        tipo: "erro",
        mensagem: "Ocorreu um erro, a senha está fazia ou você digitou sua senha errada."
      });
    }
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarDadosCadastrados(id) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaCadastroDoUsuario()}?idUsuario=${id}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarUsuarioPorId(id) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaBuscarUsuarioPorId()}?idUsuario=${id}`)
    localStorage.setItem("totalPontos", resp.data.totalPontos);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarPerfilUsuario() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaBuscarPerfilUsuario()}`)
    localStorage.setItem("totalPontos", resp.data.totalPontos);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarPerfilUsuarioModal() {
  try {
    const resp = await client.get(`${_rotaBuscarPerfilUsuario()}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  }
}

export async function obterDadosUsuario() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterDadosUsuario()}`)
    localStorage.setItem("totalPontos", resp.data.totalPontos);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterDadosUsuarioPorAutorizada(autorizadaId) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterDadosUsuarioporAutorizada()}/${autorizadaId}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

// extrato


export async function litarTodosExtratos(id, filtro) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaExtratoTodos()}?Id=${id}&Mes=${filtro.mes}&Ano=${filtro.ano}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function litarTodosExtratosporAutorizada(id, filtro) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaExtratoTodosporAutorizada()}?Id=${id}&Mes=${filtro.mes}&Ano=${filtro.ano}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function litarExtratoGanhos(id, filtro) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaExtratoGanhos()}?Id=${id}&Mes=${filtro.mes}&Ano=${filtro.ano}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function litarExtratoResgate(id, filtro) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaExtratoResgate()}?Id=${id}&Mes=${filtro.mes}&Ano=${filtro.ano}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function listaPontosAExpirar(filtro) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaExtratoPontosAExpirar()}/${filtro}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

// notificacoes
export async function listarNotificacoes(id) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaListarNotificacao()}?idUsuario=${id}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function marcarNotificacao(id, idUsuario) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const data = {
      id,
      idUsuario: Number(idUsuario),
    }
    const resp = await client.put(`${_rotaMarcarNotificacao()}?id=${data.id}&idUsuario=${data.idUsuario}`)
    return resp;
  } catch (error) {
    return error.response.data.message;
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

// Alterar senha meu perfil
export async function alterarSenhaMeuPerfil(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.put(_rotaRedefinirSenhaMeuPerfil(), data)
    return resp;
  } catch (error) {
    const { errors } = error.response.data
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: Object.values(errors)[0][0]
    })
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

// Validar Dados
export async function validarNome(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaValidarNome(), data)
    return resp;
  } catch (error) {
    return error.response.data.message;
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function validarIdade(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaValidarIdade(), data)
    return resp;
  } catch (error) {
    return error.response.data.message;
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function validarCpf(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaValidarCpf(), data)
    return resp;
  } catch (error) {
    return error.response.data.message;
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function verificarCpfBlackList(cpf) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaBlackList()}/${cpf}`)
    return resp;
  } catch (error) {
    return error.response.data.message;
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function validarEmail(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaValidarEmail(), data)
    return resp;
  } catch (error) {
    return error.response.data.message;
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function validarCelular(data) {
  if (data.length < 11) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: "O número de celular não parece válido"
    });
  }
}

export async function ultimaAtualizacaoExtrato() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaUltimaAtualizacaoExtrato()}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

//Atualizar Status Usuário
export async function atualiaStatusUsuario(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaAtualizarStatusUsuario(), data)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.mensagem
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscaListaUsuariosComStatus(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaListaUsuariosComStatus(), data)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function listaMotivosAfastamento() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaListaMotivosAfastamento()}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function infoCampanha() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaInfoCampanha()}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}