<template>
    <div class="filtrarBusca">

        <p v-if="mostrarTitulo" class="titulo-mobile">Filtrar busca</p>
        <div class="separador">
            <div class="filtrarBusca__filtro" id="multiselect">
                <p v-if="mostrarTitulo" class="titulo-desktop">Filtrar busca</p>

                <div class="filtrarBusca__filtro__container">
                    <div class="filtrarBusca__filtro__mes">
                        <Multiselect v-model="filtro.mes" label="mes" track-by="id" :options="mesesFiltrado"
                            :multiple="false" :options-limit="200" placeholder="Mês" select-label=" " deselect-label=" "
                            selected-label=" " :searchable="false" />
                    </div>
                    <div class="filtrarBusca__filtro__ano">
                        <input-ano @anoEscolhido="filtro.ano = $event"/>
                        <!-- <Multiselect v-model="filtro.ano" label="ano" track-by="id" :options="anos" :multiple="false"
                            :options-limit="200" placeholder="Ano" select-label=" " deselect-label=" " selected-label=" "
                            :searchable="false" :allow-empty="false" /> -->
                    </div>
                </div>
            </div>
            <div class="button">
                <button @click="graficoFiltrado">BUSCAR</button>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import InputAno from "@/components/formulario/InputAno"
import { mapState } from "vuex";
export default {
    name: "FiltrarDesempenho",
    props: {
        mostrarTitulo: {
            type: Boolean,
            default: true
        },

        tipo: {
            type: Boolean,
            default: false
        },

        dataTipo: Object,

        filtrarGrafico: Function,

        tipoExtrato: Boolean,

        meses: Array,
    },
    components: {
        Multiselect,
        InputAno
    },
    computed: {
        ...mapState({
            autorizadaAtiva: state => state.usuario.autorizadaAtiva
        })
    },
    data() {
        return {
            mesesDownload: null,
            anos: [
                { id: 2021, ano: 2021 },
                { id: 2022, ano: 2022 },
                { id: 2023, ano: 2023 },
                { id: 2024, ano: 2024 },
            ],
            mesesFiltrado: [
                { id: 1, mes: "Janeiro" },
                { id: 2, mes: "Fevereiro" },
                { id: 3, mes: "Março" },
                { id: 4, mes: "Abril" },
                { id: 5, mes: "Maio" },
                { id: 6, mes: "Junho" },
                { id: 7, mes: "Julho" },
                { id: 8, mes: "Agosto" },
                { id: 9, mes: "Setembro" },
                { id: 10, mes: "Outubro" },
                { id: 11, mes: "Novembro" },
                { id: 12, mes: "Dezembro" }
            ],
            filtro: {
                mes: null,
                ano: null,
                origemkpiId: null,
                autorizadaId: null
            }
        };
    },
    watch: {
        autorizadaAtiva() {
            this.graficoFiltrado();
        },
        meses() {
            this.filtraMeses();
        }
    },
    created() {
        this.filtro.ano = this.anos.find(element => element.id == new Date().getFullYear());
        this.filtraMeses();
    },
    methods: {
        filtraMeses() {
            this.graficoFiltrado();
            //Alterado para exibir todos os meses, necessário fazer correção
            // this.filtro.mes = this.mesesFiltrado.splice(0, new Date().getMonth() + 1);
            // this.mesesFiltrado = this.mesesFiltrado;
            this.filtro.mes = this.mesesFiltrado[this.mesesFiltrado.length - 1];
        },
        graficoFiltrado() {
            if (this.tipoExtrato) {
                const data = {
                    mes: this.filtro.mes ? this.filtro.mes.id : 0,
                    ano: this.filtro.ano ? this.filtro.ano.id : 0
                };

                this.$store.commit("extrato/SET_FILTRO_EXTRATO", data);
                this.filtrarGrafico(data);
            } else {
                const data = {
                    mes: this.filtro.mes ? this.filtro.mes.id : 0,
                    ano: this.filtro.ano ? this.filtro.ano.id : 0,
                    origemkpiId: this.filtro.origemkpiId !== null ? this.filtro.origemkpiId.id : 0,
                    autorizadaId: this.filtro.origemkpiId
                };
                this.filtrarGrafico(data);
            }
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.filtrarBusca {
    margin: 0;

    .titulo-mobile {
        display: block;

        @media (min-width: 768px) {
            display: none;
        }
    }

    .titulo-desktop {
        display: none;

        @media (min-width: 768px) {
            display: block;
        }
    }

    @media (min-width: 768px) {
        display: flex;

        .separador {
            display: flex;
            // margin-left: 25px;
        }
    }

    p {
        font-weight: 500;
        font-size: 14px;
        color: $grafite;
        margin-bottom: 16px;
        text-align: left;

        @media (min-width: 768px) {
            margin-bottom: 0px;
        }
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            border: none;
            width: 302px;
            height: 50px;
            background: $bg-verde;
            border-radius: 5px;

            font-weight: 500;
            font-size: 14px;
            color: $branco;

            margin-top: 14px;
            margin-bottom: 30px;

            @media (min-width: 768px) {
                width: 150px;
                height: 50px;
                margin-top: 0px;
                margin-bottom: 0px;
                margin-left: 15px;
            }
        }
    }

    &__filtro {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__container {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &__tipo {
            width: 100%;
            margin-bottom: 5px;
        }

        @media (min-width: 768px) {
            width: 100%;
            flex-direction: row;

            p {
                width: 145px;
            }

            &__container {
                width: auto;
                display: flex;
            }

            &__tipo {
                width: 260px;
                margin-bottom: 0px;
                margin-right: 10px;
            }

            &__mes {
                width: 50%;
                margin-right: 5px;
            }

            &__ano {
                width: 50%;
                margin-left: 5px;
            }
        }

        &__mes {
            width: 145px;
            margin-right: 5px;
        }

        &__ano {
            width: 145px;
            margin-left: 5px;
        }
    }
}
</style>
