<template>
    <div class="mensagem">
        <img v-if="imgParticipante" :class="ladoImagem(participante)" height="50px" width="50px" :src="srcImg()"
            :alt="`Imagem do ${altMsg()}`">
        <div v-else :class="ladoImagem(participante)">
            <span>{{ iniciaisNome }}</span>
        </div>
        <div :class="
            participante
                ? 'mensagem-right-words'
                : 'mensagem-left-words'
        ">
            <div class="container">
                <span :style="alinhamentoParaEsquerda(mensagem)" v-if="mensagem"
                    v-html="verificarMensagem(mensagem)"></span>
                <div v-if="anexos.length">
                    <span v-for="anexo in anexos" :key="anexo.id">
                        <img width="100%" :src="anexo.arquivo" alt="">
                    </span>
                </div>
            </div>
            <span class="horario">{{ tempoMsg }}</span>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "ChamadosChatMensagem",

    props: {
        participante: {
            required: true,
            type: Boolean
        },
        mensagem: {
            required: false,
            type: String
        },
        anexos: {
            required: false,
            type: Array
        },
        tempoMsg: {
            required: true,
            type: String
        }
    },

    computed: {
        ...mapState({
            iniciaisNome: (state) => state.usuario.iniciaisNome,
        }),
    },

    data() {
        return {
            imgParticipante: localStorage.getItem("fotoUsuario"),
            imgAtendente: require('@/assets/images/tema/campanha-dois.png'),
        }
    },

    methods: {
        ladoImagem(lado) {
            return lado ? 'mensagem-right-img' : 'mensagem-left-img'
        },
        alinhamentoParaEsquerda(mensagem) {
            return mensagem && 'text-align: start;'
        },
        verificarMensagem(mensagem) {
            return mensagem = mensagem.replace(/\n/g, '<br>')
        },
        altMsg() {
            return this.participante ? 'participante' : 'atendente'
        },
        srcImg() {
            return this.participante ? this.imgParticipante : this.imgAtendente
        }
    }

}
</script>

<style lang="scss" scoped>
.mensagem {
    width: 100%;
    display: flex;
    gap: 12px;

    &-left,
    &-right {
        &-img {
            border-radius: 50%;
            height: 50px;
            width: 50px;
        }

        &-words {
            width: 90%;
            overflow: hidden;

            .container {
                width: fit-content;
                background: $neutro6;
                border-radius: 10px;

                span {

                    &:first-child {
                        display: inline-block;
                        padding: 8px;
                        max-width: 100%;
                        overflow: hidden;
                    }

                }
            }

            .horario {
                display: inline-block;
                margin-top: 8px;
            }

        }
    }

    &-right {
        &-img {
            order: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $neutro4;
            color: white;
            font-size: 1.6rem;
        }

        &-words {
            text-align: end;

            .container {
                margin-left: auto;
            }

            span {
                &:last-child {
                    text-align: end;
                }
            }
        }
    }
}
</style>