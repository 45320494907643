<template>
  <div class="carrouselHome">
    <vueper-slides :autoplay="slides.length > 1 ? true : false" v-if="dadosMock != undefined">
      <vueper-slide v-if="dadosMock">
        <template v-slot:content>
          <div class="carrousel-um flex-center-row">
            <img class="mobile" src="~@/assets/images/tema/carrousel.png" />
            <img class="desktop" src="~@/assets/images/tema/tec-club-brastemp.png" />
            <a target="_blank" href="https://api.whatsapp.com/send?phone=5511989340994">
              <button>
                <img src="/img/icons/wp.png" alt="Whatsapp" /> Fale com a HELPTEC
              </button>
            </a>
          </div>
        </template>
      </vueper-slide>
      <vueper-slide v-else v-for="slide in slides" :key="slide.id">
        <template v-slot:content>
          <div class="carrousel-um flex-center-row">
            <a target="_blank" :href="slide.link"><img class="mobile" :src="slide.imagemMobile" /></a>
            <a target="_blank" :href="slide.link"><img class="desktop" :src="slide.imagemDesktop" /></a>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=5511989340994" v-if="slide.id==1">
              <button>
                <img src="/img/icons/wp.png" alt="Whatsapp" /> Fale com a HELPTEC
              </button>
            </a>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { listarBannerHome } from "@/api/banner-home";
export default {
  name: "Carrousel",

  components: {
    VueperSlides,
    VueperSlide,
  },

  data() {
    return {
      slides: [],
      dadosMock: undefined,
      btnWhatsapp: true
    };
  },
  created() {
    this.buscarBanner();
  },

  methods: {
    posicaoCarrossel(a, b) {
      return a.posicaoCarrocel - b.posicaoCarrocel;
    },
    buscarBanner() {
      listarBannerHome().then((resp) => {
        this.slides = resp.data.sort(this.posicaoCarrossel);
        if (resp.data.length > 0) {
          this.dadosMock = false;
          return;
        }
        this.dadosMock = true;
      });

    },
  },
};
</script>

<style lang="scss" scoped>
.carrouselHome {

  @media (min-width: 768px) {
    margin-top: 0px;
  }

  .carrousel-um {

    //background: $bg-laranja;
    @media (min-width: 768px) {
      height: 274px;
      position: relative;
      display: flex;
      // justify-content: center;
    }

    .mobile {
      width: 100%;

      @media (min-width: 768px) {
        display: none;
      }
    }

    .desktop {
      display: none;

      @media (min-width: 768px) {
        width: 100%;
        display: flex;
      }
    }

    button {
      @media (max-width: 768px) {
        bottom: 15px;
        right: 2px;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: red;
      position: absolute;
      bottom: 20px;
      right: 35px;
      z-index: 1;
      width: 200px;
      height: 40px;
      border-radius: 5px;
      background: $bg-btn-padrao;
      border: none;
      cursor: pointer;

      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: $branco;

      &:hover {
        opacity: 0.9;
      }

      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>
