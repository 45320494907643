import client from "@/api";
import store from "@/store";
import { _rotaAcessarCatalogo, _rotaPontosUsuario } from "@/api/_caminhosApi";

export async function irParaCatalogo() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaAcessarCatalogo()}`);
    return resp;
  } catch (error) {
    let resError = error.response.data.mensagem;

    if (error.response.status == 429) {
      resError = "Recurso bloqueado! Quantidade de requisições por minuto foi excedida. Tente novamente em 10m."
    }

    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: resError
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function consultaPontosUsuario() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaPontosUsuario()}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}