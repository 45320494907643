<template>
    <div class="modal">
        <div class="modal__container">
            <div class="msg-modal">
                <img :src="ImgModal" />
                <div class="modal__btn box-botoes">
                    <button class="modal__btn__btn-enviar" @click="verPerformance()">
                        CLIQUE AQUI E COMECE
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
      
<script>
import ImgModal from "@/assets/images/tema/modal-performance.png";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            ImgModal
        };
    },

    methods: {
        ...mapActions({
            defineAnuncioStatusUsuario: "usuario/defineAnuncioStatusUsuario",
        }),
        verPerformance() {
            this.defineAnuncioStatusUsuario();
            localStorage.setItem('verModalNovidade', 1);
            this.$router.push('/performance');
        }
    },
};
</script>
    
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.msg-modal {
    background: #f3f1f2;
    text-align: center;
    height: 560px;
    margin-bottom: -15px;

    @media (max-width: 768px) {
        width: auto;
        height: auto;
        height: 360px;
        overflow: hidden;

        img {
            width: auto;
            overflow: none;
        }
    }
}

.modal__container {
    padding: 0;
    background: $cor-gelo;


    @media (max-width: 768px) {
        padding: 0;
        height: auto;
        max-height: 100%;
        overflow: hidden;
        width: 100%;

        img {
            width: 100%;
        }
    }

    img {
        margin-bottom: 0;
        margin-bottom: -15px;
    }
}
</style>