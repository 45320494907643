// CAMINHOS API
export function _imagemMobile(img) {
	return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Comunicacao/ImagemMobile/${img}`;
}
export function _imagemDesktop(img) {
	return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Comunicacao/ImagemDesktop/${img}`;
}
export function _imagemCupom(img) {
	return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Upload/UploadCupom/${img}`;
}
//
// export function _rotaEsqueciSenha() {
//   return "/Authentication/LoginPainel";
// }
//
// ############ COMUNICAÇÃO ############
//
// COMUNICAÇÃO / BANNERS
export function _rotaBuscarBanners() {
	return "/Comunicacao/ObterTodos";
}
export function _rotaBuscarBannerPorId() {
	return "/Comunicacao/ObterPorId";
}
export function _rotaExcluirBanners() {
	return "/Comunicacao/Inativar";
}
export function _rotaAtualizarBanners() {
	return "/Comunicacao/Atualizar";
}
export function _rotaEnviarBanners() {
	return "/Comunicacao/Adicionar";
}
//
// COMUNICAÇÃO / FIQUE LIGADO
export function _rotaBuscarFiqueLigado() {
	return "Fique Ligado";
}
export function _rotaExcluirFiqueLigado() {
	return "Fique Ligado";
}
export function _rotaAtualizarFiqueLigado() {
	return "Fique Ligado";
}
export function _rotaEnviarFiqueLigado() {
	return "Fique Ligado";
}
//
// COMUNICAÇÃO / POPUP
export function _rotaBuscarPopup() {
	return "PopUp";
}
export function _rotaExcluirPopup() {
	return "PopUp";
}
export function _rotaAtualizarPopup() {
	return "PopUp";
}
export function _rotaEnviarPopup() {
	return "PopUp";
}
//
// CUPONS
export function _rotaCadastrarCupom() {
	return "/Cupom/Cadastrar";
}
export function _rotaAtualizarCupom() {
	return "/Cupom/Atualizar";
}
export function _rotaListarCupomStatus() {
	return "/Cupom/ListarCupomStatus";
}
export function _rotaListarCupomPorStatus() {
	return "/Cupom/Listar";
}
export function _rotaListarCupomObterDetalhes() {
	return "/Cupom/ObterDetalhes";
}
export function _rotaListarTodosCupons() {
	return "/Cupom/Listar";
}
export function _rotaListarTodosCuponsCompletos() {
	return "/Cupom/ListarTodosCompleto";
}
export function _rotaListarProdutosPorCupom() {
	return "/CupomProduto/ListarProdutosPorCupom";
}
//
// USUÁRIO
export function _rotaListarUsuarios() {
	return "/UsuarioParticipante/Listar";
}
export function _rotaListarPremiacaoInstantanea() {
	return "/UsuarioParticipante/ListarPremiacaoInstantanea";
}
export function _rotaHistoricoPremiacaoInstantanea() {
	return "/HistoricoPremiacaoInstantanea/ModerarPremioInstantaneo";
}
export function _rotaAtualizarStatusPremiacaoInstantanea() {
	return "/HistoricoPremiacaoInstantanea/AtualizarStatusPremiacaoInstantaneaLiberada";
}
export function _rotaBloquearUsuario(idUsuario, motivoBloqueio) {
	return `/Usuario/Bloquear?idUsuario=${idUsuario}&motivoBloqueio=${motivoBloqueio}`;
}
export function _rotaDesbloquearUsuario(idUsuario) {
	return `/Usuario/Desbloquear?idUsuario=${idUsuario}`;
}
export function _rotaModerar(idUsuario, idUsuarioAdmin,	descricao, isBloqueado) {
	return `/Usuario/Moderar?idUsuario=${idUsuario}&idUsuarioAdmin=${idUsuarioAdmin}&descricao=${descricao}&isBloqueado=${isBloqueado}`;
}
export function _rotaInformarGanhador() {
	return `/UsuarioParticipante/InformarGanhadorSorteio`;
}
export function _rotaObterDadosModeracao(idUsuario) {
	return `/UsuarioParticipante/ObterDadosModeracao?idUsuario=${idUsuario}`;
}
export function _rotaAtualizarStatusUsuario() {
	return `/UsuarioParticipante/AtualizarStatusUsuario`;
}
export function _rotaListaUsuariosComStatus() {
	return `/UsuarioParticipante/ObterListaUsuariosStatus`;
}
export function _rotaListaMotivosAfastamento() {
	return `/Afastamento/ComboStatusAfastamento`;
}
export function _rotaDownloadPlanilhaUsuarios(){
	return "/Download/DownloadPlanilhaUsuarios";
}
export function _rotaInfoCampanha(){
	return "/UsuarioParticipante/InformacoesPrograma";
}
//
// SAC
export function _rotaListarSac() {
	return "/Sac/Listar";
}
export function _rotaListarSacStatus() {
	return "/Sac/ListarSacStatus";
}
export function _rotaListarSacAssuntos() {
	return "/Sac/ListarSacAssunto";
}
export function _rotaAtualizarStatusOcorrencia() {
	return "/Sac/AtualizarStatusOcorrencia";
}
//
// NUMERO
export function _rotaListarNumeros() {
	return "/NumeroOrdem/Listar";
}

//Upload premiacao
export function _rotaEnviarPlanilhaPremiacao() {
	return "/Upload/EnviarPlanilhaPremiacaoInstantanea";
}

//Agenda Premiacao
export function _rotaVerificaPlanilha() {
	return "/AgendaPremiacao/ExisteAgenda";
}

//Relatorios

export function _rotaGerarRelatorioGanhadores() {
	return "/Relatorio/Ganhadores";
}
export function _rotaGerarRelatorioUsuarios() {
	return "/Relatorio/Usuarios";
}
//  export function _rotaEsqueciSenha(email) {
//   return `/Authentication/EsqueciMinhaSenha?email=${email}`;
// }
export function _rotaGerarRelatorio() {
	return "/Relatorio/NumerosDaSorte";
}
export function _rotaGerarRelatorioCupons() {
	return "/Relatorio/Cupons";
}

// assistencias
export function _rotaBuscarAssistencias() {
	return "/UsuarioParticipante/Assistencias";
}

// autorizadas
export function _rotaBuscarAssistenciasAutorizadas(id) {
	return "/Autorizada/ComboAutorizada?idTipo=" + id;
}

// Cadastro usuário
export function _rotaCadastrarUsuario() {
	return "/UsuarioParticipante/CadastrarUsuario";
}

export function _rotaBuscarUsuarioPorId() {
	return "/UsuarioParticipante/buscarPorId";
}

export function _rotaAtualizarCadastro() {
	return "/UsuarioParticipante/AtualizarCadastro";
}

export function _rotaCadastroDoUsuario() {
	return "/UsuarioParticipante/BuscarCadastroPorId";
}


export function _rotaComboCargoAutorizada(id) {
	return "/UsuarioParticipante/ComboCargoPorAutorizada?id=" + id;
}

export function _rotaAssistenciaFranquia() {
	return "/Autorizada/ComboTipo";
}

export function _rotaListaCargos(id) {
	return "/Usuario/ComboCargoPorTipoAutorizadaId?tipoAutorizadaId=" + id;
}

//Perfil Usuário

export function _rotaBuscarPerfilUsuario() {
	return "/UsuarioParticipante/ObterPerfilUsuario";
}

export function _rotaObterDadosUsuario() {
	return "/UsuarioParticipante/ObterDados";
}

export function _rotaObterDadosUsuarioporAutorizada() {
	return "/UsuarioParticipante/ObterDadosPorAutorizada";
}

// extrato
export function _rotaExtratoTodos() {
	return "/ExtratoPontuacao/ListarTodos";
}

export function _rotaExtratoTodosporAutorizada() {
	return "/ExtratoPontuacao/ListarTodosPorAutorizada";
}

export function _rotaExtratoGanhos() {
	return "/ExtratoPontuacao/ListarGanhos";
}

export function _rotaExtratoResgate() {
	return "/ExtratoPontuacao/ListarResgate";
}

export function _rotaExtratoPontosAExpirar() {
	return "/ExpiracaoPontos/GetPontosAExpirar";
}

// Ativar cadastro
export function _rotaAtivarCadastro() {
	return "/Authentication/ConfirmarEmail";
}

export function _rotaExcluirCadastro() {
	return "/Usuario/Inativar";
}

// Notificacao
export function _rotaListarNotificacao() {
	return "/Notificacoes/ObterPorUsuario";
}

export function _rotaMarcarNotificacao() {
	return "/Notificacoes/Visualizar";
}

// Validacao
export function _rotaValidarNome() {
	return "/Usuario/ValidarNome";
}

export function _rotaValidarIdade() {
	return "/Usuario/ValidarIdade";
}

export function _rotaValidarCpf() {
	return "/Usuario/ValidarCpf";
}

export function _rotaValidarEmail() {
	return "/Usuario/ValidarEmail";
}

export function _rotaValidarCelular() {
	return "/Usuario/ValidarContato";
}

// LOGIN
export function _rotaLogin() {
	return "/Authentication/LoginParticipante";
}

export function _rotaLogin2fa() {
	return "/Authentication/Login2FAParticipante";
}

// Esqueci senha
export function _rotaEsqueciSenha() {
	return "/Authentication/EsqueciMinhaSenha";
}

export function _rotaRedefinirSenha() {
	return "/Authentication/AlterarSenha";
}

export function _rotaRedefinirSenhaMeuPerfil() {
	return "/Usuario/AterarSenha";
}

// FaleConosoco
export function _rotaFaleConoscoAssuntos() {
	return "/Sac/ListarSacAssunto";
}

export function _rotaFaleConosco() {
	return "/Sac/CadastrarOcorrencia";
}

// Performance
export function _rotaObterRanking() {
	return "/Performance/ObterRanking";
}

export function _rotaObterGraficoDesempenho() {
	return "/Performance/ObterGraficoDesempenho";
}

export function _rotaObterRankingNacional() {
	return "/HistoricoPerformance/ListarRanking";
}

export function _rotaObterKpis() {
	return "/Performance/ObterKpis";
}

export function _rotaObterUsuariosPorStatus() {
	return "/UsuarioParticipante/ObterQtdUsuariosPorStatus";
}

export function _rotaObterGrafico() {
	return "/Performance/ObterDesempenho";
}

export function _rotaObterTreinamentos() {
	return "/Performance/ObterTreinamentos";
}

export function _rotaObterGraficoTreinamento() {
	return "/Treinamentos/ObterGraficoTreinamento";
}

export function _rotaObterTreinamentoEquipe() {
	return "/Performance/ObterTreinamentosEquipe";
}

export function _rotaUltimaAtualizacaoPerformance() {
	return "/Performance/ObterUltimaAtualizacaoPerformance";
}

export function _rotaUltimaAtualizacaoExtrato() {
	return "/Performance/ObterUltimaAtualizacaoExtrato";
}

export function _rotaObterPerformance() {
	return "/Performance/ObterPerformances";
}

export function _rotaObterKpisPorAutorizada	() {
	return "/Performance/ObterKpisPorAutorizada";
}

export function _rotaDownloadPlanilhaPerformance(){
	return "/Download/DownloadPlanilhaPerformance";
}

export function _rotaObterMesesComPerformance(){
	return "/Performance/ObterMesesComPerformance";
}

export function _rotaObterUltimaAtualizacaoPerformance(){
	return "/Performance/ObterUltimaAtualizacaoPerformance";
}

export function _rotaObterComboAtualizacaoPerformance(){
	return "/Performance/ObterDataPadraoComboPerformance";
}

// Treinamentos
export function _rotaListarTreinamentos() {
	return "/Treinamentos/ListarBanner";
}

export function _rotaListarTreinamentosPorAutorizada() {
	return "/Treinamentos/ListarBannerPorAutorizada";
}

export function _rotaDownloadPlanilhaTreinamento(){
	return "/Download/DownloadPlanilhaTreinamento";
}

export function _rotaObterMesesComTreinamento(){
	return "/Performance/ObterMesesComTreinamento";
}


// Campanhas
/* Rota alterada em 05/08/2022
export function _rotaListarCampanhas() {
	return "/Campanha/Listar";
}
*/

export function _rotaListarCampanhas() {
	return "/Campanha/ListarTodasCampanhas";
}

export function _rotaListarCampanhasConsultor() {
	return "/Campanha/ListarTodasCampanhasPorAutorizada";
}

export function _rotaParticiparDaCampanha() {
	return "/Campanha/Aceitar";
}

export function _rotaAcessarCampanha() {
	return "/Campanha/Detalhes";
}

export function _rotaAcessarRanking() {
	return "/Campanha/ObterRanking/";
}
export function _rotaAcessarCatalogo() {
	// Rota alterada em 27/02/2024
	return "/Sso/authentication";

	// return "/Sso/eaipremios";
}

export function _rotaPontosUsuario(){
	return "/Sso/balance"
}

export function _rotaEspelho() {
	return "/Authentication/AcessoEspelho";
}
export function _rotaBlackList() {
	return "/UsuarioParticipante/VerificarBlackList";
}
export function _rotaListarBannerHome() {
	return "/Banner/ObterBannerPerfil";
}

// Chamados

export function _rotaListarChamados(data) {
	return `/Sac/ListarSacOcorrencias?pagina=${data.pagina}&quantidadeItemPorPagina=${data.qtdPorPagina}`
}

export function _rotaListarMensagensChamado(id, pagina, qtdPorPagina) {
	return `/Sac/ListarSacMensagens/${id}/${pagina}/${qtdPorPagina}`;
}

export function _rotaListarStatusChamado() {
	return `/Sac/ListarSacStatus`;
}

export function _rotaCadastrarMensagemChamado() {
	return "/Sac/CadastrarMensagem";
}