<template>
  <div class="breadcrumb">
    <p class="breadcrumb__path">
      <a href="/" :class="{ bold: bold }">Home</a> /
      <span v-if="caminhoUm" :class="{ bold: bold }"> {{ caminhoUm }} / </span> 
      <span v-if="caminhoDois" :class="{ bold: bold }"> {{ caminhoDois }} / </span> 
      <span class="paginaAtual">{{ paginaAtual }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",

  props: {
    bold: Boolean,
    caminhoUm: String,
    caminhoDois: String,
    paginaAtual: String,
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 100%;
  max-width: 1200px;
  margin: 0  auto;

  p {
    margin: 12px 0 35px 24px;
    font-family: Montserrat;
    font-size: 12px;
    color: $grafite;
    @media(min-width: 768px) {
      margin: 17px 0 30px 0px;
    }
    .bold {
      font-weight: bold !important;
      text-decoration: none;
      color:black;
    }
  }
}
</style>