export default {
  namespaced: true,
  state: {
    estaLogado: false,
    perfilUsuario: "", // 1: Master, 2: Admin, 3: SAC
    idUsuario: "",
    idUsuarioBloqueado: "",
    notificacoesNaoVisualizadas: 0
  },
  mutations: {
    SET_LOGADO(state, logado) {
      state.estaLogado = logado;
    },
    SET_PERFIL(state, perfil) {
      state.perfilUsuario = perfil;
    },
    SET_ID_USUARIO(state, id) {
      state.idUsuario = id;
    },
    SET_ID_USUARIO_BLOQUEADO(state, id) {
      state.idUsuarioBloqueado = id;
    }
  }
};
