import client from "@/api";
import store from "@/store";
import {
  _rotaListarTreinamentos,
  _rotaListarTreinamentosPorAutorizada,
  _rotaDownloadPlanilhaTreinamento,
  _rotaObterMesesComTreinamento 
} from "@/api/_caminhosApi";

export async function listarTreinamentos() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(_rotaListarTreinamentos());
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function listarTreinamentosPorAutorizada(idAutorizada) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaListarTreinamentosPorAutorizada()}?idAutorizada=${idAutorizada}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterMesesComTreinamento(ano, autorizadaId) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterMesesComTreinamento()}/${autorizadaId}/${ano}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function downloadPlanilhaTreinamento(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaDownloadPlanilhaTreinamento(), data, {responseType: 'blob'});
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}