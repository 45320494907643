import axios from "axios";
import store from "@/store";
import router from "@/router";

const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE
});

function exibirAlertas(error) {
	if (error.response && error.response.status == 401) {
		setTimeout(() => {
			store.commit("SET_ALERTA", {
				show: true,
				tipo: "erro",
				mensagem: "Sua sessão expirou, faça login novamente"
			});
		}, 400);

		localStorage.removeItem("token");
		localStorage.removeItem("fotoUsuario");
		store.commit("user/SET_LOGADO", false);
		router.push("/login");
	} 
	// else if (error.response && error.response.status == 500) { 
	// 	store.commit("SET_ALERTA", {
	// 		show: true,
	// 		mensagem: "O servidor encontrou um erro. Tente novamente mais tarde",
	// 		tipo: "erro"
	// 	});
	// 	localStorage.removeItem("token");
	// 	localStorage.removeItem("fotoUsuario");
	// 	store.commit("user/SET_LOGADO", false);
	// 	router.push("/login");
	// }

	
	// else {
	// 	store.commit("SET_ALERTA", {
	// 		show: true,
	// 		mensagem: "O servidor encontrou um erro. Tente novamente mais tarde",
	// 		tipo: "erro"
	// 	});	
	// }
}

client.interceptors.request.use(config => {
	if (localStorage.getItem("tokenAdmin")) {
		config.headers.Authorization = `Bearer ${localStorage.getItem(
			"tokenAdmin"
		)}`;
	} else {
		config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
	}
	config.headers["Access-Control-Allow-Origin"] = "*";
	config.headers.crossdomain = true;
	return config;
});

client.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		exibirAlertas(error);
		return Promise.reject(error);
	}
);

export default client;
