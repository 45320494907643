<template>
  <div>
    <ModalEmailEnviado v-if="$store.state.interacoes.modalEmailEnviado.modalAtivado" />
    <ModalEsqueciSenha v-if="$store.state.interacoes.modalEsqueciSenha.modalAtivado" />
    <div class="login-main bg-login">
      <div class="col-1">
        <img class="bg-login" :src="bgLogin" />
      </div>
      <div class="col-2">
        <div class="modal-login">
          <div class="container-login-img">
            <LogoEmpresa />
          </div>
          <transition mode="out-in" name="fade-left" appear>
            <div class="container-login">

              <div v-if="!autenticacao2fa">
                <div class="container-login-inputs">
                  <inputValidate :erro="emailLoginErro">
                    <label>E-mail</label>
                    <input type="text" class="email" v-model.trim="login.email" @keyup="validarLowerCaseEmail()"
                      @blur="$v.login.email.$touch()" @keyup.enter="enterParaLogin" />
                  </inputValidate>
                  <inputValidate :erro="senhaLoginErro">
                    <label>Senha</label>
                    <input :type="passwordField" v-model.trim="login.senha" @blur="$v.login.senha.$touch()"
                      @keyup.enter="enterParaLogin" />
                    <img class="pass-hide" @click="showPass" :src="imgShow" />
                  </inputValidate>

                  <a class="esqueceu-senha" @click="esqueciSenha()">Esqueceu a senha?</a>
                  <div :class="{ backOverlay: zIndex }"></div>
                </div>

                <div class="captcha-fake" v-if="!mostraRecaptcha"></div>
                <div class="recaptcha" v-if="mostraRecaptcha">
                  <vue-recaptcha @verify="verificaCaptcha" @expired="expiraCaptcha"
                    sitekey="6Lc8qbYbAAAAAKJGmmeLYas2RJ0aYJGcyJqCG4EQ" :loadRecaptchaScript="true">
                  </vue-recaptcha>
                </div>
              </div>

              <div v-else>
                <div class="container-login-inputs">
                  <inputValidate :erro="emailLoginErro">
                    <label>Digite abaixo o código de verificação que enviamos para você no e-mail cadastrado!</label>
                    <input type="text" class="codigo" v-model.trim="login.token2fa" @keyup="validarLowerCaseEmail()"
                      @keyup.enter="enterParaLogin" />
                  </inputValidate>
                </div>
              </div>

              <div class="container-login-botoes">
                <button class="btn btn-enviar-login btn-padrao" @click="entrar"
                  :disabled="$v.login.$invalid && !captcha">
                  Entrar
                </button>
              </div>
              <div class="primeiro-acesso">
                <div class="primeiro-acesso-container">
                  <div class="primeiro-link">Primeiro acesso</div>
                  <div class="mb-30">
                    <p>
                      Se você é novo no Programa
                      <router-link :to="{ name: 'PrimeiroAcesso' }">clique aqui</router-link>
                      e realize seu cadastro
                    </p>
                  </div>
                </div>
              </div>
              <div class="duvidas">
                <div>
                  <div class="primeiro-link">Dúvidas?</div>
                  <div>
                    <p>
                      Ligue para
                      <a target="_blank" href="https://api.whatsapp.com/send?phone=5508002360011"><span><img
                            src="/img/icons/wp.png" alt="Whatsapp" class="icone-whatsapp" /></span><span
                          class="laranja">0800 236 0011</span></a>
                      e <br />
                      fale com um de nossos atendentes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import {
  emailLoginErro,
  senhaLoginErro,
  emailEsqueciErro,
} from "@/plugins/vuelidate/login";
import inputValidate from "@/components/formulario/InputValidate.vue";
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
import bgLogin from "@/assets/images/tema/bg-login.png";
import ModalEmailEnviado from "@/components/modal/ModalEmailEnviado.vue";
import ModalEsqueciSenha from "@/components/modal/ModalEsqueciSenha.vue";
import { fazerLogin } from "@/api/login/login.js";
import { irParaCatalogo } from "@/api/catalogo";

export default {
  name: "login",
  components: {
    VueRecaptcha,
    inputValidate,
    LogoEmpresa,
    ModalEmailEnviado,
    ModalEsqueciSenha,
  },
  data() {
    return {
      mostraRecaptcha: true,
      captcha: false,
      bgLogin,
      passwordField: "password",
      imgShow: "/img/icons/hide.png",
      login: {
        email: "",
        senha: "",
        tokenReCaptcha: "",
        device: "",
        token2fa: ""
      },
      autenticacao2fa: false,
      errors: {
        mensagem: '',
        dadosValidos: false
      },
      codigoEmail: '',
      emailEsqueci: "",
      assistencia: false,
      zIndex: false,
      form: {
        cliente: null,
      },
    };
  },
  validations: {
    login: {
      tokenReCaptcha: {
        required,
      },
      email: {
        required,
        email,
      },
      senha: {
        required,
      }
    },
    emailEsqueci: {
      required,
      email,
    },
  },
  computed: {
    emailLoginErro,
    senhaLoginErro,
    emailEsqueciErro,
  },
  methods: {
    validarLowerCaseEmail() {
      this.login.email = this.login.email.toLowerCase();
    },
    showPass() {
      this.passwordField =
        this.passwordField === "password" ? "text" : "password";
      this.imgShow =
        this.imgShow === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    enterParaLogin() {
      this.$v.login.$invalid ? false : this.entrar();
    },
    verificaCaptcha(e) {
      this.login.tokenReCaptcha = e;
      this.captcha = true;
    },
    expiraCaptcha() {
      this.captcha = false;
    },
    verificarCampos() {
      if (this.login.email == '' && this.login.senha == '') return this.errors.mensagem = 'Os campos e-mail e senha são obrigatórios'
      else if (this.login.email == '') return this.errors.mensagem = 'O campo login é obrigatório'
      else if (this.login.senha == '') return this.errors.mensagem = 'O campo senha é obrigatório'
      if (this.autenticacao2fa && this.login.token2fa == "") {
        this.errors.dadosValidos = false;
        this.errors.mensagem = 'Você precisa nos informar o código enviado por e-mail';
        return
      }
      this.errors.dadosValidos = true
    },
    verificaDispositivo() {
      let userAgent = navigator.userAgent;
      let gadget = navigator.maxTouchPoints > 0 && /Android|iPhone/i.test(navigator.userAgent) ? 'Celular' : 'Computador';
      let so = "";

      if (/Windows/.test(userAgent)) {
        so = 'Windows';
      } else if (/Mac/.test(userAgent)) {
        so = 'macOS';
      }
      else if (/Android/.test(userAgent)) {
        so = 'Android';
      } else if (/Linux/.test(userAgent)) {
        so = 'Linux';
      } else if (/iOS/.test(userAgent)) {
        so = 'iOS';
      } else {
        so = 'Desconhecido';
      }

      return `${so} - ${gadget}`;
    },
    entrar() {
      this.verificarCampos()
      if (this.errors.dadosValidos) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            this.login.ip = ip;
            this.login.device = this.verificaDispositivo();
            fazerLogin(this.login).then((resp) => {
              if (resp.data && resp.data.success) {
                this.autenticacao2fa = true;
              }

              if (resp.data.usuario.ativo) {
                localStorage.clear();
                localStorage.setItem("idUser", resp.data.usuario.id);
                localStorage.setItem("email", resp.data.usuario.email);
                localStorage.setItem("token", resp.data.token);
                this.$store.commit("user/SET_LOGADO", true);
                this.$router.push("/");
              } else {
                localStorage.setItem("token", resp.data.token);
                irParaCatalogo().then((resp) => {
                  localStorage.removeItem("token");
                  window.open(resp.data.url, "_self");
                });
              }
            }).catch(() => { });
          });
      } else {
        this.$store.commit("SET_ALERTA", {
          show: true,
          tipo: "erro",
          mensagem: this.errors.mensagem
        });
      }
    },
    esqueciSenha() {
      this.$store.commit("interacoes/SET_MODAL_ESQUECI_SENHA", {
        ativado: true,
        mensagem: "Modal",
        tipo: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.col-1 {
  position: fixed;
  width: 50%;

  @media (max-width: 1000px) {
    display: none;
  }
}

.col-2 {
  right: -50%;
  position: relative;
  width: 50%;
  overflow: hidden;

  @media (max-width: 768px) {
    display: block;
    left: 50;
    position: static;
    width: 100%;
  }

  @media (max-width: 1000px) {
    display: block;
    width: 100%;
    right: -45%;
    position: static;
    overflow: auto;
  }

  @media (max-width: 1250px) {
    overflow: auto;
  }

}

.bg-login {
  background: $branco;
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  transform: scale(1.0);
  transform-origin: 0 1;
  overflow: hidden;
  border: 0;

  @media (max-width: 900px) {
    display: none;
  }
}

.btn-padrao {
  background: $bg-btn-padrao;

  &:disabled {
    cursor: pointer;
    background: $bg-btn-padrao;
    opacity: 0.6;
    color: $branco;

    &:hover {
      background: $bg-btn-padrao;
      opacity: 0.6;
    }
  }
}

.login-main {
  display: flex;
  box-sizing: border-box;

  @media (max-width: 768px) {
    justify-content: center;

    .modal-senha {
      width: 90%;
    }
  }

  .duvidas {
    display: flex;
    justify-content: center;
    text-align: center;
    color: $cinza-interno;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 768px) {
      height: 130px;
      background: linear-gradient(#f7941d 0%, #e64e20 100%);
      width: 375px;
      align-items: center;
      color: #ffff;
    }

    div {
      width: 284px;

      @media (max-width: 768px) {}
    }

    a {
      color: $cor-laranja;
      font-weight: 700;

      @media (max-width: 768px) {
        color: #ffff;
      }
    }
  }
}

.modal-login {
  width: 100%;
  max-width: 762px;
  padding: 30px 60px 0 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;

  @media (max-width: 1366px) {
    padding: 10px 60px 0 150px;
    background: none;
  }

  @media (max-width: 1080px) {
    padding: 0;
    background: #FFF;
  }

  @media (max-width: 768px) {
    padding: 30px;
  }

}

.container-login-img {
  // width: 340px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px auto;
  padding: 20px;

  @media (max-width: 1366px) {
    padding: 0 10px;
  }

  img {
    width: 100%;
    max-width: 350px;
    height: auto;
    max-height: 250px;
    pointer-events: none;

    @media (max-width: 768px) {
      max-height: 150px;
      width: 302px;
      height: 96px;
    }
  }
}

.container-login-botoes {
  button {
    height: 40px;
    font-size: 14px;

    @media (max-width: 768px) {
      height: 49px;
    }
  }
}

.container-login-inputs {
  input {
    height: 42px;

    @media (max-width: 768px) {
      height: 50px;
      font-size: 17px;
    }
  }
}

.container-login,
.container-senha,
.container-assistencia {
  color: $cinza-interno;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 35rem;
  min-height: 250px;
  width: 400px;

  @media(max-width: 1366px) {
    margin-top: 15px;
  }

  >div {
    width: 100%;

    @media (max-width: 768px) {
      width: 82%;
      margin-bottom: 26px;
    }
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;

    h1 {
      font-size: 32px;
      font-weight: 500;
      color: #373737;
    }
  }
}

.btn-enviar-login {
  color: $texto-botao-login;
  background: $bg-btn-padrao;
  transition: hover 0.3s;

  &:hover {
    opacity: 0.9;
  }
}

label {
  font-size: 14px;
}

input {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  color: $textos-login;
  border: 1px solid #707070;
  border-radius: 5px;
  outline: none;
  background: none;
  height: 50px;

  &::placeholder {
    color: $textos-login;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $textos-login;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
  }
}

.esqueceu-senha,
.senha-voltar {
  margin-top: 10px;
  display: block;
  cursor: pointer;
  text-decoration: underline;
  color: $textos-login;
}

.esqueceu-senha {
  position: relative;
  bottom: 15px;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: calc(100% - 145px);

  @media (max-width: 768px) {
    font-size: 13px;
    text-align: right;
    top: 0px;
  }
}

.recaptcha {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    height: 76px;
  }

  @media (max-width: 1366px) {
    margin-bottom: 15px;
  }
}

.primeiro-acesso {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 18px;
  color: $cinza-interno;
  font-size: 14px;
  line-height: 20px;

  .primeiro-acesso-container {
    height: 70px;
  }

  div {
    width: 284px;
  }

  a {
    color: $cor-laranja;
    font-weight: 700;
  }
}

.primeiro-link {
  font-weight: 700;
  line-height: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.backOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #444444b0;
}

.pass-hide {
  width: 30px;
  position: absolute;
  right: 10px;
  top: 30px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 25px;
    top: 32px;
  }
}

.icone-whatsapp {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-top: -3px;
}
</style>
