<template>
  <div>
    <div class="tabs">
      <ul>
        <li v-for="(tab, index) in tabs" :class="{ 'is-active': tab.isActive }" :key="index">
          <a :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name == selectedTab.name;
      });
    },
  },
};
</script>

<style lang="scss">
.tabs ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 330px;
    margin: 0 auto;
  }
}

.tabs ul li a {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  font-weight: 600;
  font-family: "Economica", sans-serif;
  font-size: 16px;
  cursor: pointer;

  &.is-active {
    font-size: 14px;
  }
}

.tabs-details {
  padding: 2rem 0;
}

.is-active {
  border-bottom: 3px solid #7F53A2;

  @media (max-width: 768px) {
    background: $roxo;
    color: $branco;
    border-radius: 5px;
    padding-top: 5px;

    a {
      font-size: 14px !important;
    }
  }
}
</style>
