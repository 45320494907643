<template>
  <div>
    <ModalRanking  v-if="$store.state.interacoes.modalRanking.modalAtivado" />
    <Header :headerGrande="false" :exibeAutorizadas="true" />
    <CampanhaDetalhes />
    <Footer />
  </div>
</template>

<script>
import CampanhaDetalhes from "@/components/elementos/campanhas/CampanhaDetalhes.vue"
import ModalRanking from "@/components/modal/ModalRanking.vue";
import { Header, Footer } from '@/components/elementos'

export default {
  name: "CampanhaAtiva",

  components: {
    ModalRanking,
    CampanhaDetalhes,
    Header,
    Footer
  }
}
</script>

<style lang="scss" scoped>

</style>