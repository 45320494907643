<template>
  <div class="historicoDeChamados">
    <Header :headerGrande="false" />

    <Breadcrumb :bold="true" caminhoUm="FAQ" caminhoDois="Fale conosco" paginaAtual="Histórico de chamados" />

    <BackPage />

    <main class="chamados__container">
      <Tabela />
    </main>

    <Footer />
  </div>
</template>

<script>
import { Header, Footer, BackPage, Breadcrumb } from '@/components/elementos'
import Tabela from "@/components/chamados/Tabela.vue";

export default {
  name: "ChamadosListar",

  components: {
    Header,
    Footer,
    Breadcrumb,
    BackPage,
    Tabela
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb,
.backPage {
  width: 1200px;
  margin: 0 auto;
}
.backPage {
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin: 0 auto 30px 24px;
  }
}

.chamados {
  &__container {
    margin: 0 24px;
    min-height: 568px;

    @media (min-width: 768px) {
      width: 1200px;
      margin: 0 auto 134px auto;
    }
  }
}
</style>