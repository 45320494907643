<template>
  <div class="modalParabens">
    
    <div class="modalParabens__container">
      <div class="barra-laranja"></div>
      <div>
        <div class="modalParabens__mensagem">
          <p>
            Complete o seu cadastro para acessar o Catálogo de Prêmios.
          </p>
        </div>
      </div>

       <div class="modalParabens__btn">
        <button @click="acessarPerfil" class="continuar">
          Acessar Meu perfil
        </button>
        <button @click="fecharModal" class="voltar">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCadastroParabens",

  components: {},

  data() {
    return {
      dados: [
        { id: 1, nome: "Assistência 1" },
        { id: 2, nome: "Assistência 2" },
        { id: 3, nome: "Assistência 3" },
        { id: 4, nome: "Assistência 4" },
      ],

      form: {
        cliente: null,
      },
    };
  },
  created(){
    console.log('MODAL')
  },
  methods: {
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_CADASTRO_COMPLETO", {
        ativado: false,
        mensagem: "Parabens",
        tipo: "",
      });
    },
     acessarPerfil() {
      this.$store.commit("interacoes/SET_MODAL_CADASTRO_COMPLETO", {
        ativado: false,
        mensagem: "Parabens",
        tipo: "",
      });

      this.$router.push("/meu-perfil")
    },

    // validarPoliticaPrivacidade() {
    //   this.$emit("aceitoPolitica");

    //   this.$store.commit("interacoes/SET_MODAL_POLITICA_PRIVACIDADE", {
    //     ativado: false,
    //     mensagem: "Privacidade",
    //     tipo: "",
    //   });
    // },
  },
};
</script>


<style lang="scss" scoped>
.ps {
  height: 200px;
}
.modalParabens {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    position: relative;
    // width: 812px;
    width: 27rem;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    // padding: 138px;
    padding: 40px;
    @media (max-width: 768px) {
      width: 100%;
      height: 25rem;
      padding: 105px 24px 73px 24px;
    }

    img {
      margin-bottom: 30px;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }
  }

  &__mensagem {
    padding: 0 45px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 24px;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 260px;
      // margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;
      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }
     .voltar {
      border: none;
      background: transparent;
      width: 260px;
      margin-top: 16px;
      height: 49px;
      border-radius: 5px;
      color: $grafite;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 30px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
  .barra-laranja{
    position: absolute;
    top: 0;
    width: 100%;
    height: 1rem;
    background: #f7941d;
    border-radius: 4px 4px 0 0;
  }
}
</style>