<template>
  <div>
    <div class="login-main bg-login">
      <img class="bg-login" :src="bgLogin" />

      <div class="modal-login">
        <div class="container-login-img">
          <LogoEmpresa />
        </div>
        <transition mode="out-in" name="fade-left" appear>
          <div class="container-assistencia">
            <h1 class="mt-4 titulo-boas-vindas">
              Boas vindas!
            </h1>
            <!-- <div id="escolha-autorizada-franquia" v-if="opAssisFranquia">
              <h2 class="mt-4 titulo-escolha-autorizada">
                Escolha o tipo da sua Autorizada
              </h2>
              <div class="primeiroAcesso__btn">
                <div>
                  <button @click="
                    (inputAssistencia = true),
                    (opAssisFranquia = false),
                    (idAssisFranquia = btnAssisFranquia[0].id)
                  " class="btn-assistencia">
                    Assistência
                  </button>
                  <button @click="
                    (inputFranquia = true),
                    (opAssisFranquia = false),
                    (idAssisFranquia = btnAssisFranquia[1].id)
                  " class="btn-franquia">
                    Franquia
                  </button>

                  <p class="p-voltar">
                    <router-link :to="{ name: 'login' }" class="voltar">
                      VOLTAR
                    </router-link>
                  </p>
                </div>
              </div>
            </div> -->

            <div>
              <div class="primeiroAcesso__formContainer">
                <!-- <label>Cargo</label>
                  <multiselect v-model="form.cargo" label="descricao" track-by="id" :options="cargos"
                    :multiple="false" :options-limit="200" placeholder="" select-label="" :allow-empty="false"
                    deselect-label="Remover" selected-label="Selecionado" class="cargo"/> -->

                <div>
                  <label>Cargo</label>
                  <multiselect v-model="form.cargo" label="descricao" track-by="id" :options="cargosAssistencia"
                    :multiple="false" :options-limit="200" placeholder="" select-label="" :allow-empty="false"
                    deselect-label="Remover" selected-label="Selecionado" class="cargo"/>

                  <label>Assistência</label>
                  <multiselect v-model="form.cliente" label="descricao" track-by="id" :options="dadosAssistencias"
                    :multiple="false" :options-limit="200" placeholder="" select-label="" :allow-empty="false"
                    deselect-label="Remover" selected-label="Selecionado" />

                  <div class="primeiroAcesso__btn">
                    <div>
                      <button @click="formPrimeiroAcesso" class="continuar">
                        CONTINUAR
                      </button>
                      <p class="voltar">
                        <router-link :to="{ name: 'login' }" class="voltar">
                          VOLTAR
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <!-- <div v-if="inputFranquia">
                  <label>Cargo</label>
                  <multiselect v-model="form.cargo" label="descricao" track-by="id" :options="cargosFranquia"
                    :multiple="false" :options-limit="200" placeholder="" select-label="" :allow-empty="false"
                    deselect-label="Remover" selected-label="Selecionado" class="cargo"/>

                  <label class="pt-4 mb-2">Franquia</label>
                  <multiselect v-model="form.cliente" label="descricao" track-by="descricao" :options="dadosFranquias"
                    :multiple="false" :options-limit="200" placeholder="" select-label="" deselect-label="Remover"
                    selected-label="Selecionado" />

                  <div class="primeiroAcesso__btn">
                    <div>
                      <button @click="formPrimeiroAcesso" class="continuar">
                        CONTINUAR
                      </button>
                      <p class="voltar">
                        <a @click="
                          (inputFranquia = false),
                          (opAssisFranquia = true),
                          (form.cliente = null)
                        " class="voltar">
                          VOLTAR
                        </a>
                      </p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
import bgLogin from "@/assets/images/tema/bg-login.png";
import { buscarAssistenciasAutorizadas } from "@/api/autorizadas";
import { listaAssistenciaFranquia, listaCargos } from "@/api/cadastro";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    LogoEmpresa,
    Multiselect,
  },
  data() {
    return {
      bgLogin: bgLogin,
      assistencia: false,
      dadosAssistencias: [],
      dadosFranquias: [],
      form: {
        cliente: null,
        franquia: "",
        cargo: [{}]
      },
      cargosAssistencia: [],
      cargosFranquia: [],
      btnAssisFranquia: [],
      inputAssistencia: false,
      opAssisFranquia: true,
      inputFranquia: false,
      idAssisFranquia: "",
    };
  },
  mounted() {
    this.buscaAssistenciaFranquia();
    this.carregaFranquiasAssistencias();
    this.buscaCargos();
  },
  methods: {
    ...mapActions({
      defineAssistenciaFranquia: "assistencia/defineAssistenciaFranquia",
      defineAssistenciaId: "assistencia/defineAssistenciaId",
      defineAutorizacaoTelas: "usuario/defineAutorizacaoTelas",
    }),
    carregaFranquiasAssistencias() {
      buscarAssistenciasAutorizadas(1).then((resp) => {
        this.dadosAssistencias = resp.data;
      });

      buscarAssistenciasAutorizadas(2).then((resp) => {
        this.dadosFranquias = resp.data;
      });
    },
    buscaAssistenciaFranquia() {
      listaAssistenciaFranquia().then((resp) => {
        this.btnAssisFranquia = resp.data;
      });
    },
    buscaCargos(){
      listaCargos(1).then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i].id != 8 && resp.data[i].id != 9 && resp.data[i].id != 10) {
            this.cargosAssistencia.push(resp.data[i]);
          }
        }
      });
      listaCargos(2).then((resp) => {
        this.cargosFranquia = resp.data;
      });
    },
    formPrimeiroAcesso() {
      if (this.form.cliente.id && this.form.cargo.id) {
        this.defineAssistenciaId(this.form.cliente.id);
        this.defineAssistenciaFranquia(this.idAssisFranquia);
        sessionStorage.setItem('idCargo', this.form.cargo.id);

        this.inputFranquia
          ? this.defineAutorizacaoTelas(false)
          : this.defineAutorizacaoTelas(true);

        this.$router.push("/cadastro");
        this.$store.commit("interacoes/SET_MODAL_PRIMEIRO_ACESSO", {
          ativado: false,
          mensagem: "PrimeiroAcesso",
          tipo: "",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cargo {
  margin-bottom: 16px;
}
.titulo-boas-vindas { 
  font-size: 32px;
  color: #373737;
  text-align: center;
}
#escolha-autorizada-franquia {
  .titulo-escolha-autorizada {
    color: #979797;
    font-size: 24px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 22px;
      padding: 20px 0;
      text-align: center;
    }
  }

  .primeiroAcesso__btn {
    text-align: center;

    .duvidas {
      margin-top: 150px;
    }

    .btn-franquia,
    .btn-assistencia {
      width: 100%;
      max-width: 310px;
      color: #fff;
      height: 49px;
      margin-top: 30px;
      background: #f7941d;
      border-radius: 5px;
      text-transform: uppercase;
      color: #f5f6fa;
      font-size: 14px;
      font-weight: 600;

      @media (max-width: 768px) {
        margin-top: 10px;
        width: 100%;
        max-width: 270px;
        height: 50px;
      }

      &:hover {
        background: linear-gradient(90deg, #f7941d 0%, #e64e20 100%) 0% 0% no-repeat padding-box;
      }
    }

    .btn-franquia {
      margin-top: 20px;
      background: linear-gradient(89deg, #50b07c 0%, #50b07c 100%) 0% 0% no-repeat padding-box;

      &:hover {
        background: linear-gradient(89deg, #50b07c 0%, #a2e323 100%) 0% 0% no-repeat padding-box;
      }
    }
  }
}

.bg-login {
  background: $branco;
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 768px) {
    display: none;
  }
}

.multiselect {
  &__tags {
    background-color: $branco !important;
  }
}

.btn-padrao {
  background: $bg-btn-padrao;

  &:disabled {
    cursor: pointer;
    background: $bg-btn-padrao;
    opacity: 0.6;
    color: $branco;

    &:hover {
      background: $bg-btn-padrao;
      opacity: 0.6;
    }
  }
}

.login-main {
  display: flex;
  box-sizing: border-box;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: center;
  }

  .duvidas {
    display: flex;
    justify-content: center;
    text-align: center;
    color: $cinza-interno;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 768px) {
      height: 130px;
      background: linear-gradient(#f7941d 0%, #e64e20 100%);
      width: 375px;
      align-items: center;
      color: #ffff;
    }

    div {
      width: 284px;

      @media (max-width: 768px) {}
    }

    a {
      color: $cor-laranja;
      font-weight: 700;

      @media (max-width: 768px) {
        color: #ffff;
      }
    }
  }
}

.container-login-img {
  width: 340px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: auto;
  }

  img {
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 250px;
    pointer-events: none;
  }
}

.container-login-botoes {
  button {
    height: 35px;

    @media (max-width: 768px) {
      height: 49px;
    }
  }
}

.container-login-inputs {
  input {
    height: 49px;

    @media (max-width: 768px) {
      height: 50px;
      font-size: 17px;
    }
  }
}

.container-login,
.container-senha,
.container-assistencia {
  color: $cinza-interno;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35rem;
  min-height: 250px;
  width: 450px;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  >div {
    width: 100%;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 26px;
    }
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;

    h1 {
      font-size: 32px;
      font-weight: 500;
      color: #373737;
    }
  }
}

.btn-enviar-login {
  color: $texto-botao-login;
  background: $bg-btn-padrao;
  transition: hover 0.3s;

  &:hover {
    opacity: 0.9;
  }
}

.container-senha {
  p {
    font-size: 18px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    color: $textos-login;
  }

  button {
    margin-top: 15px;
  }
}

label {
  font-size: 14px;
}

input {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  color: $textos-login;
  border: 1px solid #707070;
  border-radius: 5px;
  outline: none;
  background: none;
  height: 50px;

  &::placeholder {
    color: $textos-login;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $textos-login;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
  }
}

.modal-login {
  width: 100%;
  max-width: 762px;
  padding: 0;
  background: $branco;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding: 30px;
  }
}

.primeiro-acesso {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 18px;
  color: $cinza-interno;
  font-size: 14px;
  line-height: 20px;

  .primeiro-acesso-container {
    height: 70px;
  }

  div {
    width: 284px;
  }

  a {
    color: $cor-laranja;
    font-weight: 700;
  }
}

.primeiro-link {
  font-weight: 700;
  line-height: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.backOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #444444b0;
}

.pass-hide {
  width: 30px;
  position: absolute;
  right: 10px;
  top: 30px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 25px;
    top: 32px;
  }
}

.continuar {
  border: none;
  background: $bg-btn-padrao;
  width: 100%;
  margin: 20px 0 8px 0;
  height: 49px;
  border-radius: 5px;
  color: $texto-botao;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: hover 0.3;

  @media (max-width: 768px) {
    width: 100%;
    background: $bg-header-cadastro;
  }

  &:hover {
    opacity: 0.9;
  }
}

.p-voltar {
  width: 100%;
  margin-top: 80px;
  text-align: center;
}

.voltar {
  margin: 10px 0 8px 0;
  height: 49px;
  color: $grafite;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 30px;
  }

  &:hover {
    opacity: 0.9;
  }
}
</style>
