export default {
  namespaced: true,
  state: {
    layout: "layout-claro",
    loading: false,
    relatorioLoading: false,
    menuAtivado: true,
    relatorioLocal: false,
    notificacoes: false,
    modal: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalBloqueia: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalModerar: {
      modalAtivado: false,
      mensagemModal: "",
      cuponsModal: [],
      sucesso: false,
      usuarioId: "",
      idHistoricoPremiacaoIsntantanea: "",
    },
    modalSenha: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalRegulamento: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalPolitica: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalCadastroSucesso: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalPrimeiroAcesso: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalParabens: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalSair: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalPoliticaPrivacidade: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalDeRegulamento: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalAceiteRegulamento: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalExcluirConta: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalContaExcluida: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalBemVindo: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalCadastroParabens: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalGanhou5Pontos: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalCadastroCompleto: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalDesistirCadastro: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalEsqueciSenha: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalEmailEnviado: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalRedefinirSenha: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalRanking: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    modalRedefinirSenhaMeuPerfil: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    faleConosco: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: ""
    },
    codigoRedefinirSenha: {
      codigo: null
    },
    tabelaExtrato: {
      extratoGeral: true,
      pontosGanhos: false,
      meusPremios: false,
      ponstosRegatados: false,
      pontosExpirados: false,
    }
  },
  mutations: {  
    SET_TEMA(state, layout) {
      state.layout = layout;
    },
    SET_RELATORIO_LOCAL(state, relatorioLocal) {
      state.relatorioLocal = relatorioLocal;
    },
    SET_LOADING(state, valor) {
      state.loading = valor;
    },
    SET_LOADING_RELATORIO(state, valor) {
      state.relatorioLoading = valor;
    },
    SET_SIDEBAR(state, valor) {
      state.menuAtivado = valor;
    },
    SET_MODAL(state, modal) {
      state.modal.modalAtivado = modal.ativado;
      state.modal.Modal = modal.mensagem;
      state.modal.tipoModal = modal.tipo;
    },
    // SET_MODAL_SENHA(state, modal) {
    //   state.modalSenha.modalAtivado = modal.ativado;
    //   state.modalSenha.mensagemModal = modal.mensagem;
    //   state.modalSenha.tipoModal = modal.tipo;
    // },
    SET_MODAL_REGULAMENTO(state, modal) {
      state.modalRegulamento.modalAtivado = modal.ativado;
      state.modalRegulamento.mensagemModal = modal.mensagem;
      state.modalRegulamento.tipoModal = modal.tipo;
    },
    SET_MODAL_POLITICA(state, modal) {
      state.modalPolitica.modalAtivado = modal.ativado;
      state.modalPolitica.mensagemModal = modal.mensagem;
      state.modalPolitica.tipoModal = modal.tipo;
    },
    SET_MODAL_SAIR(state, modal) {
      state.modalSair.modalAtivado = modal.ativado;
      state.modalSair.mensagemModal = modal.mensagem;
      state.modalSair.tipoModal = modal.tipo;
    },
    SET_MODAL_CADASTRO_SUCESSO(state, modal) {
      state.modalCadastroSucesso.modalAtivado = modal.ativado;
      state.modalCadastroSucesso.mensagemModal = modal.mensagem;
      state.modalCadastroSucesso.tipoModal = modal.tipo;
    },
    SET_MODAL_PRIMEIRO_ACESSO(state, modal) {
      state.modalPrimeiroAcesso.modalAtivado = modal.ativado;
      state.modalPrimeiroAcesso.mensagemModal = modal.mensagem;
      state.modalPrimeiroAcesso.tipoModal = modal.tipo;
    },
    SET_MODAL_PARABENS(state, modal) {
      state.modalParabens.modalAtivado = modal.ativado;
      state.modalParabens.mensagemModal = modal.mensagem;
      state.modalParabens.tipoModal = modal.tipo;
    },
    SET_MODAL_BLOQUEIO(state, modal) {
      state.modalBloqueia.modalAtivado = modal.ativado;
      state.modalBloqueia.mensagemModal = modal.mensagem;
      state.modalBloqueia.tipoModal = modal.tipo;
    },
    SET_MODAL_MODERAR(state, modal) {
      state.modalModerar.modalAtivado = modal.ativado;
      state.modalModerar.mensagemModal = modal.mensagem;
      state.modalModerar.cuponsModal = modal.cupons;
      state.modalModerar.sucesso = modal.sucesso;
      state.modalModerar.usuarioId = modal.usuarioId;
      state.modalModerar.idHistoricoPremiacaoIsntantanea = modal.idHistoricoPremiacaoIsntantanea;
    },
    SET_MODAL_POLITICA_PRIVACIDADE(state, modal) {
      state.modalPoliticaPrivacidade.modalAtivado = modal.ativado;
      state.modalPoliticaPrivacidade.mensagemModal = modal.mensagem;
      state.modalPoliticaPrivacidade.tipoModal = modal.tipo;
    },
    SET_MODAL_ACEITE_DE_REGULAMENTO(state, modal) {
      state.modalAceiteRegulamento.modalAtivado = modal.ativado;
      state.modalAceiteRegulamento.mensagemModal = modal.mensagem;
      state.modalAceiteRegulamento.tipoModal = modal.tipo;
    },
    SET_MODAL_DE_REGULAMENTO(state, modal) {
      state.modalDeRegulamento.modalAtivado = modal.ativado;
      state.modalDeRegulamento.mensagemModal = modal.mensagem;
      state.modalDeRegulamento.tipoModal = modal.tipo;
    },
    SET_MODAL_EXCLUIR_CONTA(state, modal) {
      state.modalExcluirConta.modalAtivado = modal.ativado;
      state.modalExcluirConta.mensagemModal = modal.mensagem;
      state.modalExcluirConta.tipoModal = modal.tipo;
    },
    SET_MODAL_CONTA_EXCLUIDA(state, modal) {
      state.modalContaExcluida.modalAtivado = modal.ativado;
      state.modalContaExcluida.mensagemModal = modal.mensagem;
      state.modalContaExcluida.tipoModal = modal.tipo;
    },
    SET_MODAL_BEM_VINDO(state, modal) {
      state.modalBemVindo.modalAtivado = modal.ativado;
      state.modalBemVindo.mensagemModal = modal.mensagem;
      state.modalBemVindo.tipoModal = modal.tipo;
    },
    SET_MODAL_CADASTRO_PARABENS(state, modal) {
      state.modalCadastroParabens.modalAtivado = modal.ativado;
      state.modalCadastroParabens.mensagemModal = modal.mensagem;
      state.modalCadastroParabens.tipoModal = modal.tipo;
    },
    SET_MODAL_CADASTRO_COMPLETO(state, modal) {
      state.modalCadastroCompleto.modalAtivado = modal.ativado;
      state.modalCadastroCompleto.mensagemModal = modal.mensagem;
      state.modalCadastroCompleto.tipoModal = modal.tipo;
    },
    SET_MODAL_DESISTIR_CADASTRO(state, modal) {
      state.modalDesistirCadastro.modalAtivado = modal.ativado;
      state.modalDesistirCadastro.mensagemModal = modal.mensagem;
      state.modalDesistirCadastro.tipoModal = modal.tipo;
    },
    SET_MODAL_ESQUECI_SENHA(state, modal) {
      state.modalEsqueciSenha.modalAtivado = modal.ativado;
      state.modalEsqueciSenha.mensagemModal = modal.mensagem;
      state.modalEsqueciSenha.tipoModal = modal.tipo;
    },
    SET_MODAL_EMAIL_ENVIADO(state, modal) {
      state.modalEmailEnviado.modalAtivado = modal.ativado;
      state.modalEmailEnviado.mensagemModal = modal.mensagem;
      state.modalEmailEnviado.tipoModal = modal.tipo;
    },
    SET_MODAL_REDEFINIR_SENHA(state, modal) {
      state.modalRedefinirSenha.modalAtivado = modal.ativado;
      state.modalRedefinirSenha.mensagemModal = modal.mensagem;
      state.modalRedefinirSenha.tipoModal = modal.tipo;
    },
    SET_MODAL_RANKING(state, modal) {
      state.modalRanking.modalAtivado = modal.ativado;
      state.modalRanking.mensagemModal = modal.mensagem;
      state.modalRanking.tipoModal = modal.tipo;
    },
    SET_MODAL_REDEFINIR_SENHA_MEU_PERFIL(state, modal) {
      state.modalRedefinirSenhaMeuPerfil.modalAtivado = modal.ativado;
      state.modalRedefinirSenhaMeuPerfil.mensagemModal = modal.mensagem;
      state.modalRedefinirSenhaMeuPerfil.tipoModal = modal.tipo;
    },
    SET_MODAL_FALE_CONOSCO(state, modal) {
      state.faleConosco.modalAtivado = modal.ativado;
      state.faleConosco.mensagemModal = modal.mensagem;
      state.faleConosco.tipoModal = modal.tipo;
    },
    SET_TABELA_EXTRATO(state, payload) {
      // state.tabelaExtrato = payload
      state.tabelaExtrato.extratoGeral = payload.extratoGeral;
      state.tabelaExtrato.pontosGanhos = payload.pontosGanhos;
      state.tabelaExtrato.meusPremios = payload.meusPremios;
      state.tabelaExtrato.ponstosRegatados = payload.ponstosRegatados;
      state.tabelaExtrato.pontosExpirados = payload.pontosExpirados;
    },
    SET_NOTIFICACOES(state, payload) {
      state.notificacoes = payload;
    },
    SET_MODAL_GANHOU_5_PONTOS (state, modal) {
      state.modalGanhou5Pontos.modalAtivado = modal.ativado;
      state.modalGanhou5Pontos.mensagemModal = modal.mensagem;
      state.modalGanhou5Pontos.tipoModal = modal.tipo;
    },
  }
};
