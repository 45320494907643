<template>
	<div class="faq">
		<Header :headerGrande="false"  :exibeAutorizadas="true" />

		<div class="faq__detalhes">
			<h1 class="titulo">Dúvidas frequentes</h1>
			<p class="text-center">Nesta página respondemos as dúvidas mais frequentes relacionadas ao programa TEC
				CLUB.</p>

			<div class="accordions">
				<Accordions />
			</div>

			<h1 class="titulo">Não encontrou o que precisa?</h1>
			<router-link :to="'./fale-conosco'">
				<button class="btn bg-padrao" href="#">
					Fale conosco
				</button>
			</router-link>
		</div>

		<Footer />
	</div>
</template>

<script>
import { Header, Footer, Accordions } from '@/components/elementos'
import { mapState } from "vuex";
export default {
	name: "Faq",
	components: {
		Header,
		Footer,
		Accordions
	},

	computed: {
		...mapState({
			autorizaTelas: state => state.usuario.autorizaTelas
		})
	},

	created() {
		if (this.autorizaTelas == false || this.autorizaTelas == null) {
			this.$router.push("/");
			return;
		}
	}
};
</script>

<style lang="scss" scoped>
.text-center {
	text-align: center;
}

.faq {
	&__detalhes {
		padding: 0 24px;
		margin-top: 20px;

		@media (min-width: 768px) {
			padding: 0;
			margin: 75px auto 134px;
			width: 1200px;
		}

	}

	.accordions {
		margin: 20px auto;
		border-bottom: 2px solid $grafite;
		padding-bottom: 20px;

		@media(min-width: 768px) {
			width: 70%;
		}
	}

	.titulo {
		font-size: 24px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 15px;
		text-align: center;
	}

	a {
		text-decoration: none;
	}

	.btn {
		width: 80%;
		color: $branco;
		margin: auto;

		@media(min-width: 768px) {
			width: 25%;
		}
	}
}
</style>
