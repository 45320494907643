<template>
  <div>
    <ModalRedefinirSenhaMeuPerfil v-if="$store.state.interacoes.modalRedefinirSenhaMeuPerfil.modalAtivado" />
    <ModalPoliticaPrivacidade @aceitoPolitica="aceitoPolitica"
      v-if="$store.state.interacoes.modalPoliticaPrivacidade.modalAtivado" />
    <ModalDeRegulamento @aceitoRegulamento="aceitoRegulamento"
      v-if="$store.state.interacoes.modalDeRegulamento.modalAtivado" />
    <ModalExcluirContaMeuPerfil v-if="$store.state.interacoes.modalExcluirConta.modalAtivado" />
    <ModalContaExcluida v-if="$store.state.interacoes.modalContaExcluida.modalAtivado" />
    <Header :exibeAutorizadas="false" />
    <div class="login-main">
      <!-- Login -->
      <div :class="{ backOverlay: zIndex }"></div>

      <div class="modal-login">
        <!-- <div @click="irParaHome" class="container-login-img">
          <LogoBranca />
        </div> -->
        <div class="texto-topo">
          <!-- <h1 class="desk">Cadastre seu dados pessoais</h1> -->

          <h1>Meu Perfil</h1>
        </div>

        <transition mode="out-in" name="fade-left" appear>
          <!-- Login -->
          <div class="container-login">
            <div class="img-upload">
              <div class="mask">
                <img class="prev-img" v-if="imagePrev" :src="imagePrev" />
              </div>
              <input type="file" accept="image/png, image/jpeg" @change="prevImgChange" />
              <p class="small">Formatos aceitos (.jpg .png) - 2mb</p>
              <div class="abas">
                <h1 @click="selectPessoais()" :class="pessoais ? 'ativo' : ''">Dados pessoais <div
                    :class="pessoais ? 'bolinha' : ''"></div>
                </h1>
                <h1 @click="selectProfissionais()" :class="profissionais ? 'ativo' : ''">Dados profissionais <div
                    :class="profissionais ? 'bolinha' : ''"></div>
                </h1>
                <h1 @click="selectEndereco()" :class="endereco ? 'ativo' : ''">Endereço <div
                    :class="endereco ? 'bolinha' : ''"></div>
                </h1>
                <h1 @click="selectSeguranca()" :class="seguranca ? 'ativo' : ''">Trocar senha<div
                    :class="seguranca ? 'bolinha' : ''"></div>
                </h1>
                <h1 @click="selectComunicacao()" :class="comunicacao ? 'ativo' : ''">Aceites <div
                    :class="comunicacao ? 'bolinha' : ''"></div>
                </h1>
              </div>
            </div>

            <div class="container-login-cadastro" key="cadastro">
              <div class="container-login-inputs">

                <div id="pessoais" v-show="pessoais">
                  <inputValidate :erro="nomeErro">
                    <label>Nome Completo</label>
                    <input type="text" v-model="cadastro.nome" placeholder="Ex: Rafael Evangelista Soares"
                      @blur="$v.cadastro.nome.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>
                  <inputValidate :erro="dataErro">
                    <div class="input">
                      <label>Data de Nascimento</label>
                      <input placeholder="Ex: 01/01/1999" class="input-medio" type="tel"
                        v-model.trim="cadastro.dataNascimento" @blur="$v.cadastro.dataNascimento.$touch()"
                        @keyup.enter="enterParaCadastro"  disabled/>
                    </div>
                  </inputValidate>
                  <inputValidate :erro="cpfErro">
                    <div class="input">
                      <label>CPF</label>
                      <input placeholder="Ex: 000.000.000-00" class="input-medio" type="text" v-model.trim="cadastro.cpf"
                        v-mask="'999.999.999-99'" disabled @blur="$v.cadastro.cpf.$touch()"
                        @keyup.enter="enterParaCadastro" />
                    </div>
                  </inputValidate>
                  <inputValidate :erro="emailLoginErro">
                    <label>E-mail</label>
                    <input type="text" placeholder="Ex: meuemail@gmail.com" v-model.trim="cadastro.email" disabled
                      @blur="$v.cadastro.email.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>
                  <inputValidate :erro="confirmaEmailErro">
                    <label>Confirmar E-mail</label>
                    <input type="text" placeholder="Ex: meuemail@gmail.com" v-model.trim="cadastro.emailConfirma" disabled
                      @blur="$v.cadastro.emailConfirma.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>
                  <inputValidate :erro="celularErro" :celularInvalido="celularInvalido">
                    <div class="input">
                      <label>Celular</label>
                      <input class="input-medio" type="text" placeholder="(99) 99999-9999" v-mask="'(99)99999-9999'"
                        v-model.trim="cadastro.celular" disabled/>
                    </div>
                  </inputValidate>
                </div>

                <div id="profissionais" v-show="profissionais">
                  <label>Autorizadas</label>
                  <span v-if="cadastro.assistencia.length > 1">
                    <Multiselect v-model="boxAssistenciasSelecionas" label="autorizadaNomeFantasia"
                      track-by="autorizadaId" :options="cadastro.assistencia" :multiple="false" placeholder=" "
                      select-label=" " deselect-label=" " selected-label=" " :searchable="true" :allowEmpty="false">
                      <span slot="noResult">Não encontrado</span>
                    </Multiselect>
                  </span>
                  <input type="text" disabled v-model="cadastro.assistencia[0].autorizadaNomeFantasia"
                    v-if="cadastro.assistencia.length == 1" />
                  <label>Cargo</label>
                  <input type="text" v-model="cadastro.cargo" disabled class="cargo" />
                </div>

                <div id="endereco" v-show="endereco">
                  <inputValidate :erro="cepErro" class="flex-between-row">
                    <div class="input input-50">
                      <label>CEP</label>
                      <input placeholder="Ex: 00000-000" class="" type="text" v-model.trim="cadastro.cep"
                        v-mask="'99999-999'" @blur="$v.cadastro.cep.$touch()" @keyup.enter="enterParaCadastro" />
                    </div>
                    <p>
                      Não sabe seu CEP?<br />
                      <a class="" href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank"
                        rel="noopener noreferrer">
                        Clique aqui
                      </a>
                    </p>
                  </inputValidate>

                  <inputValidate :erro="enderecoErro">
                    <label>Rua/Avenida/Travessa/Praça</label>
                    <input type="text" v-model.trim="cadastro.endereco" placeholder="Ex: 23 de maio"
                      @blur="$v.cadastro.endereco.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>

                  <div class="flex-between-row">
                    <inputValidate class="input-50" :erro="numeroErro">
                      <label>Número</label>
                      <input type="text" v-model.trim="cadastro.numero" placeholder="Ex: 1000"
                        @blur="$v.cadastro.numero.$touch()" @keyup.enter="enterParaCadastro" />
                    </inputValidate>

                    <inputValidate class="input-50">
                      <label>Complemento</label>
                      <input type="text" v-model.trim="cadastro.complemento" placeholder="Ex: Apto, Casa"
                        @blur="$v.cadastro.complemento.$touch()" @keyup.enter="enterParaCadastro" />
                    </inputValidate>
                  </div>

                  <inputValidate :erro="bairroErro">
                    <label>Bairro</label>
                    <input type="text" v-model.trim="cadastro.bairro" placeholder="Ex: Novo Mundo"
                      @blur="$v.cadastro.bairro.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>

                  <inputValidate :erro="cidadeErro">
                    <label>Cidade</label>
                    <input type="text" v-model.trim="cadastro.cidade" placeholder="Ex: São Paulo"
                      @blur="$v.cadastro.cidade.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>

                  <inputValidate :erro="ufErro" class="input-50">
                    <label>UF</label>
                    <input type="text" v-model.trim="cadastro.uf" placeholder="Ex: São Paulo"
                      @blur="$v.cadastro.uf.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>

                  <inputValidate>
                    <label>Ponto de referência</label>
                    <input type="text" v-model.trim="cadastro.referencia" placeholder="Ex: São Paulo"
                      @blur="$v.cadastro.referencia.$touch()" @keyup.enter="enterParaCadastro" />
                  </inputValidate>
                </div>

                <div id="seguranca" v-show="seguranca">
                  <inputValidate>
                    <div class="label-input">
                      <label>Senha atual</label>
                      <input class="senha" :type="passwordFieldCurrent" v-model.trim="cadastro.senhaAtual"
                        @blur="$v.cadastro.senhaAtual.$touch()" @keyup.enter="enterParaCadastro" />
                    </div>
                    <img class="pass-hide" @click="showCurrentPass" :src="imgShowCurrent" alt="WHP Técnicos" />
                  </inputValidate>
                  <inputValidate :erro="senhaLoginErro">
                    <div class="label-input">
                      <label>Nova senha</label>
                      <input class="senha" :type="passwordField" v-model.trim="cadastro.senha"
                        @blur="$v.cadastro.senha.$touch()" @keypress="confereSenha" @keyup.enter="enterParaCadastro" />
                    </div>
                    <img class="pass-hide" @click="showPass" :src="imgShow" alt="WHP Técnicos" />
                    <div v-if="errors" class="error">
                      <span class="input-erro-mensagem" v-if="!$v.cadastro.senha.strongPassword">Senha inválida</span>
                    </div>
                  </inputValidate>
                  <inputValidate :erro="confirmaSenhaErro">
                    <div class="label-input">
                      <label>Confirmar senha</label>
                      <input class="senha" :type="passwordFieldConfirm" v-model.trim="cadastro.senhaConfirma"
                        @blur="$v.cadastro.senhaConfirma.$touch()" @keyup.enter="enterParaCadastro" />
                    </div>
                    <img class="pass-hide" @click="showPassConfirm" :src="imgShowConfirm" alt="WHP Técnicos" />
                  </inputValidate>

                  <button class="btn btn-enviar-login btn-salvar btn-senha" @click="mudarSenha">
                    Mudar senha
                  </button>
                </div>

                <div id="comunicacao" v-show="comunicacao">
                  <inputValidate :erro="regulamentoErro">
                    <input class="chack-regulamento" type="checkbox" v-model="cadastro.regulamento" id="regulamento"
                      disabled />
                    <label for="regulamento" class="small label-check">Li e aceito o Regulamento do programa.</label>
                    <a class="link-regulamento" :style="positionLinkRegulamento"></a>
                  </inputValidate>
                  <inputValidate :erro="politicaErro">
                    <input id="politica" v-model.trim="cadastro.politica" type="checkbox" disabled />
                    <label for="politica" class="small label-check">
                      Li e aceito a Politica de Privacidade do programa.</label>
                    <a class="link-politica" :style="positionLinkPolitica"></a>
                  </inputValidate>
                  <div class="checkReceberEmails">
                    <input id="aceite" type="checkbox" v-model.trim="cadastro.receberEmails" />
                    <label for="aceite" class="small label-check">
                      Aceito receber e-mails, sms, whatsapp com novidades e
                      informações sobre o programa.</label>
                  </div>
                </div>

                <div class="container-login-botoes" v-show="!seguranca">
                  <div style="display:flex;">
                    <button class="btn senha-voltar" href="#" @click="linkVoltar">
                      Sair
                    </button>
                    <button class="btn btn-enviar-login btn-salvar" @click="atualizarCadastro">
                      Salvar
                    </button>
                  </div>
                  <button class="btn senha-voltar excluir" href="#" @click="chamaModalExcluirConta()">
                    Excluir conta
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Esqueceu Senha -->
        </transition>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import { Header, Footer } from '@/components/elementos'
import Multiselect from "vue-multiselect";

// import VueRecaptcha from "vue-recaptcha";

import {
  emailLoginErro,
  senhaLoginErro,
  nomeErro,
  dataErro,
  celularErro,
  cpfErro,
  cepErro,
  enderecoErro,
  numeroErro,
  bairroErro,
  cidadeErro,
  ufErro,
  confirmaEmailErro,
  confirmaSenhaErro,
  politicaErro,
  regulamentoErro,
} from "@/plugins/vuelidate/cadastro";
//import * as api from "@/api/login/login";
// import esqueciSenha from "@/api/usuario/index.js";
import inputValidate from "@/components/formulario/InputValidate.vue";
// import LogoBranca from "@/components/logos/LogoBranca.vue";
import img from "@/assets/images/tema/logo-empresa.shot.svg";
import AwesomeMask from "awesome-mask";
import ModalPoliticaPrivacidade from "@/components/modal/ModalPoliticaPrivacidade.vue";
import ModalDeRegulamento from "@/components/modal/ModalDeRegulamento.vue";
import ModalExcluirContaMeuPerfil from "@/components/modal/ModalExcluirContaMeuPerfil.vue";
import { buscarPerfilUsuario, utualizarCadastroUsuario } from "@/api/usuario";
import moment from "moment";
import ModalContaExcluida from "@/components/modal/ModalContaExcluida.vue";
import ModalRedefinirSenhaMeuPerfil from "@/components/modal/ModalRedefinirSenhaMeuPerfil.vue";
import { alterarSenhaMeuPerfil } from "@/api/usuario";
import { mapActions, mapState } from "vuex";

//const alpha = helpers.regex('^(?=.*[a-z])(?=.*[A-Z])(?=.*\)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$');

export default {
  name: "cadastro",
  components: {
    // VueRecaptcha,
    inputValidate,
    // LogoBranca,
    ModalPoliticaPrivacidade,
    ModalDeRegulamento,
    ModalExcluirContaMeuPerfil,
    ModalContaExcluida,
    ModalRedefinirSenhaMeuPerfil,
    Header,
    Footer,
    Multiselect
  },
  directives: {
    mask: AwesomeMask,
  },
  data() {
    return {
      pessoais: true,
      profissionais: false,
      endereco: false,
      seguranca: false,
      comunicacao: false,
      celularInvalido: null,
      mostraRecaptcha: true,
      captcha: false,
      boxAssistenciasSelecionas: [],
      positionLinkRegulamento: {
        position: "absolute",
      },
      positionLinkPolitica: {
        position: "absolute",
      },
      imagePrev: "",
      errors: false,
      esqueceuSenha: false,
      passwordField: "password",
      passwordFieldConfirm: "password",
      imgShow: "/img/icons/hide.png",
      imgShowCurrent: "/img/icons/hide.png",
      imgShowConfirm: "/img/icons/hide.png",
      imagem: img,
      foto: null,
      excluir: false,
      dataNascimento: null,

      cadastro: {
        assistencia: [],
        nome: "",
        dataNascimento: "",
        email: "",
        emailConfirma: "",
        celular: "",
        cpf: "",
        cep: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        referencia: "",
        senha: "",
        senhaConfirma: "",
        regulamento: false,
        politica: false,
        receberEmails: false,
        tokenReCaptcha: "",
        senhaAtual: null,
      },
      emailEsqueci: "",
      zIndex: false,
      passwordFieldCurrent: "password",
      ipUsuario: null,
    };
  },
  validations: {
    cadastro: {
      email: {
        required,
        email,
      },
      nome: {
        required,
      },
      dataNascimento: {
        required,
      },
      cpf: {
        required,
      },
      cep: {
        required,
      },
      endereco: {
        required,
      },
      numero: {
        required,
      },
      bairro: {
        required,
      },
      cidade: {
        required,
      },
      uf: {
        required,
      },
      celular: {
        required,
      },
      emailConfirma: {
        required,
        email,
      },
      regulamento: {
        regucheck(regulamento) {
          return regulamento;
        },
      },
      politica: {
        regupoli(politica) {
          return politica;
        },
      },
      senha: {
        required,
        strongPassword(senha) {
          return (
            /[A-Z]/.test(senha) && // checks upperCase char
            /[a-z]/.test(senha) && // checks for a-z
            /[0-9]/.test(senha) && // checks for 0-9
            /\W|_/.test(senha) && // checks for special char
            senha.length >= 8
          );
        },
      },
      senhaConfirma: {
        required,
        sameAs: sameAs("senha"),
      },
      senhaAtual: {
        required,
        sameAs: sameAs("senha"),
      },
    },
  },
  computed: {
    ...mapState({
      fotoUsuario: state => state.usuario.fotoUsuario,
      autorizadasUsuario: (state) => state.usuario.autorizada,
    }),
    emailLoginErro,
    senhaLoginErro,
    dataErro,
    nomeErro,
    cpfErro,
    cepErro,
    enderecoErro,
    numeroErro,
    bairroErro,
    cidadeErro,
    ufErro,
    celularErro,
    confirmaEmailErro,
    confirmaSenhaErro,
    regulamentoErro,
    politicaErro,
  },
  mounted() {
    this.buscarDadosDoUsuario();
  },

  watch: {
    "cadastro.cep": function (value) {
      if (value.length === 9) {
        this.buscaCep();
      }
    },
  },
  methods: {
    ...mapActions({
      defineFotoUsuario: "usuario/defineFotoUsuario"
    }),
    selectPessoais() {
      this.pessoais = true;
      this.profissionais = false;
      this.endereco = false;
      this.seguranca = false;
      this.comunicacao = false;
    },
    selectProfissionais() {
      this.pessoais = false;
      this.profissionais = true;
      this.endereco = false;
      this.seguranca = false;
      this.comunicacao = false;
    },
    selectEndereco() {
      this.pessoais = false;
      this.profissionais = false;
      this.endereco = true;
      this.seguranca = false;
      this.comunicacao = false;
    },
    selectSeguranca() {
      this.pessoais = false;
      this.profissionais = false;
      this.endereco = false;
      this.seguranca = true;
      this.comunicacao = false;
    },
    selectComunicacao() {
      this.pessoais = false;
      this.profissionais = false;
      this.endereco = false;
      this.seguranca = false;
      this.comunicacao = true;
    },
    mudarSenha() {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
          this.ipUsuario = ip;
          const idUser = localStorage.getItem('idUser')
          const data = {
            idUsuario: Number(idUser),
            senha: this.cadastro.senhaAtual,
            novaSenha: this.cadastro.senha,
            confirmarNovaSenha: this.cadastro.senhaConfirma,
            ip: this.ipUsuario,
          }
          alterarSenhaMeuPerfil(data).then((resp) => {
            const senhaAtualInvalida = this.cadastro.senhaAtual === null;
            const senhalInvalida = this.cadastro.senha === null;
            const senhaANovaInvalida = this.cadastro.senhaConfirma === null;
            if (senhaAtualInvalida && senhalInvalida && senhaANovaInvalida) {
              this.$store.commit("SET_ALERTA", {
                show: true,
                tipo: "erro",
                mensagem: "Campos inválidos"
              });
            } else {
              this.$store.commit("SET_ALERTA", {
                show: true,
                tipo: "sucesso",
                mensagem: resp.data
              });
            }
          })
        });
    },
    showCurrentPass() {
      this.passwordFieldCurrent =
        this.passwordFieldCurrent === "password" ? "text" : "password";
      this.imgShowCurrent =
        this.imgShowCurrent === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    showPass() {
      this.passwordField =
        this.passwordField === "password" ? "text" : "password";
      this.imgShow =
        this.imgShow === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    showPassConfirm() {
      this.passwordFieldConfirm =
        this.passwordFieldConfirm === "password" ? "text" : "password";
      this.imgShowConfirm =
        this.imgShowConfirm === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    validarCelularCorreto() {
      let nCelular = this.cadastro.celular.replace(/\D/g, "")
      if (nCelular.length < 11) {
        this.$store.commit("SET_ALERTA", {
          show: true,
          tipo: "erro",
          mensagem: "O número de celular não parece válido"
        });
        return false
      } else {
        return nCelular
      }
      // const data = new FormData();
      // data.append("numero", this.cadastro.celular.replace(/\D/g, ""));
      // validarCelular(nCelular).then((resp) => {
      //   this.celularInvalido = resp.data.mensagem;
      // });
    },
    irParaHome() {
      this.$router.push("/");
    },
    modalMudarSenha() {
      this.$store.commit("interacoes/SET_MODAL_REDEFINIR_SENHA_MEU_PERFIL", {
        ativado: true,
        mensagem: "",
        tipo: "",
      });
    },
    buscaCep() {
      const options = {
        methods: "GET",
        mode: "cors",
        cache: "default",
      };
      const getCep = `https://viacep.com.br/ws/${this.cadastro.cep}/json/`;
      fetch(getCep, options).then((resp) => {
        resp.json().then((data) => {
          this.cadastro.endereco = data.logradouro;
          this.cadastro.bairro = data.bairro;
          this.cadastro.cidade = data.localidade;
          this.cadastro.uf = data.uf;
        });
      });
    },

    buscarDadosDoUsuario() {
      buscarPerfilUsuario().then((resp) => {
        this.id = resp.data.idUsuario;
        this.cadastro.nome = resp.data.nome;
        this.cadastro.cpf = resp.data.cpf;
        this.cadastro.email = resp.data.email;
        this.cadastro.emailConfirma = resp.data.email;
        this.cadastro.cargo = resp.data.cargo;
        // this.cadastro.emailConfirma = resp.data.usuarioParticipante.usuario.email;
        this.cadastro.celular = resp.data.celular;
        this.cadastro.regulamento = resp.data.aceiteRegulamento;
        this.cadastro.politica = resp.data.aceitePoliticaPrivacidade;
        this.cadastro.receberEmails = resp.data.aceiteReceberComunicacao;
        this.imagePrev =
          resp.data.foto === null || resp.data.foto === "null"
            ? "/img/icons/img-upload.png"
            : resp.data.foto;
        this.fotoAtual = resp.data.foto;
        this.cadastro.dataNascimento = moment(resp.data.dataNascimento).format(
          "DD/MM/YYYY"
        );
        this.dataNascimento = resp.data.dataNascimento;
        // this.cadastro.dataNascimento = resp.data.usuario.dataNascimento;

        if (resp.data.autorizadas.length > 0) {
          resp.data.autorizadas.forEach((item, index) => {
            this.cadastro.assistencia.push({
              autorizadaId: index,
              autorizadaNomeFantasia: item
            })
          })
          this.boxAssistenciasSelecionas = this.cadastro.assistencia[0]
        }

        this.cadastro.cep = resp.data.cep ? resp.data.cep : "";
        this.cadastro.endereco = resp.data.endereco;
        this.cadastro.numero = resp.data.numero;
        this.cadastro.complemento =
          resp.data.complemento === "null" ? "" : resp.data.complemento;
        this.cadastro.bairro = resp.data.bairro;
        this.cadastro.cidade = resp.data.cidade;
        this.cadastro.uf = resp.data.uf;
        this.cadastro.referencia =
          resp.data.pontoReferencia === "null" ? "" : resp.data.pontoReferencia;
        this.cadastro.usuarioAtivo = resp.data.ativo;
        this.cadastro.tipoAutorizada = resp.data.tipoAutorizada
        this.cadastro.idsAutorizadas = []

        resp.data.idsAutorizadas.forEach((item, index) => {
          this.cadastro.idsAutorizadas.push({
            id: resp.data.idsAutorizadas[index],
          })
        });
        this.cadastro.idStatus = resp.data.idStatus
        this.cadastro.idCargo = resp.data.idCargo
        this.defineFotoUsuario(resp.data.foto)
      });
    },
    linkVoltar() {
      this.$store.commit("interacoes/SET_MODAL_DESISTIR_CADASTRO", {
        ativado: true,
        mensagem: "SAIR",
        tipo: "",
      });
      this.$router.push("/");
    },
    prevImgChange(e) {
      const file = e.target.files[0];
      this.imagePrev = URL.createObjectURL(file);
      this.foto = file;
    },
    confereSenha() {
      this.errors = true;
    },
    mudarBack() {
      // console.log("muda");
      this.zIndex = !this.zIndex;
    },
    aceitoRegulamento() {
      this.cadastro.regulamento = true;
    },
    excluirConta() {
      this.excluir = true;
    },
    aceitoPolitica() {
      this.cadastro.politica = true;
    },
    changeCheckRegulamento() {
      this.positionLinkRegulamento = {
        position: "absolute",
      };
    },

    changeCheckPolitica() {
      this.positionLinkPolitica = {
        position: "absolute",
      };
    },

    toggleView() {
      this.esqueceuSenha = !this.esqueceuSenha;
    },
    enterParaCadastro() {
      this.$v.cadastro.$invalid ? false : this.atualizarCadastro();
    },

    verificaCaptcha(e) {
      this.cadastro.tokenReCaptcha = e;
      this.captcha = true;
    },

    recarregarCapitcha() {
      this.mostraRecaptcha = false;
      this.captcha = false;
      setTimeout(this.recarregarCapitchaTrue, 1000);
    },
    recarregarCapitchaTrue() {
      this.mostraRecaptcha = true;
    },
    expiraCaptcha() {
      this.captcha = false;
    },

    formaData() {
      const idUser = localStorage.getItem("idUser");
      const data = new FormData();
      data.append("IdUsuario", idUser);
      // if (this.foto) {
      data.append("NovaFoto", this.foto);
      // }
      data.append("Nome", this.cadastro.nome);
      // data.append("Email", this.cadastro.email);
      // data.append("Celular", this.cadastro.celular.replace(/\D/g, ""));
      data.append("CEP", this.cadastro.cep.replace(/\D/g, ""));
      data.append("Endereco", this.cadastro.endereco);
      data.append("Numero", Number(this.cadastro.numero));
      data.append("Complemento", this.cadastro.complemento);
      data.append("Bairro", this.cadastro.bairro);
      data.append("Cidade", this.cadastro.cidade);
      data.append("UF", this.cadastro.uf == null ? '' : this.cadastro.uf);
      // data.append("Cargo", this.cadastro.idCargo);
      // data.append("DataNascimento", this.cadastro.dataNascimento);
      data.append("PontoReferencia", this.cadastro.referencia);
      data.append("AceiteReceberComunicacao", this.cadastro.receberEmails);
      // data.append("TipoAutorizada", this.cadastro.tipoAutorizada[0]); // Não recebo
      // data.append("IdsAutorizadas", this.cadastro.idsAutorizadas); // Não recebo
      this.cadastro.idsAutorizadas.forEach(({ id }) => {
        data.append("IdsAutorizadas", id); // Apenas usuário site
      })
      data.append("IdStatus", this.cadastro.idStatus); // Não recebo

      return data;
    },

    atualizarCadastro() {
      if (!this.validarCelularCorreto()) {
        return
      }
      // const idUser =  localStorage.getItem('idUser')
      utualizarCadastroUsuario(this.formaData()).then(() => {
        this.defineFotoUsuario(this.imagePrev)
      });

      // cadastrarUsuario(this.formaData()).then(() => {
      //   this.$store.commit("interacoes/SET_MODAL_CADASTRO_PARABENS", {
      //     ativado: true,
      //     mensagem: "Parabéns",
      //     tipo: "",
      //   });

      //   this.$store.commit("SET_ALERTA", {
      //     show: true,
      //     tipo: "sucesso",
      //     mensagem: resp.data.message
      //   });
      // });
    },
    chamaModalRegulamento() {
      if (this.cadastro.regulamento) {
        return (this.cadastro.regulamento = false);
      }

      this.$store.commit("interacoes/SET_MODAL_DE_REGULAMENTO", {
        ativado: true,
        mensagem: "Regulamento",
        tipo: "",
      });
    },
    chamaModalPolitica() {
      if (this.cadastro.politica) {
        return (this.cadastro.politica = false);
      }

      this.$store.commit("interacoes/SET_MODAL_POLITICA_PRIVACIDADE", {
        ativado: true,
        mensagem: "Politica",
        tipo: "",
      });
    },
    chamaModalExcluirConta() {
      this.$store.commit("interacoes/SET_MODAL_EXCLUIR_CONTA", {
        ativado: true,
        mensagem: "Excluir",
        tipo: "",
      });
    },
    enterParaSenha() {
      this.$v.emailEsqueci.$invalid ? false : this.esqueciSenha();
    },
  },
};
</script>

<style lang="scss">
#profissionais {
  .multiselect__tags {
    border: 1px solid #707070;
    background: none !important;
    border-radius: 5px !important;
    width: 100%;
    height: 25px !important;
    padding: 9px 0 0 5px !important;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .multiselect__single {
      top: 5px;
    }
  }

  .multiselect__select {
    height: 25px !important;
    top: 9px;
    z-index: 2;
  }

  .cargo {
    height: 40px;
  }
}
</style>

<style lang="scss" scoped>
*:disabled {
  background-color: #e9e9e9;
}
.abas {
  width: 220px;
  margin-top: 30px;

  h1 {
    margin: 25px 0;
    text-align: left;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.ativo {
      font-weight: bold;
    }

    .bolinha {
      width: 10px;
      height: 10px;
      background-color: green;
      border-radius: 10px;
    }
  }
}

.alterarSenha {
  p {
    margin-bottom: 15px;

    span {
      font-weight: bold;
      cursor: pointer;
      color: rgb(66, 66, 206);
    }
  }
}

.login-main {
  display: flex;
  align-items: top;
  justify-content: center;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 20px;
  background: #fff;
  position: inherit;

  .mobi,
  .desk {
    font-size: 32px;
    font-weight: 500;
    color: $grafite;
  }

  .mobi {
    display: none;
  }

  .desk {
    display: block;
  }

  @media (max-width: 700px) {
    .mobi {
      display: block;
    }

    .desk {
      display: none;
    }
  }
}

.modal-login {
  width: 100%;
  max-width: 800px;

  @media (max-width: 700px) {
    max-width: 450px;
  }
}

.container-login-img {
  cursor: pointer;
  padding: 6px 7%;
  height: 60px;
  background: $bg-header-cadastro;
  filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.09));
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;

  img {
    width: 100%;
    max-width: 150px;
    height: auto;
    max-height: 230px;
    pointer-events: none;

    @media (max-width: 700px) {
      max-height: 150px;
    }
  }

  @media (max-width: 700px) {
    text-align: center;
  }
}

.container-login,
.container-senha {
  text-align: left;
  color: $cinza-interno;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  align-items: baseline;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.container-login-cadastro {
  text-align: left;
  color: $cinza-interno;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  min-height: 250px;

  @media (max-width: 700px) {
    width: 100%;
  }
}

.btn-enviar-login {
  color: $texto-botao-login;
  background: $bg-botao-login;

  &:hover {
    background: darken($bg-botao-login, 5%);
  }
}

.container-senha {
  p {
    font-size: 18px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    color: $textos-login;
  }

  button {
    margin-top: 15px;
  }
}

label {
  font-size: 14px;
}

input {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  color: $textos-login;
  border: 1px solid #707070;
  border-radius: 5px;
  outline: none;
  background: none;
  height: 34px;

  &::placeholder {
    color: $neutro4;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $textos-login;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
  }
}

input[type="checkbox"] {
  height: 19px;
  width: 15px;
  margin: 0;
  margin-bottom: 5px;
}

input:checked {
  position: relative;

  &::after {
    content: url(/img/icons/check.png);
    position: absolute;
    top: 2px;
    left: -1px;
    width: 15px;
    height: 19px;

    img {
      width: 15px;
      height: 19px;
    }
  }
}

.senha-voltar {
  background: none;
  text-align: center;
  display: block;
  cursor: pointer;
  color: $textos-login;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;

  @media (max-width: 700px) {
    margin-top: 20px;
  }
}

.excluir {
  opacity: 0.6;
}

.esqueceu-senha {
  position: relative;
  bottom: 15px;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: calc(100% - 135px);
}

.login-copy {
  width: 100%;
  text-align: center;

  p {
    font-size: 18px;
    color: $textos-login;
  }
}

.recaptcha {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.primeiro-acesso {
  margin-top: 30px;
  margin-bottom: 30px;
  color: $cinza-interno;

  a {
    cursor: pointer;
    color: $cinza-interno;
    font-weight: 700;
  }
}

.primeiro-link {
  font-weight: 700;
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.backOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2000px;
  background-color: #444444b0;
}

.img-upload {
  // margin-right: 45px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mask {
    width: 150px;
    border-radius: 50%;
    height: 150px;
    position: absolute;
    overflow: hidden;
  }

  span {
    cursor: pointer;
    display: block;
  }

  input[type="file"] {
    margin: 0;
    height: 150px;
    cursor: pointer;
    position: relative;
    opacity: 0;
  }

  @media (max-width: 700px) {
    margin-right: 0;
  }

  p {
    margin-top: 10px;
  }
}

.container-login-inputs {
  margin-top: 30px;
  width: 100%;

  .input {
    display: flex;
    flex-direction: column;
  }

  .input-medio {
    width: 48%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .input-50 {
    width: 48%;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    color: $grafite;
  }
}

input:checked {
  background: red;
}

.small {
  font-size: 13px;
  color: $grafite;
}

.mb-48 {
  margin-bottom: 35px;
}

.label-check {
  margin: 4px;
  position: absolute;
}

.mb-1 {
  margin-bottom: 10px;
}

.error {
  margin-top: 10px;
}

.pass-hide {
  width: 30px;
  position: absolute;
  right: 10px;
  top: 24px;
  cursor: pointer;
}

.prev-img {
  // position: absolute;
  height: 150px;
  width: 150px;
}

.texto-topo {
  margin-top: 5rem;
  color: $cinza-interno;
  text-align: center;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.link-regulamento {
  left: 0;
  width: 265px;
  height: 20px;
}

.link-politica {
  left: 0;
  width: 315px;
  height: 20px;
}

.btn-senha {
  margin: auto !important;
  width: 50% !important;
  margin-bottom: 20px !important;
}

.container-login-botoes {
  display: flex;
  flex-direction: column;
  margin: 30px 0;

  .btn-salvar {
    background: $bg-btn-padrao;
    color: $branco;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: hover 0.3s;

    &:hover {
      background: $bg-btn-padrao;
      opacity: 0.9;
    }

    &:disabled {
      cursor: pointer;
      background: $bg-btn-padrao;
      opacity: 0.6;
      color: $branco;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.checkReceberEmails {
  @media (max-width: 768px) {
    margin-top: 10px;
  }
}
</style>
