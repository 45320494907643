<template>
  <div class="modalPolitica">
    <div class="modalPolitica__container">
      <h1>POLÍTICA DE PRIVACIDADE <br />"PROGRAMA TEC CLUB"</h1>

      <div>
        <perfect-scrollbar ref="scroll">
          <div class="modalPolitica__politica">
            <p>
              A presente <span>Política de Privacidade</span> tem por finalidade
              demonstrar o compromisso da <span>WHIRLPOOL S.A.</span>, pessoa
              jurídica de direito privado, inscrita no CNPJ sob o nº.
              59.105.999/0001-86, com sede na Rua Olympia Semeraro 675, Prédio
              Administrativo, Jardim Santa Emília, São Paulo, SP, com a
              privacidade e a proteção dos dados pessoais coletados de seus
              <span>USUÁRIOS</span>, estabelecendo as regras sobre a coleta,
              registro, armazenamento, uso, compartilhamento, enriquecimento e
              eliminação dos dados coletados dentro do escopo dos serviços e
              funcionalidades do <span>PROGRAMA TEC CLUB</span>, de acordo com
              as leis em vigor.
            </p>
            <p>
              <span>CADASTRO DO USUÁRIO</span><br />

              As funcionalidades do <span>PROGRAMA TEC CLUB</span> só poderão
              ser acessadas por meio de um cadastro do <span>USUÁRIO</span>.
              Esse cadastro permitirá ao usuário participar do PROGRAMA TEC
              CLUB, possibilitando o recebimento de pontos, conforme as regras
              do PROGRAMA TEC CLUB e o resgate de tais pontos nos parceiros do
              PROGRAMA TEC CLUB. Para isso, o <span>USUÁRIO</span> será obrigado
              a disponibilizar uma série de informações como seu nome, endereço
              residencial, números de seus documentos, entre outras.
            </p>
            <p>
              Por fim, como condição para o acesso a essas funcionalidades
              exclusivas do <span>PROGRAMA TEC CLUB</span>,
              <span class="underline"
                >o <span>USUÁRIO</span> declara (i) ser maior de 18 (dezoito)
                anos; e (ii) que fez a leitura completa e atenta das regras
                deste documento e dos
                <span
                  >Termos e Condições do PROGRAMA TEC CLUB e de Uso do
                  Site</span
                >, estando plenamente ciente, e conferindo, assim, sua livre e
                expressa concordância com os termos aqui estipulados</span
              >. Caso não esteja de acordo com estas regras e condições, o
              <span>USUÁRIO</span> deverá descontinuar o seu cadastro e não terá
              acesso ao <span>PROGRAMA TEC CLUB</span>.
            </p>
            <h3>1. DADOS COLETADOS, FORMA E FINALIDADE DA COLETA</h3>
            <p>
              1.1. Os dados pessoais são coletados quando o
              <span>USUÁRIO</span> os insere ou submete voluntariamente ao
              acessar e interagir com as funcionalidades disponibilizadas no
              <span>PROGRAMA TEC CLUB</span>, que inclui:
            </p>

            <table>
              <tr>
                <th class="bg">TIPOS DE DADOS</th>
                <th class="bg">DADOS PESSOAIS</th>
                <th class="bg">FINALIDADE DE USO DOS DADOS</th>
              </tr>
              <tr>
                <td class="bg" rowspan="2"><span>DADOS CADASTRAIS</span></td>
                <td rowspan="2">
                  <span
                    >Nome<br />
                    completo;<br
                  /></span>
                  E-mail pessoal;<br />
                  CPF;
                </td>
                <td>Identificar o <span>USUÁRIO</span>.</td>
              </tr>
              <tr>
                <td>
                  Portabilidade dos dados cadastrais para outro Controlador do
                  mesmo ramo de nossa atuação, caso solicitado pelo
                  <span>USUÁRIO</span>..
                </td>
              </tr>
              <tr>
                <td class="bg" rowspan="2"><span></span></td>
                <td rowspan="8">
                  Data de nascimento;<br />
                  Número do telefone pessoal;<br />
                  Número do celular pessoal;<br />
                  Sexo;<br />
                  Estado Civil;<br />
                  Time de Futebol;<br />
                  Endereço de entrega do produto;<br />
                  Endereço profissional;<br />
                  Senha de acesso;<br />
                  e Foto do usuário.
                </td>
                <td>
                  Cumprir as obrigações decorrentes do uso dos serviços do
                  <span>PROGRAMA TEC CLUB</span>.
                </td>
              </tr>
              <tr>
                <td>
                  Administrar, prestar os serviços, creditar pontos, resgatar
                  pontos e entregar produtos adquiridos por meio do
                  <span>PROGRAMA TEC CLUB</span>.
                </td>
              </tr>
              <tr>
                <td class="bg" rowspan="6"><span></span></td>
              </tr>
              <tr>
                <td>
                  Informar sobre novidades, funcionalidades, conteúdos, notícias
                  e demais eventos relevantes para a manutenção do
                  relacionamento com o <span>USUÁRIO</span>.
                </td>
              </tr>
              <tr>
                <td>
                  Possibilitar ações promocionais e de marketing, dentre elas a
                  de promover nossos produtos e serviços e novas campanhas do
                  <span>PROGRAMA TEC CLUB</span>.
                </td>
              </tr>
              <tr>
                <td>
                  Realizar prevenção a fraudes, proteção ao crédito e riscos
                  associados, cumprimento de obrigações legais e regulatórias.
                </td>
              </tr>
              <tr>
                <td>
                  Compartilhar com as empresas
                  <span>PARCEIRAS DO PROGRAMA TEC CLUB</span> para que estas
                  possam entregar seus produtos que foram resgatados com os
                  pontos do <span>PROGRAMA TEC CLUB</span>.
                </td>
              </tr>
              <tr>
                <td>
                  Compartilhar com as empresas
                  <span
                    >PARCEIRAS DO
                    <span class="roxo">PROGRAMA TEC CLUB</span></span
                  >, para informar o <span>USUÁRIO</span> sobre novidades,
                  funcionalidades, conteúdos, notícias e demais eventos
                  relevantes para a manutenção do relacionamento com o
                  <span>USUÁRIO</span>, e também para que tais parceiras possam
                  estudar novos negócios a serem oferecidos ao
                  <span>USUÁRIO</span>. Além do compartilhamento dos dados com
                  as empresas <span>PARCEIRAS DO PROGRAMA TEC CLUB</span>,
                  poderá ocorrer o compartilhamento desses mesmos dados com as
                  empresas do grupo econômico da <span>WHIRLPOOL S.A.</span>
                </td>
              </tr>
              <tr>
                <td class="bg">
                  <span>DADOS DE IDENTIFICAÇÃO DIGITAL</span>
                </td>
                <td>Endereço IP e Porta Lógica de Origem;</td>
                <td>
                  Cumprir obrigação estabelecida pelo Marco Civil da Internet -
                  Lei 12.965/2014 - e pela
                </td>
              </tr>
              <tr>
                <td class="bg" rowspan="2"></td>
                <td rowspan="4">
                  Registros de interações com o
                  <span>PROGRAMA TEC CLUB</span>;<br />
                  Telas acessadas,<br />
                  dispositivo (versão do sistema operacional, Geolocalização;<br />
                  Câmera dispositivo móvel;<br />
                  Aplicativos instalados, se necessário); Biometria.
                </td>
                <td>
                  Lei Geral de Proteção de Dados (LGPD) - Lei nº 13.709/2018.
                </td>
              </tr>
              <tr>
                <td>Identificar o <strong>USUÁRIO</strong></td>
              </tr>

              <tr>
                <td class="bg" rowspan="2"></td>
                <td>
                  Administrar, prestar os serviços, creditar pontos, vender e
                  entregar produtos adquiridos por meio do
                  <span>PROGRAMA TEC CLUB</span>.
                </td>
              </tr>

              <tr>
                <td>
                  Realizar prevenção a fraudes, proteção ao crédito e riscos
                  associados, cumprimento de obrigações legais e regulatórias.
                </td>
              </tr>
            </table>

            <p>
              1.2. <span class="underline">DA VERACIDADE DOS DADOS</span>. Não
              somos responsáveis pela precisão, veracidade ou falta dela nas
              informações que o USUÁRIO submete pelo
              <span>PROGRAMA TEC CLUB</span> ou pela sua desatualização, quando
              é de responsabilidade do USUÁRIO prestá-las com exatidão ou
              atualizá-las.
            </p>

            <p>
              1.3. <span class="underline">USO DE TECNOLOGIAS</span>. Poderemos
              fazer uso de tecnologias para: (i) registro da navegação; (ii)
              coleta de opiniões sobre o <span>PROGRAMA TEC CLUB</span>; (iii)
              atendimento ao participante; (iv) registro de eventos durante
              navegação; e (v) registro de dados de navegação não identificáveis
              para análise.
            </p>

            <p>
              1.3.1. Todas as tecnologias utilizadas respeitarão sempre a
              legislação vigente e os termos desta
              <span>Política de Privacidade</span>.
            </p>

            <p>
              1.4. <span class="underline">DO CONSENTIMENTO</span>. O
              consentimento que o <span>USUÁRIO</span> nos fornece para as
              finalidades de uso dos dados é coletado de forma individual,
              clara, específica e legítima.
            </p>

            <p>
              1.5.
              <span class="underline">DAS ALTERAÇÕES DO CONSENTIMENTO</span>.
              Por meio do endereço eletrônico
              <span class="azul">privacidadebr@whirlpool.com</span>, o
              <span>USUÁRIO</span> poderá solicitar a alteração dos diferentes
              tipos de consentimento para tratamento de seus dados, conceder
              novas permissões ou solicitar a retirada de seu consentimento para
              as formas de coleta até então permitidas, sendo avisado das
              consequências que a retirada de consentimento poderá lhe causar,
              principalmente quanto às suas interações no
              <span>PROGRAMA TEC CLUB</span>.
            </p>

            <p>
              1.6. <span class="underline">COMPARTILHAMENTO DOS DADOS</span>.
              Além das situações de compartilhamento dos dados indicadas no item
              1.1. acima, os dados coletados e as atividades registradas também
              poderão ser compartilhados:
            </p>
            <p>
              a. Com autoridades judiciais, administrativas ou governamentais
              competentes, sempre que houver determinação legal, requerimento,
              requisição ou ordem judicial; e
            </p>
            <p>
              b. De forma automática em caso de movimentações societárias, como
              fusão, aquisição e incorporação.
            </p>
            <p>
              1.7. <span class="underline">BASE DE DADOS</span>. A base de dados
              formada por meio da coleta de dados no
              <span>PROGRAMA TEC CLUB</span> é de nossa propriedade e
              responsabilidade, sendo que seu uso, acesso e compartilhamento,
              quando necessários, serão feitos dentro dos limites e propósitos
              dos nossos negócios e descritos nesta Política de Privacidade.
            </p>
            <p>
              1.7.1. O <span>USUÁRIO</span> é corresponsável pelo sigilo de seus
              dados pessoais. O compartilhamento de senhas e dados de acesso ao
              <span>PROGRAMA TEC CLUB</span> viola esta
              <span>Política de Privacidade</span> e os
              <span>Termos de Uso</span> do <span>PROGRAMA TEC CLUB</span> .
            </p>
            <p>
              1.8. Internamente, os dados coletados são acessados somente por
              profissionais devidamente autorizados, respeitando os princípios
              de proporcionalidade, necessidade e relevância para os objetivos
              do
              <span
                >site CONSUL, site BRASTEMP, site KITCHENAID, site B.BLEND e
                site PROGRAMA TEC CLUB</span
              >, além do compromisso de confidencialidade e preservação da
              privacidade nos termos desta <span>Política de Privacidade</span>.
            </p>
            <h3>2. COMO ARMAZENAMOS OS DADOS E REGISTROS</h3>
            <p>
              2.1. Os dados coletados e os registros de atividades serão
              armazenados em ambiente seguro e controlado pelo prazo mínimo
              estipulado, conforme abaixo:
            </p>
            <p>
              2.1.1. Dados Pessoais que poderão ser utilizados para fins
              administrativos, jurídicos ou solicitados por autoridades públicas
              de acordo com o prazo de retenção legal e/ou regulatória, podendo
              variar entre 5 e 20 anos.
            </p>
            <p>
              2.1.2. Dados de Identificação Digital, cuja finalidade seja para
              provas em eventual ação cível, o prazo de armazenamento é de 6
              (seis) meses, conforme previsto no artigo 15 do Marco Civil da
              Internet.
            </p>
            <p>
              2.2. Caso seja interesse do <span>USUÁRIO</span>, os dados poderão
              ser apagados antes desse prazo, mediante sua solicitação. No
              entanto, podemos eventualmente ter que manter os dados do
              <span>USUÁRIO</span> por período superior, por motivo de lei,
              ordem judicial, prevenção à fraude, proteção ao crédito, interesse
              legítimo, inclusive para que os dados possam servir de prova em
              instruções processuais judiciais e extrajudiciais, e por outras
              formas autorizadoras para tratamento de dados, em conformidade com
              a legislação vigente. Findo o prazo e a necessidade legal, os
              dados serão excluídos com uso de métodos de descarte seguro, ou
              utilizados de forma anonimizada para fins estatísticos.
            </p>
            <p>
              2.3. Os dados coletados serão armazenados em servidores
              localizados no Brasil, bem como em ambiente de uso de recursos ou
              servidores na nuvem (cloud computing), o que enseja, neste último
              caso, transferência ou processamento dos dados fora do Brasil,
              mais precisamente, nos Estados Unidos.
            </p>
            <h3>
              3. EXIBIÇÃO, RETIFICAÇÃO, LIMITAÇÃO, OPOSIÇÃO E EXCLUSÃO DE DADOS
            </h3>
            <p>
              3.1. O <span>USUÁRIO</span> pode solicitar a exibição ou
              retificação de seus dados pessoais através do telefone
              <span>0800 236 0011</span>, “Fale Conosco” no site
              <a
                class="azul"
                target="_blank"
                href="https://www.programatecclub.com.br"
                >www.programatecclub.com.br</a
              >
              ou pelo e-mail
              <span class="azul">privacidadebr@whirlpool.com</span>.
            </p>
            <p>
              3.2. Através desse canal, o <span>USUÁRIO</span> poderá também:
            </p>
            <p>a. requerer a limitação do uso de seus dados pessoais;</p>
            <p>b. manifestar sua oposição ao uso de seus dados pessoais, e</p>
            <p>
              c. solicitar a exclusão de seus dados pessoais que coletamos,
              desde que eventuais Contas de Acesso tenham sido canceladas e
              decorrido o prazo legal mínimo relacionado à guarda de dados.
            </p>
            <p>
              3.2.1. Caso o <span>USUÁRIO</span> retire seu consentimento para
              finalidades fundamentais ao regular funcionamento do
              <span>PROGRAMA TEC CLUB</span>, os serviços e funcionalidades do
              <span>PROGRAMA TEC CLUB</span> ficarão indisponíveis.
            </p>
            <p>
              3.2.2. Mesmo em caso do <span>USUÁRIO</span> não conceder seu
              consentimento para as finalidades facultativas, relacionadas ao
              envio de algumas informações, novidades, conteúdos, notícias e
              demais eventos relevantes para a manutenção do relacionamento, os
              serviços e funcionalidades do
              <span
                >site CONSUL, site BRASTEMP, site KITCHENAID, site B.BLEND e
                site PROGRAMA TEC CLUB</span
              >
              continuarão sendo disponibilizados ao mesmo regularmente.
            </p>
            <p>
              3.3. Conforme já indicado no item 2.2. acima, para fins de
              auditoria, segurança da informação, controle de fraudes, proteção
              ao crédito e preservação de direitos, poderemos permanecer com o
              histórico de registro dos dados do <span>USUÁRIO</span> por prazo
              maior do que os indicados acima, nas hipóteses que a lei ou norma
              regulatória assim estabelecer, ou, para preservação de direitos.
            </p>
            <h3>4. DISPOSIÇÕES GERAIS</h3>
            <p>
              4.1. Temos o direito de alterar o teor desta
              <span>Política de Privacidade</span> a qualquer momento, conforme
              a finalidade ou necessidade, tal qual para adequação e
              conformidade legal de disposição de lei ou norma que tenha força
              jurídica equivalente, cabendo a você verificá la sempre que
              efetuar o acesso ao <span>PROGRAMA TEC CLUB</span>.
            </p>
            <p>
              4.1.1. Ocorrendo atualizações neste documento e que demandem nova
              coleta de consentimento, serão notificadas por meio dos contatos
              que nos forneceram o cadastro.
            </p>
            <p>
              4.2. Em caso de qualquer dúvida com relação às disposições
              constantes desta <span>Política de Privacidade</span>, o
              <span>USUÁRIO</span> poderá entrar em contato por meio dos canais
              de atendimento apontados a seguir, cujo horário de funcionamento é
              de segunda à sábado, das 8h00 horas às 20h00 horas, exceto
              feriados nacionais:
            </p>
            <p>a) telefone: <span>0800 236 0011</span></p>
            <p>
              b. Fale conosco no site
              <a
                target="_blank"
                class="azul"
                href="https://www.programatecclub.com.br"
                >www.programatecclub.com.br.</a
              >
            </p>
            <p>
              c. Pelo e-mail <span class="azul">privacidadebr@whirlpool.com</span>
            </p>
            <p>
              3. Caso empresas terceirizadas realizem o processamento de
              quaisquer dados que coletamos, as mesmas deverão respeitar as
              condições aqui estipuladas e nossas normas de Segurança da
              Informação, obrigatoriamente.
            </p>
            <p>
              4. Caso alguma disposição desta
              <span>Política de Privacidade</span> seja considerada ilegal ou
              ilegítima por autoridade da localidade em que resida ou da sua
              conexão à Internet, as demais condições permanecerão em pleno
              vigor e efeito.
            </p>
            <p>
              5. O <span>USUÁRIO</span> reconhece que toda comunicação realizada
              por e-mail (aos endereços informados no seu cadastro), por
              telefone, SMS, aplicativos de comunicação instantânea ou qualquer
              outra forma digital e virtual também são válidas, eficazes e
              suficiente para a divulgação de qualquer assunto que se refere aos
              serviços que prestamos, bem como às condições de sua prestação ou
              a qualquer outro assunto nele abordado, ressalvadas as disposições
              expressamente diversas previstas nesta Política de Privacidade.
            </p>
            <h3>5. GLOSSÁRIO</h3>
            <p>
              5.1. Para os fins deste documento, devem se considerar as
              seguintes definições e descrições para seu melhor entendimento:
            </p>
            <p>
              <span>Cloud Computing</span>: Ou computação em nuvem, é tecnologia
              de virtualização de serviços construída a partir da interligação
              de mais de um servidor por meio de uma rede de informação comum
              (p.ex. a Internet), com objetivo de reduzir custos e aumentar a
              disponibilidade dos serviços sustentados.
            </p>
            <p>
              <span>Conta de Acesso</span>: Credencial de um
              <span>USUÁRIO</span> necessária para utilizar ou acessar as
              funcionalidades exclusivas do PROGRAMA TEC CLUB.
            </p>
            <p>
              <span>IP</span>: Abreviatura de Internet Protocol. É um conjunto
              alfanumérico que identifica os dispositivos dos
              <span>USUÁRIOS</span> na Internet.
            </p>
            <p>
              <span>Logs</span>: Registros de atividades dos
              <span>USUÁRIOS</span> efetuadas no <span>PROGRAMA TEC CLUB</span>.
            </p>
            <p>
              <span>Session ID</span>: Identificação da sessão do
              <span>USUÁRIO</span> quando é efetuado o acesso ao
              <span>PROGRAMA TEC CLUB</span>.
            </p>
            <p>
              <span>PROGRAMA TEC CLUB</span>: Designa o endereço eletrônico
              <a
                class="azul"
                target="_blank"
                href="https://www.programatecclub.com.br"
                >www.programatecclub.com.br</a
              >
              e seus subdomínios.
            </p>
            <p>
              <span>USUÁRIO</span>: Pessoa que acessa e utiliza as
              funcionalidades oferecidas no <span>PROGRAMA TEC CLUB</span>
            </p>
            <h3>6. LEI APLICÁVEL E JURISDIÇÃO</h3>
            <p>
              6.1. A presente Política de Privacidade será regida e interpretada
              segundo a legislação brasileira, no idioma português, sendo eleito
              o Foro da Comarca de domicílio do <span>USUÁRIO</span> para
              dirimir qualquer litígio ou controvérsia envolvendo o presente
              documento, salvo ressalva específica de competência pessoal,
              territorial ou funcional pela legislação aplicável.
            </p>
            <p><span>Atualização</span>: 16 de Setembro de 2021.</p>
          </div>
        </perfect-scrollbar>
      </div>

      <div class="modalPolitica__btn">
        <button
          v-if="mostrarBotao"
          @click="validarPoliticaPrivacidade"
          class="continuar"
        >
          ACEITO A POLÍTICA DE PRIVACIDADE
        </button>
        <button @click="fecharModal" class="voltar">FECHAR</button>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
export default {
  name: "ModalPoliticaPrivacidade",

  emits: ["aceitoPolitica"],

  components: {
    PerfectScrollbar,
  },

  props: {
    mostrarBotao: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dados: [
        { id: 1, nome: "Assistência 1" },
        { id: 2, nome: "Assistência 2" },
        { id: 3, nome: "Assistência 3" },
        { id: 4, nome: "Assistência 4" },
      ],

      form: {
        cliente: null,
      },
    };
  },

  methods: {
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_POLITICA_PRIVACIDADE", {
        ativado: false,
        mensagem: "Privacidade",
        tipo: "",
      });
    },

    validarPoliticaPrivacidade() {
      this.$emit("aceitoPolitica");

      this.$store.commit("interacoes/SET_MODAL_POLITICA_PRIVACIDADE", {
        ativado: false,
        mensagem: "Privacidade",
        tipo: "",
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.ps {
  height: 200px;
}
.modalPolitica {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    width: 762px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    @media (max-width: 768px) {
      width: 100%;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin: 59px 0;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 32px;
      color: $grafite;
      margin-bottom: 30px;
      // padding: 0 45px;
      font-weight: bold;
      width: 100%;

      @media (max-width: 768px) {
        padding: 0 24px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
  }

  &__politica {
    padding: 0 45px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 30px;
    }

    span {
      font-weight: bold;
    }

    table {
      margin-bottom: 30px;
      width: 100%;
      border: 1px solid $grafite;
      color: $grafite;

      .bg {
        background-color: rgb(255 229 153);
      }

      tr {
        line-height: 20px;
      }

      th,
      td {
        padding: 20px;
        border: 1px solid $grafite;
        text-align: center;
        vertical-align: middle;
        @media (max-width: 768px) {
          padding: 5px;
        }
      }
    }

    .roxo {
      color: #7030a0;
    }

    .azul {
      color: #0070c0;
      text-decoration: underline;
      font-weight: normal;
    }

    .underline {
      text-decoration: underline;
      font-weight: normal;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 24px;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 543px;
      margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;
      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .voltar {
      border: none;
      background: transparent;
      width: 260px;
      margin: 16px 0 42px 0;
      height: 49px;
      border-radius: 5px;
      color: $grafite;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 30px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>