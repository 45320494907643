<template>
  <div>
    <modal-exportar-dados
      v-if="exportar"
      @fechaModal="exportar = $event.value"
      :idAutorizada="autorizadaAtiva"
      tipoDownload="Performance"
    />

    <div class="btn-download-planilha">
      <button class="btn-exportar" @click="exportar = true">
        <img :src="iconesImgs.iconeDownload" class="icone" /> Exportar dados
      </button>
    </div>

    <grafico-barras titulo="Desempenho Equipe" />
  </div>
</template>

<script>
import GraficoBarras from "./Graficos/GraficoBarras.vue";
import iconesImgs from "@/utils/icones.js";
import ModalExportarDados from "@/components/modal/ModalExportarDados.vue";

import { mapState } from "vuex";

export default {
  components: {
    GraficoBarras,
    ModalExportarDados,
  },

  computed: {
    ...mapState({
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
    }),
  },

  data() {
    return {
      iconesImgs,
      exportar: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>