import client from "@/api";
import store from "@/store";
import { _rotaListarBannerHome } from "@/api/_caminhosApi";

export async function listarBannerHome() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaListarBannerHome()}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}