<template>
  <div class="treinamentosCarrousel">
    <div class="treinamentosCarrousel__textos">
      <span class="treinamento">Treinamentos</span>
      <!-- <span class="verMais">Ver mais</span> -->
    </div>
    <div class="treinamentos-img">

      <div v-if="!dadosMock">
        <div class="imgs-carrossel">
          <carousel :autoplay="true" :nav="true" :autoHeight="true"
            :responsive="{ 0: { items: 1, margin: 10 }, 768: { items: 3 } }" class="mock" v-if="mostraItens">
            <template slot="prev"><img :src="setaEsquerda" v-if="qtdItens > 3" alt="" class="slider-prev"></template>
            <template slot="next"><img :src="setaDireita" v-if="qtdItens > 3" alt="" class="slider-next"></template>
            <div class="treinamentosCarrousel__carrouselItem" v-for="treinamento in treinamentos" :key="treinamento.id">
              <a :href="treinamento.link" target="_blank">
                <img class="mobile" :src="treinamento.imagemMobile" />
                <img class="desktop" :src="treinamento.imagemDesktop" />
              </a>
            </div>
          </carousel>
        </div>
      </div>

      <div v-else class="dados-mock">
        <div class="imgs-carrossel" v-if="mostraItens">
          <carousel :nav="true" :autoHeight="true" :responsive="{ 0: { items: 1, margin: 10 }, 768: { items: 3 } }">
            <template slot="prev"><img :src="setaEsquerda" v-if="qtdItens > 3" class="slider-prev"></template>
            <template slot="next"><img :src="setaDireita" v-if="qtdItens > 3" class="slider-next"></template>
            <!-- <div class="treinamentosCarrousel__carrouselItem">
              <a href="https://www.universidadeencantamento.com.br/#/loginform?customerId=whirlpool" target="_blank">
                <img class="" src="~@/assets/images/tema/treinamento-uni6.png" />
              </a>
            </div> -->
            <!-- <div class="treinamentosCarrousel__carrouselItem">
              <a href="https://meet.google.com/fcm-bevo-xtz" target="_blank">
                <img class="" src="~@/assets/images/tema/EscolaTecnica_6.png" />
              </a>
            </div> -->
            <div class="treinamentosCarrousel__carrouselItem">
              <a href="https://meutecbook.com.br/home/login" target="_blank">
                <img class="" src="~@/assets/images/tema/treinamento3.png" />
              </a>
            </div>
          </carousel>
        </div>
      </div>
    </div>
    <!-- <carousel :autoWidth="true">
      <div class="treinamentosCarrousel__carrouselItem">
        <router-link to="/login">
          <img class="mobile" src="~@/assets/images/tema/treinamento-um.png">
          <img class="desktop" src="~@/assets/images/tema/treinamento-um-desktop.png">
        </router-link>
        <p>Treinamento 01</p>
        <button>ACESSAR</button>
      </div>
      <div class="treinamentosCarrousel__carrouselItem">
        <router-link to="/login">
          <img class="mobile" src="~@/assets/images/tema/treinamento-dois.png">
          <img class="desktop" src="~@/assets/images/tema/treinamento-dois-desktop.png">
        </router-link>
        <p>Treinamento 02</p>
        <button>ACESSAR</button>
      </div>
      <div class="treinamentosCarrousel__carrouselItem">
        <router-link to="/login">
          <img class="mobile" src="~@/assets/images/tema/treinamento-tres.png">
          <img class="desktop" src="~@/assets/images/tema/treinamento-tres-desktop.png">
        </router-link>
        <p>Treinamento 03</p>
        <button>ACESSAR</button>
      </div>
      <div class="treinamentosCarrousel__carrouselItem block-desktop">
        <router-link to="/login">
          <img class="mobile" src="~@/assets/images/tema/treinamento-quatro.png">
          <img class="desktop" src="https://placeimg.com/200/200/any?1">
        </router-link>
        <p>Treinamento 04</p>
        <button>ACESSAR</button>
      </div>
    </carousel> -->
  </div>
</template>

<script>
import { listarTreinamentos, listarTreinamentosPorAutorizada } from "@/api/treinamentos";
import { mapState } from "vuex";
import carousel from "vue-owl-carousel";
import setaDireita from "@/assets/images/icones/seta-direita.png";
import setaEsquerda from "@/assets/images/icones/seta-esquerda.png";

export default {
  name: "TreinamentosCarrousel",
  components: {
    carousel,
  },
  data() {
    return {
      treinamentos: [],
      dadosMock: true,
      mostraItens: true,
      setaDireita,
      setaEsquerda,
      autorizadaAtivaId: 0,
      qtdItens: 0
    };
  },
  computed: {
    ...mapState({
      cargoId: state => state.usuario.cargoUsuario,
      autorizadaAtiva: state => state.usuario.autorizadaAtiva
    })
  },
  mounted() {
    this.buscarTreinamentos();
  },
  watch: {
    cargoId() {
      this.defineAutorizadaAtiva();
    },
    autorizadaAtiva() {
      this.defineAutorizadaAtiva();
    }
  },
  methods: {
    defineAutorizadaAtiva() {
      this.autorizadaAtivaId = this.autorizadaAtiva;
      this.buscarTreinamentos();
    },
    buscarTreinamentos() {
      this.mostraItens = false;
      if (this.cargoId == 10) {
        listarTreinamentosPorAutorizada(this.autorizadaAtiva).then((resp) => {
          this.treinamentos = resp.data;
          setTimeout(() => {
            if (resp.data.length > 0) {
              this.dadosMock = false;
              this.qtdItens = resp.data.length
            }
            this.mostraItens = true;
          }, 500)
        });
        return
      }
      listarTreinamentos().then((resp) => {
        this.treinamentos = resp.data;
        setTimeout(() => {
          if (resp.data.length > 0) {
            this.dadosMock = false;
            this.qtdItens = resp.data.length
          }
          this.mostraItens = true;
        }, 500)
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.imgNavegacao {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.item-treinamento img {
  width: 100%;
  max-width: 265px;
}

.treinamentosCarrousel {
  margin: 1rem;

  // padding-left: 35px;
  @media (min-width: 768px) {
    max-width: 800px;
    padding-left: 0px;
    margin: 0;
  }

  .treinamentos-img {
    @media (min-width: 768px) {
      flex-direction: row;
    }

    .mobile {
      @media (min-width: 768px) {
        display: none;
      }
    }

    .mock {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    // .treinamentos-img-um {
    //   margin-bottom: 50px;
    //   @media(min-width: 768px) {
    //     margin-right: 150px;
    //   }
    // }
  }

  &__textos {
    margin: 2rem 0 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;

    @media (min-width: 768px) {
      width: 100%;
    }

    span {
      color: $grafite;
    }

    .treinamento {
      font-weight: 600;
      font-size: 16px;
    }

    .verMais {
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__carrouselItem {
    //width: 80%;

    margin-bottom: 10px;
    margin-right: 0;

    @media (min-width: 768px) {
      margin-right: 1rem;

      &:nth-child(3) {
        margin-right: 0;
      }

      //margin-right: 10px;
      // width: 260px;
      //height: 265px;
    }

    .mobile {
      @media (max-width: 768px) {
        flex-direction: column;
        display: flex;
      }
    }

    .desktop {
      height: 280px;
    }

    img {
      width: 100%;

    }

    p {
      font-size: 18px;
      text-align: center;
      color: $branco;
      position: absolute;
      bottom: 10px;

      @media (min-width: 768px) {
        top: 10px;
      }
    }

    button,
    .desktop {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    button {
      position: absolute;
      bottom: 20px;
      left: calc(50% - 51px);
      width: 102px;
      height: 30px;
      border-radius: 5px;
      background: #fff;

      background: $branco;
      border: none;

      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: $grafite;
      cursor: pointer;
    }
  }

  .block-desktop {
    @media (min-width: 768px) {
      display: none;
    }
  }
}
</style>