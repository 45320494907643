import client from "@/api";
import store from "@/store";
import { _rotaEspelho } from "@/api/_caminhosApi";

export function acessoAoEspelhoPorId(id, tokenAdmin) {
  console.log("token admin espelho", tokenAdmin)
  return new Promise((resolve) => {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaEspelho()}?id=${id}`)
    .then(resp => {
      resolve(resp);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
  });
}