<template>
	<div class="bg-footer">
		<ModalDeRegulamento v-if="modalAtivadoTecnicos" />
		<footer class="footer">
			<logo-empresa />
			<nav>
				<ul>
					<li @click="chamaModalRegulamento">
						Regulamento
					</li>
					<li @click="chamaModalPolitica">Política de privacidade</li>
					<li>
						<router-link to="/fale-conosco"> Fale conosco </router-link>
					</li>
				</ul>
			</nav>
		</footer>
	</div>
</template>

<script>
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
import ModalDeRegulamento from "@/components/modal/ModalDeRegulamento.vue";
import { mapState } from "vuex";
export default {
	name: "Footer",

	components: {
		LogoEmpresa,
		ModalDeRegulamento
	},

	computed: {
		...mapState({
			autorizaTelas: state => state.usuario.autorizaTelas,
			modalAtivadoTecnicos: state => state.interacoes.modalRegulamento.modalAtivo
		})
	},

	methods: {
		chamaModalRegulamento() {
			this.$store.commit("interacoes/SET_MODAL_DE_REGULAMENTO", {
				ativado: true,
				mensagem: "Regulamento",
				tipo: ""
			});
		},
		chamaModalPolitica() {
			this.$store.commit("interacoes/SET_MODAL_POLITICA_PRIVACIDADE", {
				ativado: true,
				mensagem: "Politica",
				tipo: ""
			});
		},
		aceitoPolitica() {
			this.cadastro.politica = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.bg-footer {
	margin-top: 1rem;
	background-color: $bg-footer;
}
.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	background: $bg-footer;
	padding: 36px 55px;

	@media (min-width: 768px) {
		max-width: 1200px;
		margin: 0 auto;
		flex-direction: row;
		justify-content: start;
	}

	img {
		width: 264px;
		@media (min-width: 768px) {
			width: 204px;
			margin-right: 180px;
		}
	}

	ul {
		padding-top: 30px;
		text-align: center;

		li {
			margin-bottom: 1rem;
		}

		@media (min-width: 768px) {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 0px;

			li {
				margin-right: 20px;
				font-size: 16px;
				line-height: 32px;
				color: $grafite;
				text-decoration: none;
				text-align: center;
				cursor: pointer;
			}
		}
		a {
			font-size: 16px;
			color: $grafite;
			text-decoration: none;
			text-align: center;
		}
	}
}
</style>
