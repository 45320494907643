<template>
  <Multiselect
    v-model="filtro.ano"
    label="ano"
    track-by="id"
    :options="anos"
    :multiple="false"
    :options-limit="200"
    placeholder=" "
    select-label=" "
    deselect-label=" "
    selected-label=" "
    :searchable="false"
    :allow-empty="false"
    @input="obterMesesDownload()"
  />
</template>

<script>
import Multiselect from "vue-multiselect";
import { obterMesesComPerformance } from "@/api/performance";
import { mapState } from "vuex";

export default {
  name: "InputAno",

  props: {
    tipo: {
      type: String,
    },
    tipoExtrato: Boolean,
  },

  components: {
    Multiselect,
  },

  computed: {
    ...mapState({
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
    }),
  },

  data() {
    return {
      resApi: [],
      mesesAno: [
        { id: 1, mes: "Janeiro" },
        { id: 2, mes: "Fevereiro" },
        { id: 3, mes: "Março" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Maio" },
        { id: 6, mes: "Junho" },
        { id: 7, mes: "Julho" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Setembro" },
        { id: 10, mes: "Outubro" },
        { id: 11, mes: "Novembro" },
        { id: 12, mes: "Dezembro" },
      ],
      anos: [],
      mesesFiltro: [],
      filtro: {
        mes: null,
        ano: null,
      },
    };
  },

  methods: {
    obterMesesDownload() {
      obterMesesComPerformance(this.filtro.ano.id, this.autorizadaAtiva).then(
        (resp) => {
          let mesesOrdemCrescente = resp.data.sort();
          this.mesesFiltro = mesesOrdemCrescente.map((item) =>
            this.mesesAno.find((numeroMes) => numeroMes.id == item)
          );

          this.mesesFiltro.sort(function (a, b) {
            if (a.id > b.id) {
              return 1;
            }
            if (a.id < b.id) {
              return -1;
            }
          });

          this.filtro.mes = this.mesesFiltro[this.mesesFiltro.length - 1];
          this.$emit("mesesLista", this.mesesFiltro);
          this.$emit("mesAtual", this.filtro.mes);
          this.$emit("anoEscolhido", this.filtro.ano);
        }
      );
    },
    defineData() {
      let anoInicial = 2021;
      let anoAtual = new Date().getFullYear();
      let res = anoAtual - anoInicial;
      for (let i = 0; i <= res; i++) {
        this.anos.push({ id: anoInicial + i, ano: anoInicial + i });
      }
      this.filtro.ano = this.anos[this.anos.length - 1];
    },
  },

  mounted() {
    this.defineData();
    this.obterMesesDownload();
  },
};
</script>