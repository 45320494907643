<template>
  <div class="login-main">
    <!-- Login -->

    <div class="modal-login">
      <div class="container-login-img"><LogoEmpresa /></div>

      <transition mode="out-in" name="fade-left" appear>
        <!-- Login -->
        <div class="container-login">
          <h1>Seja Bem-vindo (a)</h1>
            <img src="/img/icons/happy.png"/>
           <p class="primeiro-acesso">Lorem ipsum dolor sit amet, consectetuer adipiscing elit,sed diam nonummy nibh euismod tincidunt 
ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
       
        </div>
        <!-- Esqueceu Senha -->
      </transition>
    </div>
  </div>
</template>

<script>
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
export default {
  name: "bemvindo",
  components: {
    LogoEmpresa,
  },
  data() {
    return {
      assistencia: '',
    };
  }
};
</script>

<style lang="scss" scoped>
.login-main {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 20px;
  background: #fff;
  @media (max-width: 700px) {

  }
}
.modal-login {
  width: 100%;
  max-width: 450px;
}
.container-login-img {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 60%;
  margin: 0 auto;
  padding: 0 20px;
  img {
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 250px;
    pointer-events: none;
    @media (max-width: 700px) {
      max-height: 150px;
    }
  }
}
.container-login,
.container-senha {
  text-align: left;
  color: $cinza-interno;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 250px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.btn-enviar-login {
  color: $texto-botao-login;
  background: $bg-botao-login;
  &:hover {
    background: darken($bg-botao-login, 5%);
  }
}
.container-senha {
  p {
    font-size: 18px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    color: $textos-login;
  }
  button {
    margin-top: 15px;
  }
}
label {
  font-size: 14px;
}
input {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
      margin-bottom: 20px;
  padding: 5px;
  color: $textos-login;
  border: 1px solid #707070;
  border-radius: 5px;
  outline: none;
  background: none;
  height: 50px;
  &::placeholder {
    color: $neutro4;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: $textos-login;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
  }

}
  input[type="checkbox"]{
    height: 19px;
    width: 15px;
    margin: 0;
  }
.senha-voltar {
  text-align: center;
  display: block;
  cursor: pointer;
  color: $textos-login;
  margin-top: 30px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
}
.esqueceu-senha {
  position: relative;
  bottom: 15px;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: calc(100% - 135px);
}
.login-copy {
  width: 100%;
  text-align: center;
  p {
    font-size: 18px;
    color: $textos-login;
  }
}
.recaptcha {
  text-align: center;
  margin-bottom: 30px;
    margin-top: 30px;
}
.primeiro-acesso {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: $cinza-interno;
  a {
    color: $cinza-interno;
    font-weight: 700;
  }
}
.primeiro-link {
  font-weight: 700;
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}
.backOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #444444b0;
}
.img-upload {
  margin-top: 20px;
  text-align: center;
  height: 160px;
      display: flex;
    justify-content: center;
  span {
    cursor: pointer;
    display: block;
  }
  input[type="file"] {
    margin: 0;
    height: 150px;
    cursor: pointer;
    position: relative;
    opacity: 0;
  }
}
.container-login-inputs {
  margin-top: 30px;
}
.small{
  font-size: 12px;
}
.label-check{
      margin: 4px;
    position: absolute;
}
.mb-1{
  margin-bottom: 10px;
}
.error{
  margin-top: 10px;
}
.pass-hide{
    width: 30px;
    position: absolute;
    right: 10px;
    top: 30px;
    cursor: pointer;
    }
    .prev-img{
    position: absolute;
    height: 150px;
    }
</style>
