<template>
  <div class="campanhasAtivas">

    <h1 class="titulo-pagina">Campanhas ativas</h1>

    <div v-if="campanhas.length > 0">
      <carousel class="mobile" :nav="false" :autoplay="true" :autoWidth="true" :autoHeight="true" :center="true"
        :margin=20 :stagePadding=20 :items=1>
        <div v-for="(campanha, index) in campanhas" :key="index" class="campanhasAtivas__carrouselItem">
          <router-link :to="`/campanha/${campanha.id}`">
            <img class="mobile" :src="`${imgCampanhaEnv + campanha.imagemGradeMobile}`" />
          </router-link>
          <div class="infoItem">
            <p class="tituloItem">{{ campanha.nome }}</p>
            <p class="descricaoItem">{{ campanha.descricaoBreve }}</p>
            <button class="btnParticipar">
              <router-link :to="`/campanha/${campanha.id}`" class="text-mecanica">
                Mecânica
              </router-link>
            </button>
            <router-link :to="regulamento / campanha.anexoInformativo" v-if="campanha.anexoInformativo">
              <button class="btnAcessar">Ver regulamento</button>
            </router-link>
          </div>
        </div>
      </carousel>
    </div>
    <div v-else>
      <img class="mobile" src="~@/assets/images/tema/campanhas-inativas.png" />
    </div>
    <div v-if="campanhas.length > 0">
      <div class="desktop gridCampanhas">
        <div class="campanhasAtivas__gridItem" v-for="(campanha, index) in campanhas" :key="index">
          <router-link :to="`/campanha/${campanha.id}`">
            <img v-if="campanha.imagemGradeMobile" :src="`${imgCampanhaEnv + campanha.imagemGradeMobile}`" />
            <img v-else src="~@/assets/images/tema/image.jpg" />
          </router-link>

          <div class="infoItem">
            <p class="tituloItem">{{ campanha.titulo }}</p>
            <p>{{ campanha.descricaoBreve }}</p>
            <!-- <button :disabled="campanha.aceitou" @click="participarCampanha(campanha.id)" class="btnParticipar">
              {{
                  campanha.aceitou ? "Você já está participando" : "Participar"
              }}
            </button> -->
            <button class="btnParticipar">
              <router-link :to="`/campanha/${campanha.id}`" class="text-mecanica">
                Mecânica
              </router-link>
            </button>
            <router-link :to="regulamento / campanha.anexoInformativo" v-if="campanha.anexoInformativo">
              <button class="btnAcessar">Ver regulamento</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="img-vazio" v-else>
      <img class="desktop" src="~@/assets/images/tema/campanhas-inativas-desktop.png" />
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import { listarCampanhas, listarCampanhasConsultor } from "@/api/campanhas";
import { mapState } from "vuex";

export default {
  name: "CampanhasAtivas",
  components: {
    carousel,
  },

  data() {
    return {
      campanhas: [],
      imgCampanhaEnv: process.env.VUE_APP_CAMPANHAS_IMG,
      regulamento: process.env.VUE_APP_CAMPANHAS_IMG,
    };
  },

  computed: {
    ...mapState({
      cargoId: state => state.usuario.cargoUsuario,
      autorizadaAtiva: state => state.usuario.autorizadaAtiva,
    })
  },

  created() {
    this.buscarCampanhas();
  },

  watch: {
    autorizadaAtiva() {
      this.buscarCampanhas();
    }
  },

  methods: {
    buscarCampanhas() {
      if (this.cargoId == null) this.$router.push("/")
      if (this.cargoId != 10) {
        listarCampanhas().then((resp) => {
          this.campanhas = resp.data.lista;
        });
      } else {
        listarCampanhasConsultor(this.autorizadaAtiva).then((resp) => {
          this.campanhas = resp.data.lista;
        });
      }
    },

    participarCampanha(id) {
      let idObj = {
        idCampanha: id,
      };

      this.$router.push('/campanha/' + idObj.idCampanha)
      // participarDaCampanha(idObj).then((resp) => {
      //   this.$store.commit("SET_ALERTA", {
      //     show: true,
      //     tipo: "sucesso",
      //     mensagem: resp.data.mensagem,
      //   });

      //   this.buscarCampanhas();
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-mecanica {
  color: #FFF;
}

.campanhasAtivas {
  @media (min-width: 768px) {
    width: 1200px;
    padding-left: 0px;
  }

  &__carrouselItem {
    width: 100%;
    position: relative;

    @media (min-width: 768px) {
      display: flex;
    }

    .infoItem {
      width: 280px;
      font-size: 14px;
      background-color: #EFF2F6;
      padding: 15px;

      @media (min-width: 768px) {
        // display: none;
      }
    }

    .tituloItem {
      font-weight: 600;
      margin-bottom: 15px;
    }

    .descricaoItem {
      height: 60px;
    }

    //img {
    //  width: 280px;
    // height: 280px;
    //}
  }

  &__textos {
    margin: 51px 0 16px 0;
    width: 100%;

    @media (min-width: 768px) {
      width: 100%;
    }

    .titulo {
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 26px;

      @media (min-width: 768px) {
        text-align: left;
      }
    }
  }

  &__gridItem {
    width: 25%;
    // padding: 0 10px;
    margin-bottom: 30px;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 300px;
    }

    .infoItem {
      font-size: 14px;
      background-color: #EFF2F6;
      padding: 15px;
      margin-top: -5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      p {
        height: 50px;
      }
    }

    .tituloItem {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 15px;
      height: 50px;
    }

    // &:nth-child(4) {
    //   margin-right: 0;
    // }
  }

  .btnParticipar,
  .btnAcessar {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background: $bg-btn-padrao;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: $branco;
    margin: auto;
    margin-top: 30px;
    text-transform: uppercase;
    transition: 0.3s;
    background-size: 200% auto;

    &:hover {
      background-position: right center;
      background-image: linear-gradient(to right,
          rgb(62, 138, 97) 10%,
          #3ab15e 51%,
          rgb(62, 138, 97) 100%);
    }
  }

  .btnParticipar {
    &:disabled {
      opacity: 0.5;
      background: $bg-laranja;
      cursor: default;
    }
  }

  a {
    text-decoration: none;
  }

  .btnAcessar {
    background: transparent;
    color: $grafite;
    margin-top: 20px;

    &:hover {
      background: transparent;
    }
  }

  .gridCampanhas {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
  }

  .mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  [disabled] {
    background: $bg-btn-secundario;
  }

  .img-vazio {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;

    img {
      width: 50%;
    }
  }
}
</style>