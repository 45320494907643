<template>
    <section class="tabela-chamados">
        <h2 class="tabela-chamados__title">histórico de chamados</h2>
        <div role="group" class="tabela-chamados__btn-group">
            <button @click="activeBtn = 'inProgress-btn'" type="button" class="btn left"
                :class="{ active: activeBtn === 'inProgress-btn' }">
                chamados em andamento
            </button>
            <button @click="activeBtn = 'completed-btn'" type="button" class="btn right"
                :class="{ activeAndOrange: activeBtn === 'completed-btn' }">
                chamados finalizados
            </button>
        </div>
        <div v-if="inProgress().length" class="tabela-chamados__table">
            <table>
                <thead>
                    <tr>
                        <th>data</th>
                        <th>assunto</th>
                        <th>chamado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="call in inProgress()" :key="call.id">
                        <router-link :to="`/chamado/${call.id}`">
                            <td>{{ call.data }}</td>
                            <td>{{ call.assunto }}</td>
                            <td>{{ call.chamado }}</td>
                        </router-link>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-else class="pb-4 text-center">Nenhum chamado encontrado.</p>
    </section>
</template>

<script>
import { listarChamados } from '@/api/chamados';

export default {
    name: "ChamadosTabela",

    components: {
    },

    data() {
        return {
            paginacao: {
                pagina: 1,
                qtdPorPagina: 100
            },
            arrProgress: [],
            arrCompleted: [],
            activeBtn: "inProgress-btn",
        };
    },

    created() {
        listarChamados(this.paginacao).then((resp) => {
            // 4 - Finalizado 
            this.arrCompleted = resp.data.lista.filter(chamadosOff => chamadosOff.statusId == 4)
            this.arrProgress = resp.data.lista.filter(chamadosOn => chamadosOn.statusId != 4)
        });
    },

    methods: {
        // Mostrará o vetor de chamados de acordo com o botão ativado
        inProgress() {
            return this.activeBtn === "inProgress-btn"
                ? this.arrProgress
                : this.arrCompleted;
        },
    },
};
</script>

<style lang="scss" scoped>
.text-center {
    text-align: center;
}

.tabela-chamados {
    background-color: $cinzaClaro;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 24px;

    @media (min-width: 768px) {
        padding: 24px 200px;
        margin: 0 100px;
    }

    &__title {
        color: $grafite;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;

        @media (min-width: 768px) {
            font-size: 32px;
        }
    }

    &__btn-group {
        display: flex;

        .right,
        .left {
            text-align: center;
            width: 100%;
            background: $neutro6;
            color: $grafite;
        }

        .right {
            border-radius: 0 5px 5px 0 !important;
        }

        .left {
            border-radius: 5px 0 0 5px !important;
        }

        .active {
            background: $corVerde;
            color: $branco;
        }

        .activeAndOrange {
            background: $laranja;
            color: $branco;
        }
    }

    &__table {
        max-height: 328px;
        overflow: auto;
        overflow-x: hidden;

        @media (min-width: 768px) {
            max-height: none;
            height: 387px;
        }

        table {
            border-collapse: collapse;
            border-radius: 5px;
            overflow: hidden;

            th {
                padding: 10px;
            }

            td {
                padding: 20px 10px;
            }

            thead {
                tr {
                    border-bottom: 5px solid $cinzaClaro;

                    th {
                        background: $grafite;
                        color: $branco;
                        text-transform: uppercase;
                    }
                }
            }

            tbody {
                tr {
                    background: $neutro5;
                    border-bottom: 5px solid $cinzaClaro;

                    a {
                        display: contents;
                        color: $grafite;
                    }

                    &:hover {
                        cursor: pointer;
                        background: $neutro4;
                    }

                    td {
                        font-weight: bold;

                        &:nth-child(2) {
                            width: 65%;
                        }

                    }

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }
}
</style>