<template>
  <div>
    <ModalPoliticaPrivacidade
      @aceitoPolitica="aceitoPolitica"
      v-if="$store.state.interacoes.modalPoliticaPrivacidade.modalAtivado"
    />
    <ModalDeRegulamento
      @aceitoRegulamento="aceitoRegulamento"
      v-if="$store.state.interacoes.modalDeRegulamento.modalAtivado"
    />
    <ModalCadastroParabens
      v-if="$store.state.interacoes.modalCadastroParabens.modalAtivado"
    />
    <ModalDesistirCadastro
      v-if="$store.state.interacoes.modalDesistirCadastro.modalAtivado"
    />

    <Modal5Pontos
      v-if="$store.state.interacoes.modalGanhou5Pontos.modalAtivado"
    />
    <div class="login-main">
      <!-- Login -->
      <div :class="{ backOverlay: zIndex }"></div>

      <div class="modal-login">
        <div @click="irParaHome" class="container-login-img header">
          <LogoBranca />
        </div>
        <div class="texto-topo">
          <h1 class="desk">Cadastre seu dados pessoais</h1>

          <h1 class="mobi">Cadastro</h1>
        </div>

        <transition mode="out-in" name="fade-left" appear>
          <!-- Login -->
          <div class="container-login">
            <div class="img-upload">
              <img class="prev-img" v-if="imagePrev" :src="imagePrev" />
              <input
                type="file"
                accept="image/png, image/jpeg"
                @change="prevImgChange"
              />
              <p class="small">Formatos aceitos (.jpg .png) - 2mb</p>
            </div>

            <div class="container-login-cadastro" key="cadastro">
              <div class="container-login-inputs">
                <inputValidate :erro="nomeErro" :nomeInvalido="nomeInvalido">
                  <label>Nome Completo</label>
                  <input
                    type="text"
                    v-model.trim="cadastro.nome"
                    placeholder="Ex: Rafael Evangelista Soares"
                    @blur="$v.cadastro.nome.$touch(), validarNomeCorreto()"
                    @keyup.enter="enterParaCadastro"
                  />
                </inputValidate>
                <inputValidate :erro="dataErro" :idadeInvalida="idadeInvalida">
                  <div class="input">
                    <label>Data de Nascimento</label>
                    <input
                      placeholder="Ex: 01/01/1999"
                      class="input-medio"
                      type="date"
                      v-model.trim="cadastro.dataNascimento"
                      @blur="
                        $v.cadastro.dataNascimento.$touch(),
                          validarIdadeCorreta()
                      "
                      @keyup.enter="enterParaCadastro"
                    />
                  </div>
                </inputValidate>

                <inputValidate :erro="cpfErro" :cpfInvalido="cpfInvalido">
                  <div class="input">
                    <label>CPF</label>
                    <input
                      placeholder="Ex: 000.000.000-00"
                      class="input-medio"
                      type="text"
                      v-model.trim="cadastro.cpf"
                      v-mask="'999.999.999-99'"
                      @blur="$v.cadastro.cpf.$touch(), validarCpfCorreto()"
                      @keyup.enter="enterParaCadastro"
                    />
                  </div>
                </inputValidate>

                <!-- <div class="mb-3">
                  <label class="mb-3">Cargo</label>
                  <multiselect
                    v-model="cadastro.cargo"
                    label="descricao"
                    track-by="id"
                    :options="cargo"
                    :multiple="false"
                    :options-limit="200"
                    placeholder=""
                    select-label=""
                    :allow-empty="false"
                    deselect-label=" "
                    selected-label="Selecionado"
                    class="input mt-2"
                  />
                </div> -->

                <inputValidate
                  :erro="emailLoginErro"
                  :emailInavalido="emailInavalido"
                >
                  <label>E-mail</label>
                  <input
                    type="text"
                    placeholder="Ex: meuemail@gmail.com"
                    class="email"
                    v-model.trim="cadastro.email"
                    @keyup="validarLowerCaseEmail()"
                    @blur="$v.cadastro.email.$touch(), validarEmailCorreto()"
                    @keyup.enter="enterParaCadastro"
                    @paste.prevent
                    @copy.prevent
                  />
                </inputValidate>
                <inputValidate :erro="confirmaEmailErro">
                  <label>Confirmar E-mail</label>
                  <input
                    type="text"
                    class="confirmaEmail"
                    placeholder="Ex: meuemail@gmail.com"
                    v-model.trim="cadastro.emailConfirma"
                    @keyup="validarLowerCaseConfirmarEmail()"
                    @blur="$v.cadastro.emailConfirma.$touch()"
                    @keyup.enter="enterParaCadastro"
                    @paste.prevent
                    @copy.prevent
                  />
                </inputValidate>
                <inputValidate
                  :erro="celularErro"
                  :celularInvalido="celularInvalido"
                >
                  <div class="input">
                    <label>Celular</label>
                    <input
                      class="input-medio"
                      type="text"
                      placeholder="(99) 99999-9999"
                      v-mask="'(99)99999-9999'"
                      v-model.trim="cadastro.celular"
                      @blur="
                        $v.cadastro.celular.$touch(), validarCelularCorreto()
                      "
                      @keyup.enter="enterParaCadastro"
                    />
                  </div>
                </inputValidate>
                <inputValidate :erro="senhaLoginErro">
                  <label>Senha</label>
                  <input
                    :type="passwordField"
                    v-model.trim="cadastro.senha"
                    @blur="$v.cadastro.senha.$touch()"
                    @keypress="confereSenha"
                    @keyup.enter="enterParaCadastro"
                  />
                  <img
                    class="pass-hide"
                    @click="showPass"
                    :src="imgShow"
                    alt="WHP Técnicos"
                  />
                  <div v-if="errors" class="error">
                    <span
                      class="input-erro-mensagem"
                      v-if="!$v.cadastro.senha.strongPassword"
                      >Senha inválida</span
                    >
                  </div>
                </inputValidate>

                <inputValidate :erro="confirmaSenhaErro">
                  <label>Confirmar senha</label>
                  <input
                    :type="passwordFieldConfirm"
                    v-model.trim="cadastro.senhaConfirma"
                    @blur="$v.cadastro.senhaConfirma.$touch()"
                    @keyup.enter="enterParaCadastro"
                  />
                  <img
                    class="pass-hide"
                    @click="showPassConfirm"
                    :src="imgShowConfirm"
                    alt="WHP Técnicos"
                  />
                </inputValidate>

                <p class="small mb-48">
                  A senha precisa conter no mínimo 8 dígitos, uma letra maiúscula, uma letra minúscula, um número e um caractere especial (ex.: @, !, ?, %).
                </p>

                <inputValidate :erro="regulamentoErro">
                  <input
                    class="chack-regulamento"
                    type="checkbox"
                    v-model="cadastro.regulamento"
                    id="regulamento"
                    @change="
                      $v.cadastro.regulamento.$touch(), changeCheckRegulamento()
                    "
                  />
                  <label for="regulamento" class="small label-check"
                    >Li e aceito o Regulamento do programa.</label
                  >
                  <a
                    class="link-regulamento"
                    :style="positionLinkRegulamento"
                    @click="chamaModalRegulamento()"
                  ></a>
                </inputValidate>

                <inputValidate :erro="politicaErro">
                  <input
                    id="politica"
                    v-model.trim="cadastro.politica"
                    type="checkbox"
                    @change="
                      $v.cadastro.politica.$touch(), changeCheckPolitica()
                    "
                  />
                  <label for="politica" class="small label-check">
                    Li e aceito a Política de Privacidade do programa.</label
                  >
                  <a
                    class="link-politica"
                    :style="positionLinkPolitica"
                    @click="chamaModalPolitica()"
                  ></a>
                </inputValidate>

                <div class="checkReceberEmails">
                  <input
                    id="aceite"
                    type="checkbox"
                    v-model.trim="cadastro.receberEmails"
                  />
                  <label for="aceite" class="small label-check">
                    Aceito receber e-mails, sms, whatsapp com novidades e
                    informações sobre o programa.</label
                  >
                </div>

                <div class="captcha-fake" v-if="!mostraRecaptcha"></div>
                <div class="recaptcha" v-if="mostraRecaptcha">
                  <VueRecaptcha
                    sitekey="6Lc8qbYbAAAAAKJGmmeLYas2RJ0aYJGcyJqCG4EQ"
                    @verify="verificaCaptcha"
                    @expired="expiraCaptcha"
                    :loadRecaptchaScript="true"
                  />
                </div>

                <div class="container-login-botoes">
                  <button
                    class="btn btn-enviar-login btn-salvar"
                    :disabled="$v.cadastro.$invalid"
                    @click="fazerCadastro"
                  >
                    Salvar
                  </button>
                  <button class="btn senha-voltar" href="#" @click="linkVoltar">
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Esqueceu Senha -->
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import {
  emailLoginErro,
  senhaLoginErro,
  nomeErro,
  dataErro,
  celularErro,
  cpfErro,
  confirmaEmailErro,
  confirmaSenhaErro,
  politicaErro,
  regulamentoErro,
} from "@/plugins/vuelidate/cadastro";
//import * as api from "@/api/login/login";
// import esqueciSenha from "@/api/usuario/index.js";
import inputValidate from "@/components/formulario/InputValidate.vue";
import LogoBranca from "@/components/logos/LogoBranca.vue";
import img from "@/assets/images/tema/logo-empresa.shot.svg";
import AwesomeMask from "awesome-mask";
import ModalPoliticaPrivacidade from "@/components/modal/ModalPoliticaPrivacidade.vue";
import ModalDeRegulamento from "@/components/modal/ModalDeRegulamento.vue";
import ModalCadastroParabens from "@/components/modal/ModalCadastroParabens.vue";
import Modal5Pontos from "@/components/modal/ModalGanhou5Pontos.vue";
import ModalDesistirCadastro from "@/components/modal/ModalDesistirCadastro.vue";
import {
  cadastrarUsuario,
  validarNome,
  validarIdade,
  validarCpf,
  validarEmail,
  validarCelular,
  verificarCpfBlackList,
} from "@/api/usuario";
import moment from "moment";
// import Multiselect from "vue-multiselect";
// import { listarCargoAutorizadas } from "@/api/cadastro";
import { mapState } from "vuex";

//const alpha = helpers.regex('^(?=.*[a-z])(?=.*[A-Z])(?=.*\)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$');

export default {
  name: "cadastro",
  components: {
    VueRecaptcha,
    inputValidate,
    LogoBranca,
    ModalPoliticaPrivacidade,
    ModalDeRegulamento,
    ModalCadastroParabens,
    ModalDesistirCadastro,
    Modal5Pontos,
    // Multiselect,
  },
  directives: {
    mask: AwesomeMask,
  },

  data() {
    return {
      mostraRecaptcha: true,
      captcha: false,
      positionLinkRegulamento: {
        position: "absolute",
      },
      positionLinkPolitica: {
        position: "absolute",
      },
      imagePrev: "/img/icons/img-upload.png",
      errors: false,
      esqueceuSenha: false,
      passwordField: "password",
      passwordFieldConfirm: "password",
      imgShow: "/img/icons/hide.png",
      imgShowConfirm: "/img/icons/hide.png",
      imagem: img,
      nomeInvalido: null,
      idadeInvalida: null,
      cpfInvalido: null,
      emailInavalido: null,
      celularInvalido: null,
      foto: null,
      cadastro: {
        nome: "",
        dataNascimento: "",
        email: "",
        emailConfirma: "",
        celular: "",
        cpf: "",
        senha: "",
        senhaConfirma: "",
        regulamento: false,
        politica: false,
        receberEmails: false,
        tokenReCaptcha: "",
        IdCargo: null,
        // cargo: "",
      },
      emailEsqueci: "",
      zIndex: false,
      cargo: [],
    };
  },
  validations: {
    cadastro: {
      tokenReCaptcha: {
        required,
      },
      email: {
        required,
        email,
      },
      senha: {
        required,
        strongPassword(senha) {
          return (
            /[A-Z]/.test(senha) && // checks upperCase char
            /[a-z]/.test(senha) && // checks for a-z
            /[0-9]/.test(senha) && // checks for 0-9
            /\W|_/.test(senha) && // checks for special char
            senha.length >= 8
          );
        },
      },
      nome: {
        required,
      },
      dataNascimento: {
        required,
      },
      cpf: {
        required,
      },
      celular: {
        required,
      },
      emailConfirma: {
        required,
        email,
      },
      senhaConfirma: {
        required,
        sameAs: sameAs("senha"),
      },
      regulamento: {
        regucheck(regulamento) {
          return regulamento;
        },
      },
      politica: {
        regupoli(politica) {
          return politica;
        },
      },
    },
  },
  computed: {
    ...mapState({
      assistenciaId: (state) => state.assistencia.assistenciaId,
      assistenciaFranquia: (state) => state.assistencia.assistenciaFranquia,
    }),
    emailLoginErro,
    senhaLoginErro,
    dataErro,
    nomeErro,
    cpfErro,
    celularErro,
    confirmaEmailErro,
    confirmaSenhaErro,
    regulamentoErro,
    politicaErro,
  },
  mounted() {
    this.cadastro.celular = "";
    if (!this.$store.state.assistencia.assistenciaId) {
      this.$router.push("/");
    }
    // this.carregaComboCargo();
    // this.bloqueiaColarConfirmarEmail();
  },
  methods: {
    // carregaComboCargo() {
    //   listarCargoAutorizadas(
    //     this.$store.state.assistencia.assistenciaFranquia
    //   ).then((resp) => {
    //     this.cargo = resp.data;
    //   });
    // },
    validarLowerCaseEmail() {
      this.cadastro.email = this.cadastro.email.toLowerCase();
    },

    validarLowerCaseConfirmarEmail() {
      this.cadastro.emailConfirma = this.cadastro.emailConfirma.toLowerCase();
    },

    irParaHome() {
      this.$router.push("/");
    },
    validarNomeCorreto() {
      const data = new FormData();
      data.append("nome", this.cadastro.nome);
      validarNome(data).then((resp) => {
        this.nomeInvalido = resp.data.mensagem;
      });
    },
    validarIdadeCorreta() {
      const data = new FormData();
      data.append(
        "idade",
        moment(this.cadastro.dataNascimento).format("YYYY-MM-DD")
      );
      validarIdade(data).then((resp) => {
        this.idadeInvalida = resp.data.mensagem;
      });
    },
    validarCpfCorreto() {
      const data = new FormData();
      data.append(
        "cpf",
        this.cadastro.cpf.replaceAll(".", "").replace("-", "")
      );
      validarCpf(data).then((resp) => {
        this.cpfInvalido = resp.data.mensagem;
        const cpf = this.cadastro.cpf.replaceAll(".", "").replace("-", "");
        verificarCpfBlackList(cpf).then((resp) => {
          if (resp.data.bloqueado) {
            this.$store.commit("SET_ALERTA", {
              show: true,
              tipo: "erro",
              mensagem: resp.data.mensagem,
            });
            this.$router.push("/login");
          }
        });
      });
    },
    validarEmailCorreto() {
      const data = new FormData();
      data.append("email", this.cadastro.email);
      validarEmail(data).then((resp) => {
        this.emailInavalido = resp.data.mensagem;
      });
    },
    validarCelularCorreto() {
      let nCelular = this.cadastro.celular.replace(/\D/g, "");

      if (nCelular.length == 11) {
        this.celularInvalido = "";

        const data = new FormData();
        data.append("numero", nCelular);

        validarCelular(data).then((resp) => {
          this.celularInvalido = resp.data.mensagem;
        });
      } else {
        this.celularInvalido = "Número inválido";
      }
    },
    linkVoltar() {
      this.$store.commit("interacoes/SET_MODAL_DESISTIR_CADASTRO", {
        ativado: true,
        mensagem: "SAIR",
        tipo: "",
      });
    },
    prevImgChange(e) {
      const file = e.target.files[0];
      this.imagePrev = URL.createObjectURL(file);
      this.foto = file;
    },
    showPass() {
      this.passwordField =
        this.passwordField === "password" ? "text" : "password";
      this.imgShow =
        this.imgShow === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    showPassConfirm() {
      this.passwordFieldConfirm =
        this.passwordFieldConfirm === "password" ? "text" : "password";
      this.imgShowConfirm =
        this.imgShowConfirm === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    confereSenha() {
      this.errors = true;
    },
    mudarBack() {
      // console.log("muda");
      this.zIndex = !this.zIndex;
    },
    aceitoRegulamento() {
      this.cadastro.regulamento = true;
    },
    aceitoPolitica() {
      this.cadastro.politica = true;
    },
    changeCheckRegulamento() {
      this.positionLinkRegulamento = {
        position: "absolute",
      };
    },

    changeCheckPolitica() {
      this.positionLinkPolitica = {
        position: "absolute",
      };
    },

    toggleView() {
      this.esqueceuSenha = !this.esqueceuSenha;
    },
    enterParaCadastro() {
      this.$v.cadastro.$invalid ? false : this.fazerCadastro();
    },

    verificaCaptcha(e) {
      this.cadastro.tokenReCaptcha = e;
      this.captcha = true;
    },

    recarregarCapitcha() {
      this.mostraRecaptcha = false;
      this.captcha = false;
      setTimeout(this.recarregarCapitchaTrue, 1000);
    },
    recarregarCapitchaTrue() {
      this.mostraRecaptcha = true;
    },
    expiraCaptcha() {
      this.captcha = false;
    },

    formaData() {
      const data = new FormData();

      data.append("nome", this.cadastro.nome);
      data.append("cpf", this.cadastro.cpf.replace(/\D/g, ""));
      data.append("email", this.cadastro.email);
      data.append("senha", this.cadastro.senha);
      data.append("ConfirmarSenha", this.cadastro.senhaConfirma);
      data.append("celular", this.cadastro.celular.replace(/\D/g, ""));
      data.append("foto", this.foto);
      // data.append("idUsuarioCargo", 0);
      // data.append("idUsuarioStatus", 0);
      // data.append("idAssistencia", this.$store.state.assistencia.assistenciaId);
      data.append("IdCargo", sessionStorage.getItem('idCargo'));
      data.append("IdsAutorizadas", this.$store.state.assistencia.assistenciaId);
      data.append("dataNascimento", this.cadastro.dataNascimento);
      data.append("aceiteRegulamento", this.cadastro.regulamento);
      data.append("aceitePoliticaPrivacidade", this.cadastro.politica);
      data.append("aceiteReceberComunicacao", this.cadastro.receberEmails);
      data.append("tokenReCaptcha", this.cadastro.tokenReCaptcha);

      return data;
    },

    fazerCadastro() {
      if (
        this.nomeInvalido ||
        this.idadeInvalida ||
        this.cpfInvalido ||
        this.emailInavalido ||
        this.confirmaEmailErro ||
        this.celularInvalido ||
        this.senhaLoginErro ||
        this.confirmaSenhaErro
      ) {
        this.$store.commit("SET_ALERTA", {
          show: true,
          tipo: "erro",
          mensagem: "Verifique o(s) campo(s) com erro",
        });
        return;
      }

      cadastrarUsuario(this.formaData()).then((resp) => {
        let dataAtual = new Date();
        let dataLimite = new Date("08-01-2022 23:59:00");

        if (resp.data.sucesso) {
          if (resp !== undefined) {
            if (
              this.assistenciaFranquia == 2 &&
              dataAtual.getTime() < dataLimite.getTime()
            ) {
              this.$store.commit("interacoes/SET_MODAL_GANHOU_5_PONTOS", {
                ativado: true,
                mensagem: "Parabéns",
                tipo: "",
              });
            } else {
              this.$store.commit("interacoes/SET_MODAL_CADASTRO_PARABENS", {
                ativado: true,
                mensagem: "Parabéns",
                tipo: "",
              });
            }
          } else {
            this.$store.commit("SET_ALERTA", {
              show: true,
              tipo: "erro",
              mensagem: "Ocorreu um erro",
            });
          }
        } else {
          this.$store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: resp.data.mensagem,
          });
        }
      });
    },
    chamaModalRegulamento() {
      if (this.cadastro.regulamento) {
        return (this.cadastro.regulamento = false);
      }

      this.$store.commit("interacoes/SET_MODAL_DE_REGULAMENTO", {
        ativado: true,
        mensagem: "Regulamento",
        tipo: "",
      });
    },
    chamaModalPolitica() {
      if (this.cadastro.politica) {
        return (this.cadastro.politica = false);
      }

      this.$store.commit("interacoes/SET_MODAL_POLITICA_PRIVACIDADE", {
        ativado: true,
        mensagem: "Politica",
        tipo: "",
      });
    },
    chamaModalParabens() {
      this.$store.commit("interacoes/SET_MODAL_PARABENS", {
        ativado: true,
        mensagem: "Regulamento",
        tipo: "",
      });
    },
    enterParaSenha() {
      this.$v.emailEsqueci.$invalid ? false : this.esqueciSenha();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 80px !important;
  display: flex;
}
.login-main {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 20px;
  background: #fff;
  .mobi,
  .desk {
    font-size: 32px;
    font-weight: 500;
    color: $grafite;
  }
  .mobi {
    display: none;
  }
  .desk {
    display: block;
  }
  @media (max-width: 700px) {
    .mobi {
      display: block;
    }
    .desk {
      display: none;
    }
  }
}
.modal-login {
  width: 100%;
  max-width: 800px;
  @media (max-width: 700px) {
    max-width: 450px;
  }
}
.container-login-img {
  cursor: pointer;
  padding: 6px 7%;
  height: 60px;
  background: $bg-header-cadastro;
  filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.09));
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  img {
    width: 100%;
    max-width: 150px;
    height: auto;
    max-height: 230px;
    pointer-events: none;
    @media (max-width: 700px) {
      max-height: 150px;
    }
  }
  @media (max-width: 700px) {
    text-align: center;
  }
}
.container-login,
.container-senha {
  text-align: left;
  color: $cinza-interno;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  align-items: baseline;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}
.container-login-cadastro {
  text-align: left;
  color: $cinza-interno;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  min-height: 250px;
  @media (max-width: 700px) {
    width: 100%;
  }
}
.btn-enviar-login {
  color: $texto-botao-login;
  background: $bg-botao-login;
  &:hover {
    background: darken($bg-botao-login, 5%);
  }
}
.container-senha {
  p {
    font-size: 18px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    color: $textos-login;
  }
  button {
    margin-top: 15px;
  }
}
label {
  font-size: 14px;
}
input {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  color: $textos-login;
  border: 1px solid #acacac;
  border-radius: 5px;
  outline: none;
  background: none;
  height: 49px;
  &::placeholder {
    color: $neutro4;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: $textos-login;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
  }
}
input[type="checkbox"] {
  height: 19px;
  width: 15px;
  margin: 0;
  margin-bottom: 5px;
}
input:checked {
  position: relative;
  &::after {
    content: url(/img/icons/check.png);
    position: absolute;
    top: 2px;
    left: -1px;
    width: 15px;
    height: 19px;

    img {
      width: 15px;
      height: 19px;
    }
  }
}
.senha-voltar {
  background: none;
  text-align: center;
  display: block;
  cursor: pointer;
  color: $textos-login;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  @media (max-width: 700px) {
    margin-top: 20px;
  }
}
.esqueceu-senha {
  position: relative;
  bottom: 15px;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: calc(100% - 135px);
}
.login-copy {
  width: 100%;
  text-align: center;
  p {
    font-size: 18px;
    color: $textos-login;
  }
}
.recaptcha {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.primeiro-acesso {
  margin-top: 30px;
  margin-bottom: 30px;
  color: $cinza-interno;
  a {
    cursor: pointer;
    color: $cinza-interno;
    font-weight: 700;
  }
}
.primeiro-link {
  font-weight: 700;
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}
.backOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2000px;
  background-color: #444444b0;
}
.img-upload {
  // margin-right: 45px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    //  width: 160px;
    height: 160px;
    border-radius: 50%;
  }
  span {
    cursor: pointer;
    display: block;
  }
  input[type="file"] {
    margin: 0;
    height: 150px;
    cursor: pointer;
    position: relative;
    opacity: 0;
  }
  @media (max-width: 700px) {
    margin-right: 0;
  }
  p {
    margin-top: 10px;
  }
}
.container-login-inputs {
  margin-top: 30px;

  .input {
    display: flex;
    flex-direction: column;
  }

  .input-medio {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  label {
    font-weight: 500;
    font-size: 14px;
    color: $grafite;
  }
}
input:checked {
  background: red;
}

.small {
  font-size: 13px;
  color: $grafite;
}
.mb-48 {
  margin-bottom: 35px;
}
.label-check {
  margin: 4px;
  position: absolute;
}
.mb-1 {
  margin-bottom: 10px;
}
.error {
  margin-top: 10px;
}
.pass-hide {
  width: 30px;
  position: absolute;
  right: 10px;
  top: 24px;
  cursor: pointer;
}
.prev-img {
  position: absolute;
  height: 150px;
}
.texto-topo {
  margin-top: 5rem;
  color: $cinza-interno;
  text-align: center;
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.link-regulamento {
  left: 0;
  width: 265px;
  height: 20px;
}
.link-politica {
  left: 0;
  width: 315px;
  height: 20px;
}
.container-login-botoes {
  display: flex;
  flex-direction: row;

  .btn-salvar {
    background: $bg-btn-padrao;
    color: $branco;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: hover 0.3s;

    &:hover {
      background: $bg-btn-padrao;
      opacity: 0.9;
    }

    &:disabled {
      cursor: pointer;
      background: $bg-btn-padrao;
      opacity: 0.6;
      color: $branco;
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
.checkReceberEmails {
  @media (max-width: 768px) {
    margin-top: 10px;
  }
}
</style>
