/** SVG **/
import iconeDownload from "@/assets/images/icones/icon-download.svg";
import iconeFechar from "@/assets/images/icones/close.svg";
import iconeBusca from "@/assets/images/icones/icone-buscar.svg";
import iconeAnexo from "@/assets/images/icones/icon-anexo.svg";
import iconeArrowLeft from "@/assets/images/icones/arrow-left.svg";
import Download from "@/assets/images/icones/download.svg";
import iconeEditar from "@/assets/images/icones/editar.svg";
import iconeExcluir from "@/assets/images/icones/excluir.svg";
import iconeClaro from "@/assets/images/icones/layout-claro.svg";
import iconeEscuro from "@/assets/images/icones/layout-escuro.svg";
import iconeNext from "@/assets/images/icones/next.svg";
import iconeSee from "@/assets/images/icones/see.svg";
import iconeSend from "@/assets/images/icones/send.svg";

/** PNG **/
import iconeStatusUsuario from "@/assets/images/icones/status-alteracao.png";
import iconeStatusAlteracao from "@/assets/images/icones/status-usuario.png";
import iconeExcluirAnexo from "@/assets/images/icones/status-excluir-anexo.png";
import iconeAnexoLaranja from "@/assets/images/icones/img-anexo-laranja.png";
import iconeConcluido from "@/assets/images/icones/concluido.png";
import iconeClose from "@/assets/images/icones/close.png"
import Excluir from "@/assets/images/icones/excluir.png"
import iconeExtratoGeralInativo from "@/assets/images/icones/extrato-geral-inativo.png"
import iconeHide from "@/assets/images/icones/hide.png"
import iconeIPendente from "@/assets/images/icones/i-pendente.png"
import iconeIRealizado from "@/assets/images/icones/i-realizado.png"
import iconeExtratoGeral from "@/assets/images/icones/icon-extrato-geral.png"
import iconePrograma from "@/assets/images/icones/icone-programa.png"
import imgAnexo from "@/assets/images/icones/img-anexo.png"
import imgUpload from "@/assets/images/icones/img-upload.png"
import iconeMail from "@/assets/images/icones/mail.png"
import iconeMailSend from "@/assets/images/icones/mailSend.png"
import iconeMeusPremiosInativo from "@/assets/images/icones/meus-premios-inativo.png"
import iconeMeusPremios from "@/assets/images/icones/meus-premios.png"
import iconeNota from "@/assets/images/icones/nota.png"
import iconePendente from "@/assets/images/icones/pendente.png"
import iconePontosExpirados from "@/assets/images/icones/pontos-expirados.png"
import iconePontosGanhos from "@/assets/images/icones/pontos-ganhos.png"
import iconePontosResgatados from "@/assets/images/icones/pontos-resgatados.png"
import iconeRanking from "@/assets/images/icones/ranking.png"
import iconeRealizado from "@/assets/images/icones/realizado.png"
import iconeSetaDireita from "@/assets/images/icones/seta-direita.png"
import iconeSetaEsquerda from "@/assets/images/icones/seta-esquerda.png"
import iconeShow from "@/assets/images/icones/show.png"
import iconeTreinamentos from "@/assets/images/icones/treinamentos.png"
import iconeFiltrar from "@/assets/images/icones/icone-filtrar-resultados.png"

/** Imagens extrato **/
import ExtratoGeralTabela from "@/assets/images/icones/extrato-geral-tabela.png";
import PontosGanhosInativo from "@/assets/images/icones/pontos-ganhos-inativo.png";
import PontosResgatadosInativo from "@/assets/images/icones/pontos-resgatados-inativo.png";
import PontosExpiradosInativo from "@/assets/images/icones/pontos-expirados-inativo.png";

export default ({
    //SVG
    iconeArrowLeft,
    Download,
    iconeEditar,
    iconeExcluir,
    iconeClaro,
    iconeEscuro,
    iconeNext,
    iconeSee,
    iconeSend,
    iconeBusca,
    iconeDownload,
    iconeFechar,

    //PNG
    iconeStatusUsuario,
    iconeStatusAlteracao,
    iconeAnexo,
    iconeExcluirAnexo,
    iconeAnexoLaranja,
    iconeConcluido,
    ExtratoGeralTabela,
    PontosGanhosInativo,
    PontosResgatadosInativo,
    PontosExpiradosInativo,
    iconeClose,
    Excluir,
    iconeExtratoGeralInativo,
    iconeHide,
    iconeIPendente,
    iconeIRealizado,
    iconeExtratoGeral,
    iconePrograma,
    imgAnexo,
    imgUpload,
    iconeMail,
    iconeMailSend,
    iconeMeusPremiosInativo,
    iconeMeusPremios,
    iconeNota,
    iconePendente,
    iconePontosExpirados,
    iconePontosGanhos,
    iconePontosResgatados,
    iconeRanking,
    iconeRealizado,
    iconeSetaDireita,
    iconeSetaEsquerda,
    iconeShow,
    iconeTreinamentos,
    iconeFiltrar
});