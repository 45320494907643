import client from "@/api";
import store from "@/store";
import {
  _rotaObterRanking,
   _rotaObterKpis,
   _rotaObterTreinamentos,
   _rotaObterGrafico,
   _rotaUltimaAtualizacaoPerformance,
   _rotaObterRankingNacional,
   _rotaObterPerformance,
   _rotaObterGraficoDesempenho,
   _rotaObterUsuariosPorStatus,
   _rotaObterGraficoTreinamento,
   _rotaObterTreinamentoEquipe,
   _rotaObterKpisPorAutorizada,
   _rotaDownloadPlanilhaPerformance,
   _rotaObterMesesComPerformance,
   _rotaObterUltimaAtualizacaoPerformance,
   _rotaObterComboAtualizacaoPerformance
  } from "@/api/_caminhosApi";

export async function obterUltimaAtualizacaoPerformance() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(_rotaObterUltimaAtualizacaoPerformance());
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterComboAtualizacaoPerformance() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(_rotaObterComboAtualizacaoPerformance());
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarRankings(idAutorizada) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterRanking()}/${idAutorizada}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterMesesComPerformance(ano, autorizadaId) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterMesesComPerformance()}/${autorizadaId}/${ano}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function downloadPlanilhaPerformance(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaDownloadPlanilhaPerformance(), data, {responseType: 'blob'});
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarRankingNacional(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(`${_rotaObterRankingNacional()}?quantidade=5&idCargo=${data.idCargo}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarKpis() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(_rotaObterKpis());
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterPerformance(autorizada) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterPerformance()}/${autorizada}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterTreinamentos(data) {
  store.commit("interacoes/SET_LOADING", true);
  let buscar = data.buscar != null ? `buscar=${data.buscar}&` : '';
  let buscaCompleta = `${buscar}pagina=${data.pagina}&totalPorPagina=5&mesTreinamento=${data.mes}&anoTreinamento=${data.ano}`
  try {
    const resp = await client.get(`${_rotaObterTreinamentos()}?${buscaCompleta}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarGraficoDesempenho(data) {
  store.commit("interacoes/SET_LOADING", true);

  try {
    const resp = await client.post(_rotaObterGraficoDesempenho(), data);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterKpisPorAutorizada(idAutorizada, mes, ano) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterKpisPorAutorizada()}/${idAutorizada}/${mes}/${ano}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function obterUsuarioPorStatus(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterUsuariosPorStatus()}/${data.autorizadaId}/${data.mes}/${data.ano}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarGrafico(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaObterGrafico()}?idKpi=${data.kpi}&mes=${data.mes}&ano=${data.ano}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarGraficoTreinamento(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaObterGraficoTreinamento(), data);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function buscarTreinamentoEquipe(data) {
  store.commit("interacoes/SET_LOADING", true);
  let buscar = data.buscar != null ? `buscar=${data.buscarItem}&` : '';
  let buscaCompleta = `${buscar}autorizadaId=${data.autorizadaId}&pagina=${data.pagina}&totalPorPagina=5&mes=${data.mes}&ano=${data.ano}&status=${data.status}`
  try {
    const resp = await client.get(`${_rotaObterTreinamentoEquipe()}?${buscaCompleta}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function ultimaAtualizacaoPerformance() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaUltimaAtualizacaoPerformance()}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}
