<template>
  <div>
    <div class="btn-download-planilha">
      <button class="btn-exportar" @click="downlaodPlanilha()">
        <img :src="iconesImgs.iconeDownload" class="icone" /> Exportar dados
      </button>
      <a class="btn-download" ref="btnDownload" download="Planilha Usuários.xlsx">Fazer download</a>
    </div>
    <div class="grafico">
      <div class="programa">
        <div class="filtroContainer">
          <img :src="iconesImgs.iconePrograma" />
          <h2 class="titulo">Usuários</h2>
          <div class="filtro">
            <filtro-usuario :filtrarGrafico="(filtro) => carregaInfoUsuario(filtro)" :mostrarTitulo="false"
              :tipo="true" />
          </div>
        </div>
        <div class="big-numbers">
          <div class="box">
            <h2 class="titulo">Ativos</h2>
            <p class="numero">{{ infoUsuario.qtdAtivos }}</p>
          </div>

          <div class="box">
            <h2 class="titulo">Somente Catálogo</h2>
            <p class="numero">{{ infoUsuario.qtdSomenteCatalogo }}</p>
          </div>

          <div class="box">
            <h2 class="titulo">Inativos</h2>
            <p class="numero">{{ infoUsuario.qtdInativos }}</p>
          </div>

          <div class="box">
            <h2 class="titulo">Afastados</h2>
            <p class="numero">{{ infoUsuario.qtdAfastados }}</p>
          </div>

          <div class="box">
            <h2 class="titulo">Bloqueados</h2>
            <p class="numero">{{ infoUsuario.qtdBloqueados }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="statusUsuario"></div>
    <status-usuario />
  </div>
</template>

<script>
import { obterUsuarioPorStatus } from "@/api/performance";
import { downloadPlanilhaUsuarios } from "@/api/usuario";
import { mapState } from "vuex";
import FiltroUsuario from "./Filtros/FiltroUsuarios.vue";
import StatusUsuario from "./StatusUsuario/StatusUsuario.vue";
import iconesImgs from "@/utils/icones.js";
export default {
  components: {
    FiltroUsuario,
    StatusUsuario,
  },
  data() {
    return {
      iconesImgs,
      infoUsuario: {
        qtdAtivos: 0,
        qtdSomenteCatalogo: 0,
        qtdInativos: 0,
        qtdAfastados: 0
      }
    };
  },
  computed: {
    ...mapState({
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
      tabusuarios: state => state.usuario.tabUsuarios
    }),
  },
  created() {
    this.dataAtual();
    if (this.tabusuarios) {
      setTimeout(() => {
        window.scrollTo(0, 1000);
      }, 1000)
    }
  },
  methods: {
    downlaodPlanilha() {
      let info = {
        autorizadaId: parseInt(this.autorizadaAtiva),
        periodo: "",
      };
      downloadPlanilhaUsuarios(info).then((resp) => {
        let url = window.URL.createObjectURL(new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }));
        this.$refs.btnDownload.href = url;
        this.$refs.btnDownload.click();
        this.inicioDownload = false;
      });
    },
    carregaInfoUsuario(filtro) {
      filtro.autorizadaId = parseInt(this.autorizadaAtiva);
      obterUsuarioPorStatus(filtro).then((resp) => {
        this.infoUsuario = resp.data;
      });
    },
    dataAtual() {
      const hoje = new Date();
      let filtro = [];
      filtro.mes = hoje.getMonth();
      filtro.ano = 2024;
      this.carregaInfoUsuario(filtro);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-download {
  display: none;
}

.filtro {
  margin-top: 15px;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
}

.grafico {
  display: flex;
  flex-direction: column;
}

.programa {
  background-color: $azul-claro;
  // width: 100%;
  margin: 5px;
  padding: 15px;
  border-radius: 5px;

  .titulo {
    font-size: 24px;
    font-weight: 600;
    width: 150px;
    display: inline-block;
    margin-left: 30px;
    margin-bottom: 40px;
    height: 38px;

    @media (min-width: 768px) {
      width: auto;
    }
  }
}

.big-numbers {
  width: 100%;
  background: #e9e9e9;
  border-radius: 10px;
  min-height: 210px;
  margin-top: 32px;
  margin-bottom: 46px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 10px 25px 10px;
  column-gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
  }

  .box {
    width: 170px;
    height: 116px;
    margin-right: 0;
    background: #dbdbdb;
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 80%;
      margin: 8px;
    }

    .titulo {
      font-size: 14px;
      color: #373737;
      margin-bottom: 0;
      font-weight: bold;
      padding-top: 10px;
      margin-left: 10px;
    }

    .numero {
      font-size: 45px;
      color: $laranja-escuro;
      text-align: center;
      margin-top: 15px;
      font-weight: bold;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.paginacao {
  display: flex;
  justify-content: center;
  margin: 0 auto 25px auto;
  padding-top: 5px;

  button {
    padding: 6px;
    background: none;
    border: none;
    color: $cinza-interno;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
    transition: 0.3s;

    &:disabled {
      cursor: default;
    }
  }
}

.treinamentos {
  background-color: $azul-claro;
  margin: 5px;
  padding: 15px 0;
  border-radius: 5px;
  position: relative;

  .titulo {
    font-size: 24px;
    font-weight: 600;
    width: 150px;
    margin-left: 25px;
    display: inline-block;

    @media (min-width: 768px) {
      width: auto;
    }
  }

  .icone-treinamentos {
    margin-left: 20px;
  }

  .barra-titulo {
    background: $cinza-escuro;
    margin-top: 20px;
    margin-bottom: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: $branco;
    padding: 0 15px;

    .status {
      min-width: 80px;
    }

    .curso {
      width: 50%;
    }

    .pontos {
      width: 26%;
    }

    .data {
      width: 15%;
    }
  }

  .item {
    background: #e9e9e9;
    margin: 5px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    .icone {
      background: $bg-verde;
      min-width: 70px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .texto {
      padding: 10px;
      font-size: 12px;
      font-weight: 600;
      width: 100%;

      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }

      .pontos-data {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        @media (min-width: 768px) {
          width: 45%;
          margin-top: 0px;
        }
      }
    }
  }

  .btn {
    background: $bg-btn-padrao;
    color: $branco;
    margin-top: 20px;

    &:disabled {
      opacity: 0.6;
    }

    @media (min-width: 768px) {
      width: 250px;
      margin-left: auto;
    }
  }

  .h1 {
    text-align: center;
    font-size: 16px;
    margin: revert;
    margin: 60px 0;
    font-weight: revert;
    text-transform: uppercase;
    opacity: 0.6;
  }
}
</style>