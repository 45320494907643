<template>
  <div class="modalParabens">
    <div class="modalParabens__container">
      <img src="/img/icons/lock.png" />
      <h1>REDEFINIR SENHA</h1>
      <div>
        <inputValidate>
          <div class="label-input">
            <label>Senha atual</label>
            <input class="senha" :type="passwordFieldCurrent" v-model.trim="cadastro.senhaAtual"
              @blur="$v.cadastro.senhaAtual.$touch()" @keyup.enter="enterParaCadastro" />
          </div>
          <img class="pass-hide" @click="showCurrentPass" :src="imgShow" alt="WHP Técnicos" />
          <!-- <div v-if="errors" class="error">
            <span
              class="input-erro-mensagem"
              v-if="!$v.cadastro.senha.strongPassword"
              >Senha inválida</span
            >
          </div> -->
        </inputValidate>
        <inputValidate :erro="senhaLoginErro">
          <div class="label-input">
            <label>Senha</label>
            <input class="senha" :type="passwordField" v-model.trim="cadastro.senha" @blur="$v.cadastro.senha.$touch()"
              @keypress="confereSenha" @keyup.enter="enterParaCadastro" />
          </div>
          <img class="pass-hide" @click="showPass" :src="imgShow" alt="WHP Técnicos" />
          <div v-if="errors" class="error">
            <span class="input-erro-mensagem" v-if="!$v.cadastro.senha.strongPassword">Senha inválida</span>
          </div>
        </inputValidate>

        <inputValidate :erro="confirmaSenhaErro">
          <div class="label-input">
            <label>Confirmar senha</label>
            <input class="senha" :type="passwordFieldConfirm" v-model.trim="cadastro.senhaConfirma"
              @blur="$v.cadastro.senhaConfirma.$touch()" @keyup.enter="enterParaCadastro" />
          </div>
          <img class="pass-hide" @click="showPassConfirm" :src="imgShowConfirm" alt="WHP Técnicos" />
        </inputValidate>
      </div>
      <div class="modalParabens__texto">
        <p>A senha precisa conter no mínimo 8 dígitos, uma letra maiúscula, uma letra minúscula, um número e um
          caractere especial (ex.: @, !, ?, %).</p>
      </div>
      <div class="modalParabens__btn">
        <button @click="mudarSenha" class="continuar btn">
          ENVIAR
        </button>
        <button @click="fechar" class="continuar">
          FECHAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import inputValidate from "@/components/formulario/InputValidate.vue";
import { alterarSenhaMeuPerfil } from "@/api/usuario"
import {
  confirmaSenhaErro,
} from "@/plugins/vuelidate/cadastro";
export default {
  name: "ModalRedefinirSenhaMeuPerfil",

  components: {
    inputValidate
  },

  data() {
    return {
      ipUsuario: null,
      cadastro: {
        senhaAtual: null,
        senha: null,
        senhaConfirma: null
      },
      passwordFieldCurrent: "password",
      passwordField: "password",
      passwordFieldConfirm: "password",
      errors: false,
      imgShow: "/img/icons/hide.png",
      imgShowConfirm: "/img/icons/hide.png",
    };
  },

  validations: {
    cadastro: {
      senha: {
        required,
        strongPassword(senha) {
          return (
            /[A-Z]/.test(senha) && // checks upperCase char
            /[a-z]/.test(senha) && // checks for a-z
            /[0-9]/.test(senha) && // checks for 0-9
            /\W|_/.test(senha) && // checks for special char
            senha.length >= 8
          );
        },
      },
      senhaConfirma: {
        required,
        sameAs: sameAs("senha"),
      },
      senhaAtual: {
        required,
        sameAs: sameAs("senha"),
      },
    },
  },

  computed: {
    confirmaSenhaErro,
  },

  methods: {
    mudarSenha() {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
          this.ipUsuario = ip;
          const idUser = localStorage.getItem('idUser')
          const data = {
            idUsuario: Number(idUser),
            senha: this.cadastro.senhaAtual,
            novaSenha: this.cadastro.senha,
            confirmarNovaSenha: this.cadastro.senhaConfirma,
            ip: this.ipUsuario,
          }
          alterarSenhaMeuPerfil(data).then((resp) => {
            const senhaAtualInvalida = this.cadastro.senhaAtual === null;
            const senhalInvalida = this.cadastro.senha === null;
            const senhaANovaInvalida = this.cadastro.senhaConfirma === null;
            if (senhaAtualInvalida && senhalInvalida && senhaANovaInvalida) {
              this.$store.commit("SET_ALERTA", {
                show: true,
                tipo: "erro",
                mensagem: "Campos inválidos"
              });
            } else {
              this.$store.commit("SET_ALERTA", {
                show: true,
                tipo: "sucesso",
                mensagem: resp.data
              });
              this.$store.commit("interacoes/SET_MODAL_REDEFINIR_SENHA_MEU_PERFIL", {
                ativado: false,
                mensagem: "",
                tipo: "",
              });
            }
          })
        });
    },

    fechar() {
      this.$store.commit("interacoes/SET_MODAL_REDEFINIR_SENHA_MEU_PERFIL", {
        ativado: false,
        mensagem: "",
        tipo: "",
      });
    },

    confereSenha() {
      this.errors = true;
    },

    enviarNovaSenha() {

    },

    enterParaCadastro() {
      this.$v.cadastro.$invalid ? false : this.enviarNovaSenha();
    },

    showCurrentPass() {
      this.passwordFieldCurrent =
        this.passwordFieldCurrent === "password" ? "text" : "password";
      this.imgShow =
        this.imgShow === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    showPass() {
      this.passwordField =
        this.passwordField === "password" ? "text" : "password";
      this.imgShow =
        this.imgShow === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
    showPassConfirm() {
      this.passwordFieldConfirm =
        this.passwordFieldConfirm === "password" ? "text" : "password";
      this.imgShowConfirm =
        this.imgShowConfirm === "/img/icons/hide.png"
          ? "/img/icons/show.png"
          : "/img/icons/hide.png";
    },
  },
};
</script>


<style lang="scss" scoped>
.pass-hide {
  width: 30px;
  position: absolute;
  right: 10px;
  top: 25px;
  cursor: pointer;
}

.error {
  margin-top: 10px;
}

.label-input {
  display: flex;
  flex-direction: column;

  input {
    height: 40px;
  }

  label {
    margin-bottom: 5px;
    color: $grafite;
  }
}

.senha {
  height: 50px;
  margin-bottom: 20px;
  border: 1px solid $corCinzaClaro;
  border-radius: 5px;
  width: 460px;
  font-size: 18px;
  color: $neutro3;

  @media(max-width: 768px) {
    width: 280px;
  }
}

.modalParabens {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    // width: 812px;
    width: 712px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    // padding: 120px;
    padding: 30px;

    @media (max-width: 768px) {
      width: 100%;
      padding: 24px;
    }

    img {
      margin-bottom: 30px;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }
  }

  &__mensagem {
    padding: 0 45px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  &__texto {
    display: flex;
    justify-content: center;
    width: 100%;

    p {
      width: 460px;
      font-size: 14px;
      margin-bottom: 15px;

      @media(max-width: 768px) {
        width: 270px;
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      &:disabled {
        opacity: 0.5;
      }
    }

    button {
      &:nth-child(2) {
        background: transparent;
        color: $grafite;
        margin-top: 15px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 24px;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 230px;
      // margin: 20px 0 8px 0;
      height: 40px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;

      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>