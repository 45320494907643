import client from "@/api";
import store from "@/store";
// import router from "@/router";
// Caminhos
import { _rotaLogin, _rotaLogin2fa, _rotaEsqueciSenha, _rotaRedefinirSenha } from "@/api/_caminhosApi";

export async function fazerLogin(data) {
  store.commit("interacoes/SET_LOADING", true);

  try {
    let resp = "";
    if (data.token2fa) {
      resp = await client.post(_rotaLogin2fa(), data);
    } else {
      resp = await client.post(_rotaLogin(), data);
    }
    return resp;
  } catch (error) {
    const { data } = error.response,
      mensagem = typeof data == 'string' ? data : data.errors[0]
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: mensagem
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function esqueciSenha(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaEsqueciSenha()}?email=${data}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.mensagem
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function redefinirSenha(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaRedefinirSenha(), data);
    return resp;
  } catch (error) {
    const { errors } = error.response.data
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: Object.values(errors)[0][0]
    })
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}
