export default {
  namespaced: true,
  state: {
    assistenciaId: null,
    assistenciaFranquia: null,
  },
  mutations: {
    setAssistenciaId(state, payload) {
      state.assistenciaId = payload;
    },
    setAssistenciaFranquia(state, payload) {
      state.assistenciaFranquia = payload;
    },
  },
  actions: {
    defineAssistenciaFranquia(context, payload) {
      context.commit("setAssistenciaFranquia", payload);
    },
    defineAssistenciaId(context, payload) {
      context.commit("setAssistenciaId", payload);
    },
  },
};
