import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
// componentes do bundle
import Login from "@/views/Login.vue";
// Comentado para inclusão do Tampão
import Home from "@/views/Home.vue";
import Cadastro from "@/views/Cadastro.vue";
import MeuPerfil from "@/views/MeuPerfil.vue";
import BemVindo from "@/views/BemVindo.vue";
import Performance from "@/views/Performance.vue";
import PrimeiroAcesso from "@/views/ProgramaPrimeiroAcesso.vue";
import Confirmacao from "@/views/ConfirmacaoCadastro.vue";
import Campanhas from "@/views/Campanhas.vue";
import Faq from "@/views/Faq.vue";
import FaleConosco from "@/views/FaleConosco.vue";
import ChamadosListar from "@/views/chamados/Listar.vue";
import ChamadosDetalhes from "@/views/chamados/DetalhesId.vue";
import SobrePrograma from "@/views/SobrePrograma.vue";
import CampanhaAtiva from "@/views/CampanhaAtiva.vue";
// import Extrato from "@/views/Extrato.vue";


// import LayoutLogado from "@/views/_LayoutLogado.vue";
// import Dashboard from "@/views/Dashboard.vue";
// Exporta em arquivo à parte e carrega a View somente quando é solicitado
// function lazyLoad(view) {
//   return () => import(`@/views/${view}.vue`);
// }
//
Vue.use(VueRouter);
//
const routes = [
  // {
  //   path: "/",
  //   component: LayoutLogado,
  //   children: [
  //     {
  //       path: "/",
  //       name: "inicio",
  //       component: Dashboard,
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2, 3],
  //         menuInterno: {
  //           texto: "Início",
  //           icone: "IconInicio",
  //         },
  //       },
  //     },

  //     {
  //       path: "/relatorio-usuarios",
  //       name: "relatorio-usuarios",
  //       component: lazyLoad("Usuarios"),
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2],
  //         menuInterno: {
  //           texto: "Relatório de Usuários",
  //           icone: "IconRelatorioUsuarios",
  //         },
  //       },
  //     },
  //     {
  //       path: "/cupons",
  //       name: "cupons",
  //       component: lazyLoad("Cupom"),
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2],
  //         menuInterno: {
  //           texto: "Cupons",
  //           icone: "IconCupom",
  //         },
  //       },
  //     },
  //     {
  //       path: "/numero-sorte",
  //       name: "numero-sorte",
  //       component: lazyLoad("NumeroSorte"),
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2],
  //         menuInterno: {
  //           texto: "Número da Sorte",
  //           icone: "IconNumeroSorte",
  //         },
  //       },
  //     },
  //     {
  //       path: "/premiacao-instantanea",
  //       name: "premiacao-instantanea",
  //       component: lazyLoad("Premiacao"),
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2],
  //         menuInterno: {
  //           texto: "Premiação Instantânea",
  //           icone: "IconPremiacaoInstantanea",
  //         },
  //       },
  //     },
  //     {
  //       path: "/fale-conosco",
  //       name: "fale-conosco",
  //       component: lazyLoad("FaleConosco"),
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2],
  //         menuInterno: {
  //           texto: "Fale Conosco",
  //           icone: "IconFaleConosco",
  //         },
  //       },
  //     },
  //     {
  //       path: "/comunicacao",
  //       name: "comunicacao",
  //       component: lazyLoad("Comunicacao"),
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2],
  //         menuInterno: {
  //           texto: "Banners",
  //           icone: "IconComunicacao"
  //         }
  //       }
  //     },
  //     {
  //       path: "/''coes",
  //       name: "configuracoes",
  //       component: lazyLoad("Configuracoes"),
  //       meta: {
  //         precisaAutenticacao: true,
  //         perfilPermitido: [1, 2],
  //         menuInterno: {
  //           texto: "Configurações",
  //           icone: "IconConfiguracoes"
  //         }
  //       }
  //     }
  //   ],
  // },

  
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // comentado para inclusão do Tampão
  {
    path: "/cadastro",
    name: "cadastro",
    component: Cadastro,
  },
  {
    path: "/meu-perfil",
    name: "meuperfil",
    component: MeuPerfil,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "/bem-vindo",
    name: "Bem-Vindo",
    component: BemVindo,
  },
  {
    path: "/primeiro-acesso",
    name: "PrimeiroAcesso",
    component: PrimeiroAcesso,
  },
  {
    path: "/confirmacao",
    name: "Confirmação",
    component: Confirmacao,
  },
  {
    path: "/campanhas",
    name: "Campanhas",
    component: Campanhas,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "/campanha/:id",
    name: "CampanhaAtiva",
    component: CampanhaAtiva,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "/performance",
    name: "Performance",
    component: Performance,
    meta: { precisaAutenticacao: true },
  },
  // Desabilitado em 27/02/2024
  // {
  //   path: "/extrato",
  //   name: "Extrato",
  //   component: Extrato,
  //   meta: { precisaAutenticacao: true },
  // },
  {
    path: "/fale-conosco",
    name: "FaleConosco",
    component: FaleConosco,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "/historico-de-chamados",
    name: "ChamadosListar",
    component: ChamadosListar,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "/chamado/:id",
    name: "ChamadosDetalhesId",
    component: ChamadosDetalhes,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "/sobre-o-programa",
    name: "SobrePrograma",
    component: SobrePrograma,
    meta: { precisaAutenticacao: true },
  },
  {
    path: "*",
    redirect: "/",
  },
  // Fim */
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
  linkExactActiveClass: "exact-active",
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.precisaAutenticacao && !store.state.user.estaLogado) {
//     return next("/login");
//   }
//   if (
//     (to.meta.perfilPermitido &&
//       !to.meta.perfilPermitido.some(
//         (item) => item == store.state.user.perfilUsuario
//       )) ||
//     (to.path == "/login" && store.state.user.estaLogado)
//   ) {
//     return next("/");
//   }
//   if (store.state.interacoes.menuAtivado) {
//     store.commit("interacoes/SET_SIDEBAR", false);
//   }
//   if (store.state.interacoes.modal.modalAtivado) {
//     store.commit("interacoes/SET_MODAL", {
//       ativado: false,
//       mensagem: "",
//       tipo: "",
//     });
//   }
//   next();
// });

// export default router;

function fecharElementosTela() {
  if (store.state.interacoes.menuAtivado) {
    store.commit("interacoes/SET_SIDEBAR", false);
  }
  const token = localStorage.getItem("token");
  if (token)
    if (store.state.interacoes.modal.modalAtivado) {
      store.commit("interacoes/SET_MODAL", {
        ativado: false,
        mensagem: "",
        tipo: "",
      });
    }
}

function semValidacao(to) {
  const token = localStorage.getItem("token");
  return (
    to.meta.precisaAutenticacao == true &&
    !store.state.user.estaLogado &&
    !token
  );
}

function verificarPermissoes(to) {
  const perfilId = JSON.parse(localStorage.getItem("perfilId"));
  if (
    to.meta.perfilPermitido &&
    !to.meta.perfilPermitido.some((item) => item == perfilId)
  ) {
    return true;
  }
}

router.beforeEach((to, from, next) => {
  fecharElementosTela();

  if (semValidacao(to)) {
    return next("/login");
  }
  if (verificarPermissoes(to)) {
    return next("/");
  }
  next();
});

export default router;
