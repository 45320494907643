<template>
  <div class="modalParabens">
    <div class="modalParabens__container">
      <img src="/img/icons/fale-conosco-parabens.png" />
      <h1>MENSAGEM ENVIADA COM SUCESSO</h1>
      <div>
        <div class="modalParabens__mensagem">
          <p>
            Em breve nossa equipe de atendimento 
            entrará em contato com você.
          </p>
        </div>
      </div>

      <div class="modalParabens__btn">
        <button @click="fecharModal" class="continuar">
          FECHAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalFaleConoscoParabens",

  emits: ["aceitoPolitica"],

  components: {},

  data() {
    return {
      dados: [
        { id: 1, nome: "Assistência 1" },
        { id: 2, nome: "Assistência 2" },
        { id: 3, nome: "Assistência 3" },
        { id: 4, nome: "Assistência 4" },
      ],

      form: {
        cliente: null,
      },
    };
  },

  methods: {
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_FALE_CONOSCO", {
        ativado: false,
        mensagem: "Parabens",
        tipo: "",
      });
    },

    // validarPoliticaPrivacidade() {
    //   this.$emit("aceitoPolitica");

    //   this.$store.commit("interacoes/SET_MODAL_POLITICA_PRIVACIDADE", {
    //     ativado: false,
    //     mensagem: "Privacidade",
    //     tipo: "",
    //   });
    // },
  },
};
</script>


<style lang="scss" scoped>
.ps {
  height: 200px;
}
.modalParabens {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    width: 812px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 138px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 95px 24px 73px 24px;
    }

    img {
      margin-bottom: 30px;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }
  }

  &__mensagem {
    padding: 0 45px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 24px;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 260px;
      // margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;
      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>