<template>
  <div class="modal">
    <div class="modal__container" v-if="!enviado">
      <h1>Alteração de status</h1>
      <div>
        <div class="modal__mensagem">
          <p>
            Atualize os status de participação da sua equipe justificando a alteração.
            <br />A confirmação da solicitação será
            enviada por e-mail em até 3 dias úteis.
          </p>
        </div>
      </div>

      <div class="combo-selects">
        <div class="campo">
          <Multiselect v-model="form.status" label="descricao" track-by="id" :options="filtraStatus()" :multiple="false"
            placeholder="Status" select-label=" " deselect-label=" " selected-label=" " :searchable="false"
            class="select campo-maior" @input="limpaCampo(), errors.status = false"
            :class="errors.status ? 'invalid-field-multiselect' : ''" />
        </div>
        <div class="campo data" v-if="form.status.id && form.status.id != 6">
          A partir de:
          <input placeholder="Período" class="select input-data" type="date" max='2999-12-31' v-model="form.dataInicio"
            :class="errors.dataInicio ? 'tem-erro' : ''" @input="errors.dataInicio = false, mensagemErroData = ''" />
        </div>
        <div class="campo" v-if="form.status.id && form.status.id == 6">
          <Multiselect v-model="form.motivo" label="descricao" track-by="id" :options="motivos" :multiple="false"
            placeholder="Motivo" select-label=" " deselect-label=" " selected-label=" " :searchable="false"
            class="select campo-maior" :class="errors.motivo ? 'invalid-field-multiselect' : ''"
            @input="errors.motivo = false" />
        </div>
        <div class="campo" v-if="form.status.id && form.status.id == 6">
          De:
          <input placeholder="Período" class="select input-data" type="date" max='2999-12-31' v-model="form.dataInicio"
            :class="errors.dataInicio ? 'tem-erro' : ''" @input="errors.dataInicio = false, mensagemErroData = ''" />
        </div>
        <div class="campo" v-if="form.status.id && form.status.id == 6">
          Até:
          <input placeholder="Período" class="select input-data" type="date" max='2999-12-31' v-model="form.dataFim"
            :class="errors.dataFim ? 'tem-erro' : ''" @input="errors.dataFim = false, mensagemErroData = ''" />
        </div>
        <p class="msg-erro erro" v-if="mensagemErroData">{{ mensagemErroData }}</p>
        <textarea rows="4" cols="50" class="textarea" v-model="form.justificativa"
          placeholder="Digite a justificativa, ela é obrigatória!" :class="errors.justificativa ? 'tem-erro' : ''"
          @input="errors.justificativa = false" maxlength="150"></textarea>
        <p class="max-caracter">{{ 150 - form.justificativa.length }} caracteres restantes</p>
      </div>


      <label class="area-anexo" for="anexo" :class="errors.anexo ? 'tem-erro' : ''">
        <img :src="iconesImgs.iconeAnexo" alt="Clique para adicionar anexo" />
        <span class="texto"><span class="laranja">Clique aqui</span> para anexar arquivo</span>
      </label>

      <input id="anexo" class="input-file" type="file" @change="separaAnexo($event), errors.anexo = false"
        accept=".jpg, .jpeg, .png, .gif, .pdf" />
      <p :class="errors.erroAnexo ? 'descricao erro' : 'descricao'">
        Formatos suportados:
        <span :class="errors.erroAnexo ? 'laranja erro' : 'laranja'">JPG, PNG e PDF de no máximo 40 Mb.</span>
      </p>

      <div class="area-imagem" v-if="form.anexo.name">
        <div class="imagem">
          <img :src="iconesImgs.iconeAnexoLaranja" />
          <p>
            <span class="nome">{{ form.anexo.name }}</span>
            <span class="tamanho">{{ (form.anexo.size / 1048576).toFixed(3) }} Mb</span>
          </p>
        </div>
        <img :src="iconesImgs.iconeExcluirAnexo" alt="Clique para excluir o anexo" title="Clique para excluir o anexo"
          class="icone-excluir" @click="form.anexo = []" />
      </div>
      <div class="modal__btn box-botoes">
        <button class="modal__btn" @click="$emit('fechaModal', false)">
          Cancelar solicitação
        </button>
        <button class="modal__btn__btn-enviar" @click="atualizarStatus()">
          ENVIAR
        </button>
      </div>
    </div>
    <div class="modal__container" v-if="enviado">
      <img :src="iconesImgs.iconeConcluido" />
      <h1 class="titulo-enviado">{{ mensagemEnvio }}</h1>
      <div class="modal__btn enviado">
        <button class="modal__btn__btn-enviar" @click="$emit('fechaModal', false)">
          Fechar
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { atualiaStatusUsuario } from "@/api/usuario";
import Multiselect from "vue-multiselect";
import iconesImgs from "@/utils/icones.js";
import moment from "moment";
export default {
  name: "ModalStatusUsario",

  props: {
    usuarioParticipanteId: Number,
    statusUsuario: String,
    motivos: Array
  },

  components: {
    Multiselect,
  },

  data() {
    return {
      temErro: false,
      enviado: false,
      mensagemEnvio: "Envio com sucesso",
      mensagemErroData: "",
      iconesImgs,
      status: [
        { id: 1, descricao: "Ativo" },
        { id: 2, descricao: "Inativo" },
        { id: 6, descricao: "Afastado" },
      ],
      form: {
        status: '',
        motivo: { id: 0, descricao: '' },
        dataInicio: '',
        dataFim: '',
        justificativa: '',
        anexo: '',
      },
      errors: {
        status: false,
        motivo: false,
        dataInicio: false,
        dataFim: false,
        justificativa: false,
        anexo: false,
        erroAnexo: false
      }
    };
  },

  methods: {
    filtraStatus() {
      if (this.statusUsuario == "Somente catálogo" || this.statusUsuario == "Inativo") {
        return this.status.filter((item) => item.descricao == "Ativo")
      }

      if (this.statusUsuario == "Bloqueado") {
        return this.status.filter((item) => item.descricao == "Inativo" || item.descricao == "Ativo")
      }

      return this.status.filter((item) => item.descricao != this.statusUsuario)
    },
    limpaCampo() {
      this.form.motivo = [];
      this.form.periodo = "";
    },
    separaAnexo($event) {
      this.form.anexo = $event.target.files[0];
      let extensoesPermitidas = ['pdf', 'png', 'jpeg', 'jpg'];
      let extensao = this.form.anexo.name.split('.').pop();
      let formato = extensoesPermitidas.find(item => item == extensao)

      if (this.form.anexo.size > 40000000 || formato == undefined) {
        this.errors.erroAnexo = true;
        this.form.anexo = '';
        return
      }
      this.errors.erroAnexo = false;
    },
    formaData() {
      let dataSolicitacao = moment().format("YYYY-MM-DD HH:mm:ss");

      const data = new FormData();
      data.append("UsuarioStatusId", parseInt(this.form.status.id));
      data.append("StatusAfastadoId", this.form.motivo != '' ? parseInt(this.form.motivo.id) : parseInt(0));
      data.append("DataInicio", this.form.dataInicio);
      data.append("DataFim", this.form.dataFim == '' ? "1900-01-01" : this.form.dataFim);
      data.append("Justificativa", this.form.justificativa);
      data.append("Arquivo", this.form.anexo);
      data.append("DataSolicitacao", dataSolicitacao);
      data.append("UsuarioAlteradoId", parseInt(this.usuarioParticipanteId));
      data.append("UsuarioSolicitanteId", parseInt(localStorage.getItem('idUser')));

      return data;
    },

    atualizarStatus() {
      this.validaCampos();
      if (this.temErro) return;

      atualiaStatusUsuario(this.formaData()).then((resp) => {
        this.mensagemEnvio = resp.data.mensagem;
        this.enviado = true;
      })
    },
    validaCampos() {
      this.temErro = false;
      if (this.form.status == '') { this.errors.status = true; this.temErro = true; }
      if (this.form.status.id == 6 && this.form.motivo == '') { this.errors.motivo = true; this.temErro = true; }
      if (this.form.dataInicio == "") { this.errors.dataInicio = true; this.temErro = true; }
      if (this.form.status.id == 6 && this.form.dataFim == '') { this.errors.dataFim = true; this.temErro = true; }
      if (this.form.justificativa == '') { this.errors.justificativa = true; this.temErro = true; }
      if (this.form.status.id == 6 && this.form.anexo == '') { this.errors.anexo = true; this.temErro = true; }
      if (this.form.dataFim != "" && this.form.dataFim < this.form.dataInicio) {
        this.errors.dataFim = true;
        this.temErro = true;
        this.mensagemErroData = "A data final não pode ser menor que a data inicial!"
        return;
      }
      if (this.temErro) return;

      this.temErro = false;
      this.errors = {
        status: false,
        motivo: false,
        dataInicio: false,
        dataFim: false,
        justificativa: false,
        anexo: false,
      }

    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.invalid-field-multiselect {
  .multiselect__tags {
    border: 1px solid $erro-1 !important;
  }
}

.campo {
  .multiselect_ {
    &_placeholder {
      color: $textos-login !important;
    }
  }


  .multiselect__single {
    @media (max-width: 768px) {
      width: 100%;
      max-width: 160px;
    }
  }
}
</style>
<style lang="scss" scoped>
.max-caracter {
  font-size: 12px;
  display: block;
  width: 100%;
  max-width: 450px;
  padding-left: 5px;
  margin: 0 auto;
}

.enviado {
  margin: 40px 0;
}

.tem-erro {
  border: 1px solid $erro-1 !important;
}

.box-botoes {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 10px;
}

.area-imagem,
.area-anexo {
  width: 100%;
  max-width: 450px;
  height: 40px;
  background: $branco;
  margin: auto;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-content: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px dashed #bcbcbc;

  @media (max-width: 768px) {
    height: 40px;
    padding: 20px 0;
  }

  img {
    margin-bottom: 0;
  }

  .texto {
    margin-left: 5px;
    align-self: center;
  }
}

.area-imagem {
  height: 60px;
  border-style: solid;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  .imagem {
    font-size: 14px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 10px;

    .nome {
      font-weight: bold;
      margin-bottom: 5px;
      display: block;
    }

    .tamanho {
      display: block;
    }
  }

  .icone-excluir {
    cursor: pointer;
  }
}

.laranja {
  color: $laranja-claro;
}

.descricao {
  width: 100%;
  max-width: 450px;
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 32px;
}

.erro {
  font-weight: bold;
  color: $corErro !important;
}

.msg-erro {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  padding: 0;
  text-align: center;
  font-size: 12px;
}

.modal__container {
  padding: 20px 50px;
  background: $cor-gelo;

  @media(max-width: 768px) {
    padding: 40px 20px;
    height: auto;
    max-height: 100%;
    overflow-y: visible;
  }


}

.combo-selects {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;

  p {
    display: block;
  }

  .campo {
    width: 250px;
    margin-bottom: 20px;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    justify-content: end;
    grid-gap: 5px;
    color: $cinza-interno;

    &.data {
      width: 100%;
      max-width: 373px;
      justify-content: left;

      @media (max-width: 768px) {
        justify-content: end;
      }
    }
  }
}

.textarea {
  resize: none;
  background: $branco;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  max-width: 450px;
  margin-left: 5px;
  color: $textos-login;

  ::placeholder {
    color: $textos-login;
  }
}

.select {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  width: 200px;
  height: 55px;
  padding: 0px;
  color: $textos-login;
  border-radius: 5px;
  outline: none;
  background: #fff;
  margin-right: 20px;

  &::placeholder {
    color: $textos-login;
  }

  &:disabled {
    color: $textos-login;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $textos-login;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }
}

.input-data {
  padding: 0 10px;
}

.input-file {
  display: none;
}
</style>