<template>
    <div class="box-progresso">
        <div class="div-progresso" v-for="(performance, index) in performances" :key="index">
            <img src="~@/assets/images/icones/nota.png" />
            <h4 class="h4">{{ performance.tipo }} - <span class="negrito">Meta {{ performance.metaMes }}{{ performance.percentual ? "%" : ""  }}</span></h4>
            <div class="atingido">{{ performance.metaAtingidaFormatada }}</div>
            <div class="barra-branca">
                <div class="barra-cor"
                    :style="{ width: definePorcentagem(performance.metaAtingidaFormatada), backgroundColor: performance.codigoCor }">
                </div>
            </div>
            <p class="frase-final">{{ performance.statusMeta }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ["performances"],
    methods: {
        definePorcentagem(valor) {
            let valorAlterado = valor.slice(0, -1)
            if (valorAlterado > 100) {
                return "100%";
            }
            return valor;
        }
    }
}
</script>


<style lang="scss" scoped>
.box-progresso {
    width: 395px;

    @media (max-width: 768px) {
        width: 330px;
        margin: auto;
    }

    .div-progresso {
        width: 100%;
        height: 140px;
        padding: 25px 14px 0 19px;
        font-size: 12px;
        color: $grafite;
        background: $azul-claro;
        border-radius: 5px;
        margin-bottom: 10px;

        .h4 {
            margin-top: 9px;
            font-weight: 100;
        }

        .atingido {
            text-align: right;
            font-weight: bold;
            font-size: 14px;
            position: relative;
            top: -5px;
        }

        .negrito {
            font-weight: bold;
        }

        .frase-final {
            margin-top: 9px;
            margin-bottom: 19px;
        }

        .barra-branca {
            width: 100%;
            background: $branco;
            border-radius: 10px;
            height: 7px;
        }

        .barra-cor {
            border-radius: 10px;
            height: 7px;
        }
    }
}
</style>