<template>
  <div class="modalParabens">
    <div class="modalParabens__container">
      <img class="img" src="/img/icons/ranking.png" />
      <h1>RANKING <span>DE GANHADORES</span></h1>
      <div>
        <perfect-scrollbar ref="scroll">
          <div v-for="item in rankingCampanha" :key="item.posicao" class="modalParabens__mensagem">
            <span class="posicao">{{ item.posicao }}°</span>
            <img :src="item.foto ? item.foto : '/img/icons/img-upload.png'">
            <div class="descricao">
              <p class="bold">{{ item.nome }}</p>
              <p>{{ item.assistencia }}</p>
              <p>{{ item.cidade ? item.cidade : 'Cidade' }}</p>
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <div class="modalParabens__btn">
        <button @click="fecharModal" class="continuar">
          FECHAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {acessarRanking} from "@/api/campanhas";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
export default {
  name: "ModalRanking",

  components: {
    PerfectScrollbar
  },

  data() {
    return {
      rankingCampanha: [],
    };
  },

  created() {
    this.buscarRanking()
  },

  methods: {
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_RANKING", {
        ativado: false,
        mensagem: "Rankig",
        tipo: "",
      });
    },

    buscarRanking() {
      const { id } = this.$route.params
      acessarRanking(id).then(resp => {
        this.rankingCampanha = resp.data.ranking
      })
    }

  },
};
</script>


<style lang="scss" scoped>
.ps {
  height: 200px;
}
.modalParabens {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    // width: 812px;
    width: 712px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    // padding: 138px;
    padding: 50px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 95px 24px 73px 24px;
    }

    .img {
      margin-bottom: 30px;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
        span {
          display: none;
        }
      }
    }
  }

  &__mensagem {
    // padding: 0 45px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .posicao {
      font-weight: bold;
      font-size: 24px;
      line-height: 48px;
      color: #e64e20;
    }

    img {
      margin: 0 5px;
      width: 47px;
      height: 47px;
      border-radius: 50%;
    }

    @media (max-width: 768px) {
      // padding: 0 24px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: $grafite;
      // margin-bottom: 50px;
      text-align: left;
    }

    .bold {
      font-weight: 700;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    @media (max-width: 768px) {
      width: 100%;
      // padding: 0 24px;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 260px;
      // margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;
      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>