<template>
    <div class="modal">
        <div class="modal__container">
            <img :src="iconesImgs.iconeFechar" alt="Botão para fechar a janela de exportação" class="btn-fechar"
                @click="$emit('fecharModal')" />
            <h2 class="titulo-modal">Filtro por status</h2>
            <div>
                <div class="modal__mensagem">
                    <p>
                        Selecione o status desejado para visualizar
                    </p>
                </div>
            </div>

            <div class="combo-selects">
                <div class="campo">
                    <Multiselect v-model="statusFiltro" label="descricao" track-by="id" :options="status" :multiple="false"
                        placeholder="Selecione o status" select-label=" " deselect-label=" " selected-label=" "
                        :searchable="false" class="select" :allow-empty="false" />
                </div>
            </div>
            <div class="modal__btn box-botoes" v-if="statusFiltro">
                <button class="modal__btn__btn-enviar" @click="atualizaStatus()">
                    Filtrar
                </button>
            </div>
        </div>
    </div>
</template>
    
<script>
import Multiselect from "vue-multiselect";
import iconesImgs from "@/utils/icones.js";
export default {
    name: "ModalFiltroTreinamentos",

    components: {
        Multiselect,
    },

    data() {
        return {
            iconesImgs,
            statusFiltro: null,
            status: [
                { id: 0, descricao: "Ver todos" },
                { id: 4, descricao: "Aprovado" },
                { id: 5, descricao: "Reprovado" },
                { id: 6, descricao: "Não Realizado" },
            ],
        };
    },

    methods: {
        filtraStatus() {
            if (this.statusUsuario == "Somente catálogo" || this.statusUsuario == "Inativo") {
                return this.status.filter((item) => item.descricao == "Ativo")
            }

            if (this.statusUsuario == "Bloqueado") {
                return this.status.filter((item) => item.descricao == "Inativo" || item.descricao == "Ativo")
            }

            return this.status.filter((item) => item.descricao != this.statusUsuario)
        },

        atualizaStatus() {
            if (this.statusFiltro != null) {
                this.$emit('statusBusca', this.statusFiltro.id);
                return
            }
            this.$emit('statusBusca', 0);
        },
    },
};
</script>
  
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.btn-fechar {
    filter: brightness(0);
    width: 20px;
    position: absolute;
    top: 9px;
    right: 10px;
    cursor: pointer;
}

.box-botoes {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
}

.modal__container {
    position: relative;
    padding: 20px 50px;
    background: $cor-gelo;

    .titulo-modal {
        font-size: 25px;
        margin-bottom: 20px;
    }

    @media(max-width: 768px) {
        padding: 40px 20px;
        height: auto;
        max-height: 100%;
        overflow-y: visible;
    }
}

.combo-selects {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;

    p {
        display: block;
    }

    .campo {
        width: 250px;
        margin-bottom: 20px;
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        justify-content: end;
        grid-gap: 5px;
        color: $cinza-interno;
    }
}

.select {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    width: 200px;
    height: 55px;
    padding: 0px;
    color: $textos-login;
    border-radius: 5px;
    outline: none;
    background: #fff;
    margin-right: 20px;

    &::placeholder {
        color: $textos-login;
    }

    &:disabled {
        color: $textos-login;
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: $textos-login;
        transition: background-color 5000s ease-in-out 0s;
        background-color: transparent;
    }
}
</style>