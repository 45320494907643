export default {
	namespaces: true,
	state: {
		show: false,
		tipo: "",
		mensagem: ""
	},
	mutations: {
		SET_ALERTA(state, estado) {
			state.show = estado.show;
			state.tipo = estado.tipo;
			state.mensagem = estado.mensagem;

			setTimeout(function() {
				//On timeout mutate state to dismiss notification
				state.show = false,
				state.tipo = "",
				state.mensagem = ""
			}, 4000);
		}
	}
};
