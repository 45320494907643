import client from "@/api";
import store from "@/store";
import { _rotaBuscarAssistenciasAutorizadas } from "@/api/_caminhosApi";

export function buscarAssistenciasAutorizadas(id) {
  return new Promise((resolve) => {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaBuscarAssistenciasAutorizadas(id)}`)
    .then(resp => {
      resolve(resp);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
  });
}