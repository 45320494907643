export default {
	namespaced: true,
	state: {
		telaAtivada: "Lista",
		linkAtivado: "",
		componenteAtivado: "",
		formularioTipo: "Novo",
		formularioEditarId: "",
		tabela: "",
		relatorio: [],
		dadosBloqueio: [],
		statuses: "",
		paginacao: "",
		codigo: null,
		iniciaisNome: "",
		autorizaTelas: null,
		cargoUsuario: null,
		fotoUsuario: null,
		autorizada: [],
		autorizadaAtiva: '',
		autorizadaSelect: [],
		graficoTreinamento: [],
		statusUsuario: false,
		modalAnuncioStatusUsuario: null,
		tabUsuarios: false,
		totalPontos: ''
	},
	mutations: {
		SET_USUARIO_TELA(state, tela) {
			state.telaAtivada = tela;
		},
		SET_LINKATIVADO(state, link) {
			state.linkAtivado = link;
		},
		SET_COMPONENTE(state, componente) {
			state.componenteAtivado = componente;
		},
		SET_TIPO_FORMULARIO(state, tipo) {
			state.formularioTipo = tipo;
		},
		SET_ID_FORMULARIO_EDITAR(state, id) {
			state.formularioEditarId = id;
		},
		SET_TABELA(state, dados) {
			state.tabela = dados;
		},
		SET_RELATORIO(state, relatorio) {
			state.relatorio = relatorio;
		},
		SET_STATUS_ITENS(state, dados) {
			state.statuses = dados;
		},
		SET_DATA_PAGINACAO(state, dados) {
			state.paginacao = dados;
		},
		SET_DADOS_BLOQUEIO(state, dados) {
			state.dadosBloqueio = dados;
		},
		SET_CODIGO_REDEFINIR_SENHA(state, payload) {
			state.codigo = payload;
		},
		SET_INICIAIS_NOME(state, payload) {
			state.iniciaisNome = payload;
		},
		SET_AUTORIZA_TELAS(state, payload) {
			state.autorizaTelas = payload;
		},
		SET_CARGO_USUARIO(state, payload) {
			state.cargoUsuario = payload;
		},
		SET_FOTO_USUARIO(state, payload) {
			state.fotoUsuario = payload;
		},
		SET_AUTORIZADA(state, payload) {
			state.autorizada = payload;
		},
		SET_AUTORIZADA_ATIVA(state, payload) {
			state.autorizadaAtiva = payload
		},
		SET_GRAFICO_TREINAMENTO(state, payload) {
			state.graficoTreinamento = payload
		},
		SET_STATUS_USUARIO(state, payload) {
			state.statusUsuario = payload;
		},
		SET_ANUNCIO_STATUS_USUARIO(state, payload) {
			state.modalAnuncioStatusUsuario = payload;
		},
		SET_TAB_USUARIOS(state, payload) {
			state.tabUsuarios = payload
		},
		SET_SELECT_AUTORIZADA(state, payload) {
			state.autorizadaSelect = payload
		},
		SET_TOTAL_PONTOS(state, payload) {
			state.totalPontos = payload
		},
		CLEAR_STATES(state){
			state.linkAtivado = "",
			state.componenteAtivado = "",
			state.formularioTipo = "",
			state.formularioEditarId = "",
			state.tabela = "",
			state.relatorio = [],
			state.dadosBloqueio = [],
			state.statuses = "",
			state.paginacao = "",
			state.codigo = null,
			state.iniciaisNome = "",
			state.autorizaTelas = null
		}
	},
	actions: {
		defineAutorizacaoTelas(context, payload) {
			context.commit("SET_AUTORIZA_TELAS", payload);
		},
		limparStates(context){
			context.commit("CLEAR_STATES")
		},
		defineCargoUsuario(context, payload) {
			context.commit("SET_CARGO_USUARIO", payload);
		},
		defineFotoUsuario(context, payload) {
			context.commit("SET_FOTO_USUARIO", payload);
		},
		defineAutorizadaUsuario(context, payload) {
			context.commit("SET_AUTORIZADA", payload)
		},
		selecionaAutorizada(context, payload) {
			context.commit("SET_AUTORIZADA_ATIVA", payload)
		},
		adicionaDadosGraficoTreinamento(context, payload) {
			context.commit("SET_GRAFICO_TREINAMENTO", payload)
		},
		defineStatusUsuario(context, payload){
			context.commit("SET_STATUS_USUARIO", payload)
		},
		defineAnuncioStatusUsuario(context, payload){
			context.commit("SET_ANUNCIO_STATUS_USUARIO", payload)
		},
		defineTabUsuarios(context, payload) {
			context.commit("SET_TAB_USUARIOS", payload)
		},
		defineSelectAutorizada(context, payload) {
			context.commit("SET_SELECT_AUTORIZADA", payload)
		},
		defineTotalPontos(context, payload) {
			context.commit("SET_TOTAL_PONTOS", payload)
		}
	}
};
