<template>
    <div>
        <grafico-barras titulo="Desempenho no programa" />

        <div class="treinamentos">
            <div class="flex">
                <img :src="iconesImgs.iconeTreinamentos" class="icone-treinamentos">
                <h2 class="titulo">Treinamentos Realizados</h2>
                <div class="busca-treinamento desktop">
                    <input type="text" class="input-buscar" placeholder="Pesquisar" v-model="arrayBusca.buscar"><button
                        class="btn-buscar" @click="buscarPerformanceTreinamentos(1)">Buscar</button>
                </div>
            </div>
            <div class="busca-treinamento mobile">
                <div class="btn-input">
                    <input type="text" class="input-buscar" placeholder="Pesquisar" v-model="arrayBusca.buscar">
                    <button class="btn-buscar-mobile" @click="buscarPerformanceTreinamentos(1)">
                        <img :src="iconesImgs.iconeBusca" alt="Clique para buscar" title="Clique para buscar" />
                    </button>
                </div>
            </div>
            <div class="barra-titulo">
                <p class="status">STATUS</p>
                <p class="curso">CURSO</p>
                <p class="pontos">PONTOS</p>
                <p class="data">DATA</p>
            </div>
            <h2 class="h2" v-if="treinamentos.length == 0">Ainda não possui treinamentos.</h2>
            <div v-for="(item, index) in treinamentos" :key="index" class="item">
                <div v-if="item.status === 'Aprovado'" style="background: linear-gradient(#50b07c 0%, #a2e323 100%)"
                    class="icone status">
                    <img :src="iconesImgs.iconeIRealizado">
                    {{ item.status }}
                </div>
                <div v-else-if="item.status === 'Reprovado'" style="background: linear-gradient(#F7941D 0%, #E64E20 100%)"
                    class="icone status">
                    <img :src="iconesImgs.iconeIPendente">
                    {{ item.status }}
                </div>

                <div v-else style="background: #bcbcbc" class="icone status">
                    <img :src="iconesImgs.iconeIPendente">
                    {{ item.status }}
                </div>

                <div class="curso texto">
                    <p>{{ item.curso }}</p>
                </div>
                <div class="pontos texto">
                    <p>{{ item.tipoProcessamentoId == 2 ? " - " : item.pontos }}</p>
                </div>
                <div class="data texto">
                    <p>{{ formatarData(item.data) }}</p>
                </div>
            </div>
            <transition name="fade-left" mode="out-in">
                <div class="paginacao">
                    <button :disabled="arrayBusca.pagina == 1" @click="pagAnterior()">Anterior</button>
                    <p>{{ arrayBusca.pagina }} - {{ totalobjetos }}</p>
                    <button @click="proxPagina()">Próxima</button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { obterTreinamentos } from "@/api/performance";
import iconesImgs from "@/utils/icones.js";
import moment from "moment";
import GraficoBarras from "./Graficos/GraficoBarras.vue";
import { mapState } from "vuex";
export default {
    components: {
        GraficoBarras
    },
    data() {
        return {
            iconesImgs,
            totalobjetos: 0,
            ultimoItem: false,
            busca: "",
            treinamentos: [],
            arrayBusca: {
                pagina: 1,
                autorizadaId: '',
                buscar: null,
                mes: '',
                ano: ''
            },
        }
    },
    computed: {
        ...mapState({
            filtroTreinamentos: state => state.extrato.filtroTreinamentos
        })
    },
    created() {
        this.buscarPerformanceTreinamentos(1);
    },
    watch: {
        'filtroTreinamentos.mes'() {
            this.buscarPerformanceTreinamentos(1);
        },
        'filtroTreinamentos.ano'() {
            this.buscarPerformanceTreinamentos(1);
        }
    },
    methods: {
        formatarData(data) {
            return moment(data).format("DD/MM/YYYY");
        },
        pagAnterior() {
            this.ultimoItem = false
            this.arrayBusca.pagina = this.arrayBusca.pagina - 1
            this.buscarPerformanceTreinamentos(0);
        },
        proxPagina() {
            if (this.arrayBusca.pagina < this.totalobjetos) {

                this.arrayBusca.pagina = this.arrayBusca.pagina + 1
                this.buscarPerformanceTreinamentos(0);
            }
            if (this.arrayBusca.pagina == this.totalobjetos) {
                this.ultimoItem = true
            }
        },
        buscarPerformanceTreinamentos(pag) {
            pag == 0 ? '' : this.arrayBusca.pagina = pag;

            this.arrayBusca.mes = this.filtroTreinamentos.mes;
            this.arrayBusca.ano = this.filtroTreinamentos.ano == null ? new Date().getFullYear() : this.filtroTreinamentos.ano;
            this.treinamentos = [];

            if (this.filtroTreinamentos.mes == null) return;
            
            obterTreinamentos(this.arrayBusca).then(resp => {
                this.treinamentos = resp.data.treinamentos;
                this.totalobjetos = resp.data.paginacao.totalPaginas;
                this.ultimoItem = true;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
    align-items: center;
}

.paginacao {
    display: flex;
    justify-content: center;
    margin: 0 auto 25px auto;
    padding-top: 5px;

    p {
        cursor: default;
    }

    button {
        padding: 6px;
        background: none;
        border: none;
        color: $cinza-interno;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
        outline: none;
        transition: 0.3s;

        &:disabled {
            cursor: default;
        }
    }
}

.busca-treinamento {
    width: 370px;
    text-align: right;

    @media (max-width: 768px) {
        margin: 16px auto 0 auto;
        width: 100%;
    }

    .btn-input {
        width: 100%;
        max-width: 350px;
        display: flex;
        justify-content: center;
        position: relative;
        margin: auto;
    }

    .input-buscar {
        border: 0;
        border-radius: 5px;
        background: $branco;
        height: 40px;
        padding: 0 10px;

        @media (max-width: 768px) {
            width: 300px;
            margin: 0 auto;
            background: $cinza-claro;
        }
    }

    .btn-buscar {
        color: $grafite;
        height: 40px;
        text-align: center;
        background-color: $neutro6;
        font-size: 14px;
        width: 100px;
        border-radius: 5px;
        margin-left: 10px;
    }

    .btn-buscar-mobile {
        width: 40px;
        position: absolute;
        right: 8%;
        top: 8px;
        background: $cinza-claro;
    }
}

.treinamentos {
    background-color: $azul-claro;
    margin: 5px;
    padding: 15px 0;
    border-radius: 5px;
    position: relative;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 330px;
        margin-right: auto;
        margin-left: auto;
    }

    .titulo {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        width: 150px;
        margin-left: 25px;
        display: inline-block;

        @media(min-width: 768px) {
            width: auto;
            font-size: 24px;
        }
    }

    .icone-treinamentos {
        margin-left: 20px;
    }

    .barra-titulo {
        background: $dark-cinza;
        margin-top: 20px;
        margin-bottom: 0px;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: $branco;
        padding-left: 25px;
    }

    .item {
        background: $neutro6;
        margin: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        .texto {
            font-size: 12px;
            font-weight: 600;
            padding-left: 20px;

            @media(max-width: 768px) {
                padding-left: 5px;
            }
        }

        .icone {
            background: $bg-verde;
            min-width: 90px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            font-weight: bold;
            font-size: 12px;
            color: $branco;
            row-gap: 10px;

            @media(max-width: 768px) {
                font-size: 10px;
                min-width: 74px;
                text-align: center;
            }
        }
    }

    .status {
        width: 100px;

        @media(max-width: 768px) {
            width: 20%;
        }
    }

    .curso {
        width: 400px;
        padding-right: 20px;

        @media(max-width: 768px) {
            width: 40%;
        }
    }

    .data,
    .pontos {
        width: 130px;

        @media(max-width: 768px) {
            width: 25%;
            text-align: right;
            padding-right: 20px;
        }
    }

    .h2 {
        text-align: center;
        margin: 20px;
    }
}
</style>