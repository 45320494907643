import client from "@/api";
import store from "@/store";
import { _rotaListarCampanhas, _rotaListarCampanhasConsultor, _rotaParticiparDaCampanha, _rotaAcessarCampanha, _rotaAcessarRanking } from "@/api/_caminhosApi";

export async function listarCampanhas() {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(_rotaListarCampanhas());
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function listarCampanhasConsultor(idAutorizada) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaListarCampanhasConsultor()}/${idAutorizada}`);
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function participarDaCampanha(data) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.post(_rotaParticiparDaCampanha(), data)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.mensagem
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function acessarCamapnha(id) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaAcessarCampanha()}/${id}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.message
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}

export async function acessarRanking(id) {
  store.commit("interacoes/SET_LOADING", true);
  try {
    const resp = await client.get(`${_rotaAcessarRanking()}${id}`)
    return resp;
  } catch (error) {
    store.commit("SET_ALERTA", {
      show: true,
      tipo: "erro",
      mensagem: error.response.data.mensagem
    });
  } finally {
    store.commit("interacoes/SET_LOADING", false);
  }
}