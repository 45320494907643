<template>
    <section class="chat">
        <header class="chat__title" :class="{ 'andamento': status, 'finalizado': !status }">
            <h2><span>Chamado</span> - {{ this.chamado }}</h2>
        </header>
        <div>
            <div class="chat__conversation" :class="{ 'finalizado-altura': !status }">
                <div v-for="item in arrMensagens" :class="caixaDialogo(item.participante)" :key="item.id">
                    <Mensagem :participante="item.participante" :mensagem="item.mensagem" :anexos="item.anexos"
                        :tempoMsg="item.dataHora" />
                </div>
                <div ref="scrollInfinity" id="ward"></div>
            </div>
            <form v-if="status" @submit.prevent="enviarMensagem" class="chat__sendContainer">
                <div class="chat__sendContainer__anexos" v-if="selectedFile">
                    <div class="boxDoc">
                        <img :src="iconesImgs.imgAnexo" alt="imagem do anexo" className="m-3" />
                        <div class="conteudoAnexo">
                            <p class="nome">{{ selectedFile.name }}</p>
                            <p class="tamanho">{{ bytesToMegaBytes(selectedFile.size) }} Mb</p>
                        </div>
                        <div class="excluirAnexo">
                            <img :src="iconesImgs.Excluir" alt="excluir anexo"
                                @click="apagarAnexo()" />
                        </div>
                    </div>
                </div>
                <div class="chat__sendContainer__mensagem">
                    <label for="attach-file"><img :src="iconesImgs.iconeAnexo"
                            alt="botão para anexar arquivo" width="25" height="25" /></label>
                    <input @change="newFile" type="file" name="anexos" id="attach-file" style="display: none" />
                    <textarea maxlength="2000" v-model="mensagem" name="message"></textarea>
                    <button v-if="esconderBtn()" type="submit" class="btn bg-padrao">
                        <span v-if="verificarTamanhoTela()">enviar</span>
                        <img v-else :src="iconesImgs.iconeSend" alt="Icone de enviar">
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import Mensagem from '@/components/chamados/Chat/ChatMensagem.vue'
import { listarMensagensChamado, cadastrarMensagemChamado } from '@/api/chamados';
import iconesImgs from "@/utils/icones.js";
import store from "@/store";

export default {
    name: "ChamadosChat",

    components: {
        Mensagem
    },

    data() {
        return {
            iconesImgs,
            idChamado: this.$route.params.id,
            chamado: "Carregando...",
            statusId: 0,
            status: undefined,
            qtdPorPagina: 10,
            totalMsg: 0,
            arrMensagens: [],
            mensagem: "",
            selectedFile: null,
            arrStatus: [],
        };
    },

    mounted() {
        this.popularDados();
    },

    updated() {
        this.scrollInfinity()
    },

    methods: {
        verificarTamanhoTela() {
            if (window.screen.width > 768) return true
        },
        scrollInfinity() {
            const intersectionObserver = new IntersectionObserver(entries => {
                if (entries.some(entry => entry.isIntersecting) && this.qtdPorPagina < this.totalMsg && !this.$store.state.interacoes.loading) {
                    this.qtdPorPagina += 10
                    this.popularDados()
                }
            });
            intersectionObserver.observe(this.$refs.scrollInfinity);
            return () => intersectionObserver.disconnect();
        },
        caixaDialogo(lado) {
            return lado ? 'chat__dialog-right' : 'chat__dialog-left'
        },
        popularDados() {
            listarMensagensChamado(this.idChamado, 1, this.qtdPorPagina).then((resp) => {
                const { totalObjetos } = resp.data.paginacao
                const { mensagens, chamado, status } = resp.data.lista
                this.arrMensagens = mensagens
                this.chamado = chamado
                this.status = status != 4 && true
                this.totalMsg = totalObjetos
            })
        },
        esconderBtn() {
            return this.mensagem.length || this.selectedFile ? true : false;
        },
        formData() {
            const data = new FormData();

            data.append('ocorrenciaId', this.idChamado);
            data.append('mensagem', this.mensagem);
            this.selectedFile && data.append('arquivos', this.selectedFile, this.selectedFile.name);
            data.append('SacDestinatarioId', 4);

            return data;
        },
        enviarMensagem() {
            cadastrarMensagemChamado(this.formData()).then((resp) => {
                if (resp.status == 200) {
                    this.popularDados();
                    this.zerar();
                }
            }).catch(() => { })
        },
        zerar() {
            this.mensagem = "";
            this.apagarAnexo();
        },
        apagarAnexo() {
            this.selectedFile = null;
        },
        verificarTipoImg(tipo) {
            let tipoPermitido = false;
            const arrTipoImg = ['png', 'jpg', 'jpeg']
            for (const key in arrTipoImg) {
                const tipoImg = arrTipoImg[key];
                if (tipo.search(tipoImg) !== -1) {
                    return tipoPermitido = true;
                }
            }
            return tipoPermitido;
        },
        verificarTamanhoFile(tamanho) {
            let tamanhoPermitido = true
            const tamanhoMax = 20971520 
            if (tamanho > tamanhoMax) {
                return tamanhoPermitido = false
            } 
            return tamanhoPermitido
        },
        newFile(e) {
            const { type, size } = e.target.files[0]
            if(!this.verificarTipoImg(type)) {
                store.commit("SET_ALERTA", {
                    show: true,
                    tipo: "erro",
                    mensagem: 'Apenas é permitido anexar imagens tipo png, jpeg e jpg na mensagem'
                })
            } else if (!this.verificarTamanhoFile(size)) {
                store.commit("SET_ALERTA", {
                    show: true,
                    tipo: "erro",
                    mensagem: 'A imagem ultrapassa o tamanho permitido que é 20Mb'
                })
            } else {
                this.selectedFile = e.target.files[0]
            }
        },
        bytesToMegaBytes(bytes) { 
            return (bytes / (1024*1024)).toFixed(2);
        }
    },
};
</script>

<style lang="scss" scoped>
.chat {

    &__title {
        overflow: hidden;
        color: $branco;
        font-size: 22px;
        padding: 24px 5px;
        display: flex;
        flex-direction: column;
        gap: 48px;

        @media (min-width: 768px) {
            flex-direction: row;
            padding: 24px;
            font-size: 32px;
        }
    }

    &__conversation {
        display: flex;
        flex-direction: column-reverse;
        gap: 24px;
        padding: 24px 12px;
        height: 341px;
        overflow-y: scroll;

        @media (min-width: 768px) {
            height: 550px;
            padding: 24px 200px;
        }
    }

    &__dialog,
    &__dialog-left,
    &__dialog-right {

        @media (min-width: 768px) {
            width: 75%;
        }
    }

    &__dialog-left {
        float: left;

        @media (min-width: 768px) {
            margin-right: 25%;
        }
    }

    &__dialog-right {
        float: right;

        @media (min-width: 768px) {
            margin-left: 25%;
        }
    }

    &__sendContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 12px;
        margin-top: 24px;
        border-top: 1px solid #ced4da;

        &__anexos {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            margin-bottom: 8px;

            @media (max-width: 768px) {
                margin-bottom: 24px;
            }

            .boxDoc {
                display: flex;
                align-items: center;
                position: relative;
                gap: 12px;
                border: 1px solid #ced4da;
                border-radius: 3px;
                background-color: $branco;
                justify-content: space-between;
                padding: 16px;

                @media (max-width: 768px) {
                    width: 100%;
                }

                .conteudoAnexo {
                    text-align: center;
                    width: 170px;

                    @media (max-width: 768px) {
                        width: 215px;
                    }

                    .nome,
                    .tamanho {
                        font-size: 0.8rem;
                    }

                    .nome {
                        @include line-clamp(1);
                    }
                }

                .excluirAnexo {
                    cursor: pointer;
                }
            }
        }

        &__mensagem {
            display: flex;
            gap: 12px;
            align-items: center;

            label {
                cursor: pointer;
            }

            textarea {
                width: 100%;
                padding: 8px 16px;
                border-radius: 3px;
                border: 1px solid #ced4da;
                background-color: $branco;

                @media (max-width: 768px) {
                    height: 84px;
                }
            }

            .btn {
                width: auto;
            }
        }
    }
}

.andamento {
    background-color: $corVerde;
}

.finalizado {
    background-color: $laranja;

    &-altura {
        height: 498px;
    }
}
</style>