import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/user.js";
import interacoes from "@/store/interacoes.js";
import comunicacao from "@/store/comunicacao.js";
import cupom from "@/store/cupom.js";
import usuario from "@/store/usuario.js";
import sac from "@/store/sac.js";
import numero from "@/store/numero.js";
import premiacao from "@/store/premiacao.js";
import assistencia from "@/store/assistencia.js";
import extrato from "@/store/extrato.js";
import alerta from "@/store/alerta.js";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		user,
		interacoes,
		comunicacao,
		cupom,
		usuario,
		sac,
		numero,
		premiacao,
		assistencia,
		extrato,
		alerta,
	}
});
