<template>
	<div class="sobrePrograma">
		<Header :headerGrande="false" :exibeAutorizadas="true" />

		<div class="banner">
      <div class="left">
        <h2>SOBRE O PROGRAMA</h2>
        <h1>
          O Programa <span>TEC CLUB+</span><br/>
          é um programa de <br/>
          relacionamento, capacitação <br/>
          e reconhecimento das marcas BRASTEMP, CONSUL, <br/>
          KITCHENAID e B.BLEND.
        </h1>
        <p class="bold">
          Temos como objetivo reconhecer e recompensar em pontos as performances nos indicadores, treinamentos e participação nas campanhas, que você pode trocar por prêmios incríveis.          
        </p>
        <div class="pontos">
          1 PONTO = R$ 1 REAL
        </div>
        <p style="max-width: 420px;">
          Podem ser trocados no<br/> <span class="bold">Catálogo de Prêmios por Gift Card<br/> (cartão TEC CLUB+), produtos, vouchers,<br/> recarga de celular, pagamento de<br/> contas, transferências bancárias</span><br/> e muito mais.
        </p>
      </div>

      <div class="right">
        <img :src="imgBanner" alt="">
      </div>
		</div>

    <div class="metas">
      <div class="container">
        <h2>METAS</h2>
        <h1 style="max-width: none;">
          Cada cargo possui suas metas<br/>
          de forma específica: <span>{{ cargo }}</span>
        </h1>
        <div v-if="metas.length > 0" class="content">
          <div class="box" v-for="(item, index) in metas" :key="index">
            <h2>{{ item.kpi }}</h2>
            <h1>{{ item.meta }}</h1>
            <span>{{ item.pontuacao }}</span>
          </div>
          <!-- <div class="box">
            <h2>FCC</h2>
          </div>
          <div class="box">
            <h2>FCC</h2>
          </div>
          <div class="box">
            <h2>FCC</h2>
          </div> -->
        </div>
      </div>
    </div>

    <div class="descricao">
      <h1>Treinamentos</h1>
      <p v-if="cargo == 'Titular'">
        Para pontuar, <strong>é necessário que pelo menos 95% da sua autorizada seja aprovada</strong> nos treinamentos disponibilizados no mês.<br/>
        <strong>As avaliações podem ser feita até o último dia do mês, apenas 1 vez.</strong>
      </p>
      <p v-else>
        Para pontuar, é necessário ser aprovado em todos os treinamentos disponibilizados no mês e <br/>
        <strong>ter pelo menos 90% de aprovação na avaliação final, que pode ser feita até o último dia do mês, apenas 1 vez.</strong>
      </p>
      <h1>Campanhas</h1>
      <p>
        As campanhas fazem o seu engajamento no programa valer ainda mais. Participando ativamente, você tem a chance de ganhar ainda mais prêmios como<br/>
        pontuações extras ou produtos. 
      </p>
      <h1>Pontuações</h1>
      <p>
        As pontuações devem ser creditadas até o 10º dia útil do mês subsequente. Orientamos que os chamados sobre pontuação sejam realizados<br/>
        nos primeiros 7 dias após a data do crédito, como previsto em regulamento. 
        <br/><br/>
        No mês do seu aniversário, <strong>você ganha 10 pontos de presente do Programa assim que logar na plataforma.</strong>
      </p>
    </div>

		<Footer />
	</div>
</template>

<script>
import { Header, Footer } from '@/components/elementos'
import { mapState } from "vuex";
import { infoCampanha } from "@/api/usuario";
import imgTec from './../assets/images/programa/img_tecnico.png'
import imgAdmin from './../assets/images/programa/img_administrativo.png'
import imgTit from './../assets/images/programa/img_titular.png'

export default {
	name: "SobrePrograma",

	components: {
		Header,
		Footer,
	},

	computed: {
		...mapState({
			autorizaTelas: state => state.usuario.autorizaTelas
		}),
		
	},

	data() {
		return {
      cargo: '',
			imgBanner: null,
      metas: []
		};
	},

	methods: {
		getInfos() {
      infoCampanha().then((res) => {
        this.cargo = res.data.lista.cargo
        this.imgBanner = res.data.lista.cargo == 'Administrativo' ? imgAdmin : res.data.lista.cargo == 'Técnico AT' ? imgTec : imgTit
        for(let i = 0; i < res.data.lista.metas.length; i = i + 1 ) {
          
          if(res.data.lista.metas[i] != null) {
            this.metas.push(res.data.lista.metas[i])
          }
        }
        
      })
    }
	},
  mounted() {
    this.getInfos()
  },
	created() {
		if (this.autorizaTelas == false || this.autorizaTelas == null) {
			this.$router.push("/");
			return
		}
	}
};
</script>

<style lang="scss" scoped>
.sobrePrograma {
  background-color: #eff2f6;
  margin-top: -65px;
  padding-top: 75px;
  .bold {
    font-weight: 600;
  }
  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    max-width: 488px;
    margin-top: 16px;
    margin-bottom: 24px;
    span {
      color: #EF4E23;
    }
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 32px;
      max-width: 310px;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #EF4E23;
  }
  .banner {
    width: 1200px;
    margin: 50px auto 0 auto;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 90%;
    }
    .left,
    .right {
      color: #15151F;
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .left {
      p {
        max-width: 480px;
        line-height: 24px;
        br {
          display: none;
        }
        @media (max-width: 768px) {
          max-width: 330px;
          br {
            display: block;
          }
        }
      }
      .pontos {
        background-color: #9CCB3D;
        color: white;
        box-shadow: 0px 6px 0px 0px #7EA72D;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        width: fit-content;
        border-radius: 8px;
        padding: 16px 22px;
        margin: 40px 0;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 32px;
          margin: 40px auto;
        }
      }
    }
    .right {
      display: flex;
      img {
        width: 100%;
        @media (max-width: 768px) {
          margin-top: 32px;
        }
      }
    }
  }
  .metas {
    background-color: white;
    padding: 100px 0;
    color: #15151F;
    @media (max-width: 768px) {
      padding: 48px 0;
    }
    .container {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      @media (max-width: 768px) {
        width: 90%;
      }
      h1 {
        br {
          display: block;
        }
        @media (max-width: 768px) {
          max-width: 330px;
          br {
            display: none;
          }
        }
      }
      .content {
        display: flex;
        gap: 32px;
        margin-top: 56px;
        @media (max-width: 768px) {
          display: grid;
          grid-template-columns: auto auto;
          gap: 16px;
        }
        .box {
          background-color: #F5F6FA;
          border-radius: 8px;
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          span {
            background-color: #B1D667;
            border-radius: 100px;
            padding: 4px 8px;
            font-weight: 600;
          }
          h1 {
            margin: 8px 0;
          }
        }
      }
    }
  }
  .descricao {
    width: 1200px;
    margin: 0 auto;
    padding: 100px 0;
    color: #15151F;
    @media (max-width: 768px) {
      padding: 48px 0 100px;
      width: 90%;
    }
    h1 {
      color: #EF4E23;
      margin-bottom: 8px;
      margin-top: 0;
    }
    p {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
