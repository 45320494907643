<template>
  <div class="historicoDoChamado">
    <Header :headerGrande="false" />
    <BackPage />
    <main class="detalhes-chamado__container">
      <Chat />
    </main>
    <Footer />
  </div>
</template>

<script>
import { Header, Footer, BackPage } from '@/components/elementos'
import Chat from "@/components/chamados/Chat";

export default {
  name: "ChamadosDetalhesId",

  components: {
    Header,
    Footer,
    BackPage,
    Chat
  },
};
</script>

<style lang="scss" scoped>
.backPage {
  width: 1200px;
  margin: 17px auto 30px auto;

  @media (max-width: 768px) {
    margin: 17px auto 30px 24px;
  }
}

.detalhes-chamado {
  &__container {
    min-height: 568px;
    background-color: $cinzaClaro;
    margin: 0 24px;
    border-radius: 5px;
    overflow: hidden;

    @media (min-width: 768px) {
      width: 1200px;
      margin: 0 auto 134px auto;
    }
  }
}
</style>
