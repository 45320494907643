<template>
  <div id="main" :class="layout">
    <!-- <Tampao /> -->
    <Alerta />
    <transition name="fade">
      <Loading v-if="loading" />
    </transition>
    <MensagemAlerta />
    <MotivoBloqueio />
    <ModalBloqueioUsuario v-if="$store.state.usuario.statusUsuario" />
    <ModerarPremio />
    <ModalPrimeiroAcesso v-if="$store.state.interacoes.modalPrimeiroAcesso.modalAtivado" />
    <ModalDeRegulamento :mostrarBotao="false" v-if="$store.state.interacoes.modalDeRegulamento.modalAtivado" />
    <ModalPoliticaPrivacidade :mostrarBotao="false"
      v-if="$store.state.interacoes.modalPoliticaPrivacidade.modalAtivado" />
    <ModalBemVindo v-if="$store.state.interacoes.modalBemVindo.modalAtivado" />
    <ModalRedefinirSenha v-if="$store.state.interacoes.modalRedefinirSenha.modalAtivado" />
    <ModalCompletarDadosCadastrais v-if="$store.state.interacoes.modalCadastroCompleto.modalAtivado" />

    <!-- Modal aceite regulamento para assistências -->
    
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import Loading from "@/components/loading/Loading.vue";
const MensagemAlerta = () => import("@/components/modal/MensagemAlerta.vue");
const MotivoBloqueio = () => import("@/components/modal/MotivoBloqueio.vue");
const ModerarPremio = () => import("@/components/modal/ModerarPremio.vue");
// const Tampao = () => import("@/components/elementos/Tampao.vue");

const ModalPrimeiroAcesso = () => import("@/components/modal/ModalPrimeiroAcesso.vue");
const ModalBemVindo = () => import("@/components/modal/ModalBemVindo.vue");
const ModalRedefinirSenha = () => import("@/components/modal/ModalRedefinirSenha.vue");
const ModalBloqueioUsuario = () => import("@/components/modal/ModalUsuarioBloqueado.vue");
import ModalDeRegulamento from "@/components/modal/ModalDeRegulamento.vue";
import ModalPoliticaPrivacidade from "@/components/modal/ModalPoliticaPrivacidade.vue";
import ModalCompletarDadosCadastrais from "@/components/modal/ModalCompletarDadosCadastrais.vue";
import Alerta from "@/components/alerta/Alerta";
import { ativarCadastro } from "@/api/usuario";
import { acessoAoEspelhoPorId } from "@/api/espelho";

export default {
  components: {
    Loading,
    MensagemAlerta,
    MotivoBloqueio,
    ModerarPremio,
    ModalPrimeiroAcesso,
    ModalBemVindo,
    ModalBloqueioUsuario,
    ModalRedefinirSenha,
    Alerta,
    ModalDeRegulamento,
    ModalPoliticaPrivacidade,
    ModalCompletarDadosCadastrais
    // Tampao
  },

  data() {
    return {
      ipUsuario: null,
      bloqueioUsuario: true
    };
  },

  created: function () {
    // const token = window.localStorage.getItem("token");
    // if (token) {
    //   usuario.descriptografaToken(token).then( res => {

    //   //o bloco a baixo verifica se o token expirou
    //   if (res.exp < new Date().getTime()/1000) {
    //     usuario.logout()
    //     this.$store.commit("SET_ALERTA", {
    //       show: true,
    //       tipo: "erro",
    //       mensagem: "Seu login expirou"
    //     });
    //     this.$router.push("/");
    //     }
    //   })
    // }

    let codigo = new URL(window.location.href).searchParams.get("code");
    let confirmaEmail = new URL(window.location.href).searchParams.get(
      "confirma-email"
    );
    let espelho = new URL(window.location.href).searchParams.get(
      "espelho"
    );


    if (espelho) {
      const [id, tokenAdmin] = espelho.split("?token=")

      localStorage.setItem("tokenAdmin", tokenAdmin);
      localStorage.setItem("espelho", true);

      acessoAoEspelhoPorId(id).then(resp => {
        localStorage.removeItem("tokenAdmin");
        localStorage.setItem("idUser", id);
        localStorage.setItem("token", resp.data.token);
        this.$store.commit("user/SET_LOGADO", true);
        this.$router.push("/")
      })
    }
    // Código de redefinição de senha
    if (codigo) {
      this.$store.commit("usuario/SET_CODIGO_REDEFINIR_SENHA", codigo);
      this.$store.commit("interacoes/SET_MODAL_REDEFINIR_SENHA", {
        ativado: true,
        mensagem: "RedefinirSenha",
        tipo: "",
      });
      // this.$store.commit("SET_CODIGO_ALTERACAO_SENHA", codigo);
      // usuario
      //   .validarCodigoSenha(codigo)
      //   .then(() => {
      //     this.$store.commit("SET_MODAL", { show: true, tipo: "novaSenha" });
      //   })
      //   .catch(() => {
      //     this.$store.commit("SET_ALERTA", {
      //       show: true,
      //       tipo: "erro",
      //       mensagem: "Ocorreu um erro"
      //     });
      //   });
    }
    // Confirmação de email
    if (confirmaEmail) {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ipUsuario = ip;
          const data = {
            email: confirmaEmail,
            ip: this.ipUsuario,
          };
          ativarCadastro(data).then((resp) => {
            // this.$store.commit("interacoes/SET_MODAL_BEM_VINDO", {
            //   ativado: true,
            //   mensagem: "Bem Vindo",
            //   tipo: "",
            // });

            localStorage.clear();

            this.$store.commit("SET_ALERTA", {
              show: true,
              tipo: "sucesso",
              mensagem: "E-mail confirmado com sucesso."
            });

            localStorage.setItem("idUser", resp.data.usuario.id);
            localStorage.setItem("email", resp.data.usuario.email);
            localStorage.setItem("token", resp.data.token);
            this.$store.commit("user/SET_LOGADO", true);
            this.$router.push("/");
          });
        });
    }
  },
  computed: {
    layout() {
      return this.$store.state.interacoes.layout;
    },
    loading() {
      return this.$store.state.interacoes.loading;
    },
    modal() {
      return this.$store.state.interacoes.modal;
    },
  },
};
</script>

<style lang="scss">
.multiselect {
  &__tags {
    background-color: $branco !important;
    height: 49px !important;
    padding: 14px 40px 0 8px !important;
  }

  &__select {
    height: 43px !important;
    padding: 0px 8px !important;
  }

  &__input {
    color: $grafite !important;
  }

  &__single {
    @media (max-width: 768px) {
      height: 2rem;
      width: 18rem;
      position: absolute;
      line-height: 1;
      top: 10px;
      display: flex;
      align-items: center;
    }
  }
}


.filtrarBusca {
  &__filtro {
    .multiselect {
      &__tags {
        color: #373737 !important;
        border: 1px solid #373737;
        background: #eff2f6;
      }

      &__single {
        width: 76% !important;

        @media(min-width: 768px) {
          width: 114% !important;
        }
      }

      &__placeholder {
        color: #373737 !important;
      }
    }
  }
}

.carrouselHome {
  .vueperslides {
    &__parallax-wrapper {
      height: 295px !important;

      @media(min-width: 768px) {
        padding-bottom: 0px !important;
        width: 800px !important;
        height: 274px !important;
      }
    }
  }
}

.carrouselPrograma {
  .vueperslides {
    &__parallax-wrapper {
      height: 302px !important;

      @media(min-width: 768px) {
        padding-bottom: 0px !important;
        width: 733px !important;
        height: 412px !important;
      }
    }

    &__bullet {
      .default {
        border: 1px solid $grafite !important;
      }

      &--active {
        .default {
          border-width: 6px !important;
        }
      }
    }

    &__arrow {
      // display: none;
      color: $grafite;

      svg {
        margin: -16px !important;
      }
    }
  }
}

.owl-theme .owl-nav {
  display: none !important;
}

.owl-theme .owl-dots {
  display: none !important;
}
</style>
