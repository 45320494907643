<template>
    <div>
        <Grafico :graficoLista="dadosGrafico" :options="options" :series="series" type="donut" height="180"
            v-if="mostraGrafico" />
    </div>
</template>

<script>
// import { mapState } from "vuex";
import VueApexCharts from 'vue-apexcharts';
export default {
    components: {
        Grafico: VueApexCharts
    },

    props: [
        'dados'
    ],

    data() {
        return {
            mostraGrafico: false,
            dadosGrafico: '',
            arrayGrafico: {
                autorizadaId: '',
                mes: 1,
                ano: 2024
            },
            series: [],
            options: {
                colors: [],
                width: '50px',
                chart: {
                    type: 'donut',
                    width: '50px'
                },
                fill: {
                    type: ['', '', '', 'gradient'],
                    gradient: {
                        shade: 'dark',
                        type: "horizontal",
                        opacityFrom: 0,
                        opacityTo: 0,
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 100
                    },
                },
                labels: [],
                tooltip: {
                    enabled: false
                },
                legend: {
                    show: false,
                    horizontalAlign: 'center',
                    markers: {
                        width: 12,
                        height: 12,
                        strokeColor: '#fff',
                        radius: 2,
                        offsetX: -4,
                        offsetY: 1
                    },
                },
                stroke: {
                    show: false,
                },
                responsive: [{
                    breakpoint: 900,
                    options: {
                        legend: {
                            position: "bottom",
                            floating: false,
                        },
                        chart: {
                            width: 180,
                            height: 180
                        },
                        dataLabels: {
                            style: {
                                fontSize: '12px'
                            },
                        },
                    },
                }]
            },
        }
    },
    watch: {
        dados() {
            this.montaGrafico();
        }
    },
    mounted() {
        this.montaGrafico();
    },
    methods: {
        montaGrafico() {
            // console.log (this.dados.length)
            this.mostraGrafico = false;
            if (this.dados.length < 1) return;
            this.dadosGrafico = this.dados;
            let rejeitado = 100 - parseInt(this.dadosGrafico.valor);
            this.series = [parseInt(this.dadosGrafico.valor), rejeitado];
            this.options.colors = [this.dadosGrafico.codigoCor, '#CACACA']
            this.mostraGrafico = true;
        }
    },
}
</script>