<template>
  <div class="campanhas">
    <Header :headerGrande="false" :exibeAutorizadas="true" />

    <Breadcrumb :bold="true" paginaAtual="Campanhas" />

    <div class="campanhas__components"> 
        <CampanhasAtivas />
    </div>

    <Footer />
  </div>
</template>

<script>
import { Header, Footer, Breadcrumb } from '@/components/elementos'
import CampanhasAtivas from "@/components/elementos/campanhas/CampanhasAtivas.vue"
export default {
  name: "Campanhas",
  components: {
    Header,
    Footer,
    Breadcrumb,
    CampanhasAtivas,
  }
}
</script>

<style lang="scss" scoped>
  .campanhas {
    margin-top: 80px;
    &__components {
      @media(min-width: 768px) {
        margin-top: 25px !important;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
    }
  }
</style>