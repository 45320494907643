<template>
  <div class="filtrarBusca">
    <p v-if="mostrarTitulo" class="titulo-mobile">Filtrar busca</p>
    <div class="separador">
      <div class="filtrarBusca__filtro" id="multiselect">
        <p v-if="mostrarTitulo" class="titulo-desktop">Filtrar busca</p>

        <div class="filtrarBusca__filtro__container">
          <div class="filtrarBusca__filtro__mes">
            <Multiselect
              v-model="filtro.mes"
              label="mes"
              track-by="id"
              :options="mesesFiltrado"
              :multiple="false"
              :options-limit="200"
              placeholder=" "
              select-label=" "
              deselect-label=" "
              selected-label=" "
              :searchable="false"
            >
              <i slot="noOptions">Sem resultado</i>
            </Multiselect>
          </div>
          <div class="filtrarBusca__filtro__ano">
            <input-ano @anoEscolhido="filtro.ano = $event" />
          </div>
        </div>
      </div>
      <div class="button">
        <button @click="graficoFiltrado">BUSCAR</button>
      </div>
    </div>
  </div>
</template>

<script>
import InputAno from "@/components/formulario/InputAno.vue";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
export default {
  name: "FiltrarDesempenho",
  props: {
    mostrarTitulo: {
      type: Boolean,
      default: true,
    },

    tipo: {
      type: Boolean,
      default: false,
    },

    dataTipo: Object,

    filtrarGrafico: Function,

    meses: Array,

    treinamentos: Boolean,
  },
  components: {
    Multiselect,
    InputAno,
  },
  computed: {
    ...mapState({
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
    }),
  },
  data() {
    return {
      mesesDownload: null,
      baseMeses: [
        { id: 1, mes: "Janeiro" },
        { id: 2, mes: "Fevereiro" },
        { id: 3, mes: "Março" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Maio" },
        { id: 6, mes: "Junho" },
        { id: 7, mes: "Julho" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Setembro" },
        { id: 10, mes: "Outubro" },
        { id: 11, mes: "Novembro" },
        { id: 12, mes: "Dezembro" },
      ],
      mesesFiltrado: [],
      filtro: {
        mes: null,
        ano: null,
        origemkpiId: null,
        autorizadaId: null,
      },
    };
  },
  watch: {
    autorizadaAtiva() {
      this.graficoFiltrado();
    },
    meses() {
      this.filtraMeses();
    },
    "filtro.ano"() {
      this.$emit("filtraMesesPorAno", this.filtro.ano);
    },
  },
  created() {
    this.filtro.ano = { id: 2024, ano: 2024 };
    this.filtraMeses();
  },
  methods: {
    filtraMeses() {
      if (this.treinamentos && this.meses.length > 0) {
        this.mesesFiltrado = this.meses.map((item) =>
          this.baseMeses.find((nMes) => nMes.id == item)
        );
        this.mesesFiltrado.sort(function (a, b) {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
        });

        this.filtro.mes = this.mesesFiltrado[this.mesesFiltrado.length - 1];
        this.graficoFiltrado();

        return;
      }

      let mesAtual = new Date().getMonth();
      this.filtro.mes = this.meses.find((item) => item.id == mesAtual + 1);
      if (this.meses.length < 1) {
        this.mesesFiltrado = [];
        this.filtro.mes = null;
      }
    },
    graficoFiltrado() {
      const data = {
        mes: this.filtro.mes ? this.filtro.mes.id : 1,
        ano: this.filtro.ano ? this.filtro.ano.id : new Date().getFullYear(),
      };

      this.$store.commit("extrato/SET_FILTRO_EXTRATO", data);
      this.filtrarGrafico(data);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.filtrarBusca {
  margin: 0;

  .titulo-mobile {
    display: block;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .titulo-desktop {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    display: flex;

    .separador {
      display: flex;
      // margin-left: 25px;
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: $grafite;
    margin-bottom: 16px;
    text-align: left;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border: none;
      width: 302px;
      height: 50px;
      background: $bg-verde;
      border-radius: 5px;

      font-weight: 500;
      font-size: 14px;
      color: $branco;

      margin-top: 14px;
      margin-bottom: 30px;

      @media (min-width: 768px) {
        width: 150px;
        height: 50px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 15px;
      }
    }
  }

  &__filtro {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__tipo {
      width: 100%;
      margin-bottom: 5px;
    }

    @media (min-width: 768px) {
      width: 100%;
      flex-direction: row;

      p {
        width: 145px;
      }

      &__container {
        width: auto;
        display: flex;
      }

      &__tipo {
        width: 260px;
        margin-bottom: 0px;
        margin-right: 10px;
      }

      &__mes {
        width: 50%;
        margin-right: 5px;
      }

      &__ano {
        width: 50%;
        margin-left: 5px;
      }
    }

    &__mes {
      width: 145px;
      margin-right: 5px;
    }

    &__ano {
      width: 145px;
      margin-left: 5px;
    }
  }
}
</style>
