<template>
  <div class="desempenho">
    <performance-padrao v-if="cargoId == 1 || cargoId == 4 || cargoId == 7" />

    <div class="box-titular" v-if="cargoId == 8 || cargoId == 9 || cargoId == 10">

      <div class="btns">
        <div class="box-btn" v-if="cargoId == 8 || cargoId == 9">
          <button v-for="(btn, index) in autorizadasUsuario" :key="index" class="btn-select-autorizada"
            :class="autorizadaAtiva == btn.autorizadaId ? 'active' : ''" @click="selecionaAutorizada(btn.autorizadaId)"
            :id="`btn${index}`">{{ btn.autorizadaNome }}</button>
        </div>

        <!-- <div class="box-btn">
          <Multiselect v-model="autorizadaSelecionada" label="autorizadaNome" track-by="autorizadaId"
            :options="autorizadasUsuario" :multiple="false" placeholder="Autorizada" select-label=" " deselect-label=" "
            selected-label=" " :searchable="false" />
        </div> -->
      </div>

      <tabs>

        <tab name="Performance" :selected="tabusuarios ? false : true">
          <performance-titular />
        </tab>

        <tab name="Treinamentos">
          <treinamentos />
        </tab>

        <tab name="Usuários" :selected="tabusuarios ? true : false">
          <usuarios />
        </tab>

      </tabs>
    </div>

  </div>
</template>

<script>
//import Multiselect from "vue-multiselect";

import { mapState, mapActions } from "vuex";

import Tab from "@/components/estrutura/tabs/_Tab";
import Tabs from "@/components/estrutura/tabs/_Tabs";
import PerformancePadrao from "./componentesDesempenho/PerformancePadrao.vue";
import iconesImgs from "@/utils/icones.js";

/** Usuário Titular e Gerente **/
import PerformanceTitular from "./componentesDesempenho/PerformanceTitular.vue";
import Treinamentos from "./componentesDesempenho/Treinamentos.vue";
import Usuarios from "./componentesDesempenho/Usuarios.vue";

export default {
  name: "Desempenho",
  components: {
    Tab,
    Tabs,
    PerformancePadrao,
    PerformanceTitular,
    Treinamentos,
    Usuarios,
    //Multiselect
  },

  computed: {
    ...mapState({
      autorizadasUsuario: state => state.usuario.autorizada,
      autorizadaAtiva: state => state.usuario.autorizadaAtiva,
      autorizada: state => state.usuario.autorizada,
      cargoId: state => state.usuario.cargoUsuario,
      tabusuarios: state => state.usuario.tabUsuarios,
    })
  },

  data() {
    return {
      tamanhoDiv: null,
      tabTitular: true,
      exportar: false,
      iconesImgs,
      performance: true,
      autorizadaSelecionada: []
    }
  },

  watch: {
    autorizadaSelecionada() {
      if (this.cargoId == 10) return;
      this.selecionaAutorizada(this.autorizadaSelecionada.autorizadaId)
    },
  },

  mounted() {
    this.autorizadaSelecionada = this.autorizada[0];
  },

  methods: {
    ...mapActions({
      selecionaAutorizada: "usuario/selecionaAutorizada",
    }),
  },
}
</script>

<style lang="scss" scoped>
.desempenho {
  @media(min-width: 768px) {
    width: 65%;
    padding-left: 0px;
    margin-top: 65px;
  }
}

.box-titular {
  margin-top: 10px;
  margin-left: 10px;
}

.btns {
  margin-bottom: 40px;

  @media (max-width: 768px) {
    width: 300px;
    overflow-y: auto;
    margin: 40px auto 20px auto;
    padding-bottom: 10px;
  }
}

.box-btn {
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
}

.btn-select-autorizada {
  font-size: 14px;
  color: $branco;
  text-transform: uppercase;
  width: 100%;
  max-width: 350px;
  height: 40px;
  border-radius: 16px;
  background: $cinza;
  padding: 0 32px;
  margin-right: 16px;
  margin-bottom: 10px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 100;
    min-height: 40px;
  }

  &.active {
    background: $roxo;
  }
}
</style>