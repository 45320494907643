<template>
  <div class="campanhaDetalhes">
    <div>
      <div v-if="campanha.bannerDesktop != ''">
        <img
          class="mobile banner"
          :src="`${imgCampanhaEnv + campanha.bannerMobile}`"
        />
        <img
          class="desktop gitbanner"
          :src="`${imgCampanhaEnv + campanha.bannerDesktop}`"
        />
      </div>

      <div class="campanhaDetalhes__detalhes">
        <div class="topo">
          <div class="campanhaDetalhes__detalhes__datas">
            <div>
              <p class="font-weight">Início</p>
              <p>{{ dataInicio }}</p>
            </div>
            <div>
              <p class="font-weight">Término</p>
              <p>{{ dataTermino }}</p>
            </div>
            <div>
              <p class="font-weight">Data limite de aceite</p>
              <p>{{ dataLimite }}</p>
            </div>
          </div>
          <div class="campanhaDetalhes__detalhes__botoes">
            <button
              :disabled="campanha.aceitou"
              @click="participarCampanha"
              class="btnParticipar"
            >
              {{
                campanha.aceitou
                  ? "Você já está participando"
                  : "Participar da Campanha"
              }}
            </button>
            <button
              @click="abrirRanking"
              class="btnRanking"
              v-if="campanha.ranking"
            >
              Ranking
            </button>
            <a
              v-if="
                campanha.anexoInformativo != '' &&
                campanha.anexoInformativo != null
              "
              :href="`${regulamento}${campanha.anexoInformativo}`"
              class="btnRegulamento"
              target="_blank"
              title="Clique para ver o regulamento"
            >
              Regulamento
            </a>
          </div>
        </div>

        <div class="campanhaDetalhes__detalhes__info">
          <div v-if="campanha.premios">
            <v-expansion-panels
              class="mobile"
              v-if="campanha.premios.length > 0"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="btn-ver-premios">
                  Ver Prêmios
                </v-expansion-panel-header>
                <v-expansion-panel-content class="premios-mobile">
                  <p>
                    <span class="titulo"
                      ><strong>{{ campanha.premios[0].nome }}</strong></span
                    >
                    <br /><br />
                    <span
                      v-for="(premio, index) in campanha.premios"
                      :key="index"
                    >
                      {{ premio.posicao }}º
                      {{
                        premio.pontos != null ? premio.pontos : premio.produtos
                      }}<br />
                    </span>
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <h2 class="titulo">
            {{ campanha.titulo }}
          </h2>
          <p v-html="campanha.descricao" class="conteudo"></p>
        </div>

        <div v-if="campanha.premios">
          <div
            class="campanhaDetalhes__detalhes__premios desktop"
            v-if="campanha.premios.length > 0"
          >
            <h2 class="titulo">Prêmios</h2>
            <p class="titulo-premio">
              {{ campanha.premios[0].nome }}
            </p>
            <p v-for="(premio, index) in campanha.premios" :key="index">
              {{ premio.posicao }}º
              {{ premio.produtos != "0" ? premio.produtos : premio.pontos }}
            </p>
            <img
              :src="`${imgPremioEnv + campanha.premios[0].imagemPremioDesktop}`"
              class="img-premio"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { acessarCamapnha, participarDaCampanha } from "@/api/campanhas";

import moment from "moment";
export default {
  name: "campanhaDetalhes",
  data() {
    return {
      campanha: [],
      dataInicio: null,
      dataTermino: null,
      dataLimite: null,
      idCampanha: null,
      imgCampanhaEnv: process.env.VUE_APP_CAMPANHAS_IMG,
      imgPremioEnv: process.env.VUE_APP_PREMIOS_IMG,
      regulamento: process.env.VUE_APP_CAMPANHAS_IMG,
    };
  },

  created() {
    this.buscarCampanha();
  },

  methods: {
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },

    buscarCampanha() {
      const { id } = this.$route.params;
      this.idCampanha = id;
      acessarCamapnha(id).then((resp) => {
        this.campanha = resp.data.campanha;
        this.dataInicio = this.formatarData(resp.data.campanha.dataInicio);
        this.dataTermino = this.formatarData(resp.data.campanha.dataFim);
        this.dataLimite = this.formatarData(resp.data.campanha.dataLimite);
      });
    },

    participarCampanha() {
      let idObj = {
        idCampanha: this.idCampanha,
      };
      participarDaCampanha(idObj).then((resp) => {
        console.log("RESP >>" + resp.data);
        this.$store.commit("SET_ALERTA", {
          show: true,
          tipo: "sucesso",
          mensagem: resp.data.mensagem,
        });

        this.buscarCampanha();
      });
    },

    abrirRanking() {
      this.$store.commit("interacoes/SET_MODAL_RANKING", {
        ativado: true,
        mensagem: "Ranking",
        tipo: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.campanhaDetalhes {
  color: #373737;
  margin: 0 auto;
  margin-top: 2rem;
  @media (min-width: 768px) {
    width: 100%;
    max-width: 1200px;
    padding-left: 0px;
  }
  .conteudo {
    margin-right: 0;
    @media (min-width: 768px) {
      margin-right: 50px;
    }
  }
  .gitbanner {
    width: 1200px;
    height: 300px;
  }
  .banner {
    width: 100%;
  }
  .img-premio {
    margin-top: 30px;
  }
  .mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }
  &__detalhes {
    @media (min-width: 768px) {
      .topo {
        grid-area: topo;
        display: flex;
        justify-content: space-between;
      }
    }
    padding: 1rem;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: auto 30%;
      grid-row-gap: 20px;
      margin-top: 30px;
      grid-template-areas: "topo topo" "texto premios"
    }
    &__datas {
      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        width: 40%;
      }
    }
    &__botoes {
      flex-direction: row;
      border-top: 2px solid #e9e9e9;
      border-bottom: 2px solid #e9e9e9;
      padding: 20px 0;
      margin: 20px 0;
      @media (min-width: 768px) {
        width: 40%;
        display: flex;
        border: none;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
      }
    }
    &__info {
      grid-area: texto;
      p,
      blockquote {
        margin: revert;
        font-weight: revert;
      }
      strong {
        font-weight: 600;
      }
      h2 {
        font-weight: revert;
        font-size: 24px;
      }
      .titulo {
        color: $cor-laranja;
        margin-top: 30px;
        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    }
    &__premios {
      grid-area: premios;
      display: none;
      background-color: #eff2f6;
      border-radius: 5px;
      padding: 20px;
      height: fit-content;
      img {
        width: 100%;
      }
      .titulo {
        margin-bottom: 20px;
        @media (min-width: 768px) {
          font-size: 32px;
          margin-bottom: 20px;
        }
      }
    }
    .v-expansion-panel-header {
      justify-content: space-between;
      border: 1px solid $grafite;
      border-radius: 5px;
    }
    .v-expansion-panel--active > .v-expansion-panel-header {
      border: none;
    }
    .v-expansion-panel::before {
      box-shadow: none;
    }
  }
  .divBtns {
    width: 100%;
  }
  .btnParticipar {
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px !important;
    }
    padding: 0 20px;
    &:disabled {
      opacity: 0.5;
      // background: $bg-laranja;
      cursor: default;
    }
  }
  .btnRegulamento,
  .btnParticipar,
  .btnRanking {
    display: block;
    position: relative;
    z-index: 1;
    // width: 98%;
    height: 50px;
    border-radius: 5px;
    background: rgb(80, 176, 124);
    background: $bg-btn-padrao;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: $branco;
    margin: auto;
    text-transform: uppercase;
    @media (min-width: 768px) {
      margin: 0 5px;
    }
    transition: 0.3s;
    background-size: 200% auto;
    &:hover {
      background-position: right center;
      background-image: linear-gradient(
        to right,
        rgb(62, 138, 97) 10%,
        #3ab15e 51%,
        rgb(62, 138, 97) 100%
      );
    }
  }
  .btnRanking {
    display: block;
    background: $bg-btn-secundario;
    color: $branco;
    margin-top: 15px;
    @media (min-width: 768px) {
      margin: 0;
      width: 33%;
    }
  }
  .btnRegulamento {
    @media (min-width: 768px) {
      width: 160px;
    }
    border: 1px solid #50b07c;
    color: #50b07c;
    background: #fff;
    text-decoration: none;
    display: grid;
    align-content: center;
    &:hover {
      background: #fff;
    }
  }
  .desktop {
    @media (max-width: 768px) {
      display: none;
    }
    @media (min-width: 769px) {
      display: block;
    }
  }
  .font-weight {
    font-weight: 600;
  }
  [disabled] {
    background: $bg-btn-secundario;
    &:hover {
      background: $bg-btn-secundario;
    }
  }
  br:nth-child(2) {
    display: none;
  }
  .premios {
    display: none;
  }
  .btn-ver-premios {
    background: #eff2f6;
    font-weight: bold;
  }
  .premios-mobile {
    background-color: #eff2f6;
    padding: 20px 5px;
    font-size: 18px;
    line-height: 26px;

    .titulo {
      color: #000;
      display: block;
      font-size: 20px;
    }
  }

  .titulo-premio {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}
</style>
