<template>
  <div class="notificacao" :class="{ ativo: notificacaoAtivo }">
    <div class="notificacao__header">
      <p>Suas notificações</p>
      <img
        @click="fecharNotificacoes"
        src="~@/assets/images/tema/fechar-meunu.png"
        alt="Fechar"
      />
    </div>
    <div class="notificacao__conteudo">
      <div class="notificacoes__wrapper">
        <div
          v-for="notificacao in notificacoes"
          :key="notificacao.id"
          class="notificacao__conteudo__item"
          :class="{
            notificacao__conteudo__visualizacao: notificacao.vizualizada,
          }"
        >
          <p class="data">{{ formatarData(notificacao.dataEnvio) }}</p>
          <p class="descricao">{{ notificacao.mensagem }}</p>
          <p
            v-if="!notificacao.vizualizada"
            @click="marcarComoLida(notificacao.id)"
            class="marcar"
            :class="{
              notificacao__conteudo__item__desativar: notificacao.vizualizada,
            }"
          >
            Marcar como lida
          </p>
        </div>
      </div>

      <div class="notificacao__conteudo__btn">
        <button @click="marcarComoTodasLidas">MARCAR TODAS COMO LIDAS</button>
      </div>
    </div>
  </div>
</template>

<script>
import { listarNotificacoes, marcarNotificacao } from "@/api/usuario";
import moment from "moment";
export default {
  name: "Notificacoes",

  data() {
    return {
      notificacoes: null,
    };
  },

  computed: {
    notificacaoAtivo() {
      return this.$store.state.interacoes.notificacoes;
    },
  },

  created() {
    this.litarTodasNotificacoes();
  },

  methods: {
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    fecharNotificacoes() {
      this.$store.commit("interacoes/SET_NOTIFICACOES", false);
    },

    retornaIdUsuario() {
      const idUser = localStorage.getItem("idUser");
      return idUser;
    },

    litarTodasNotificacoes() {
      listarNotificacoes(this.retornaIdUsuario()).then((resp) => {
        this.$store.state.user.notificacoesNaoVisualizadas =
          resp.data.qtdNaoVisualizada;
        this.notificacoes = resp.data.lista;
      });
    },

    marcarComoLida(id) {
      marcarNotificacao(id, this.retornaIdUsuario()).then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    },

    marcarComoTodasLidas() {
      const data = {
        idUsuario: this.retornaIdUsuario(),
        id: "0",
      };
      marcarNotificacao(data.id, data.idUsuario).then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notificacao {
  width: 100%;
  height: auto;
  background: $branco;
  z-index: 1;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  transition: all 0.3s;
  @media (min-width: 768px) {
    left: 0;
    display: none;
  }

  &__header {
    height: 80px;
    background: $bg-laranja;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 11px;
      color: $branco;
    }
  }

  &__conteudo {
    &__visualizacao {
      opacity: 0.4;
    }

    &__item {
      padding: 0 36px;
      padding-top: 27px;
      border-bottom: 1px solid #bcbcbc;

      &:not(.notificacao__conteudo__visualizacao) {
        background: #edfbd3;
      }

      &__desativar {
        pointer-events: none;
      }

      .marcar {
        cursor: pointer;
      }

      .data,
      .descricao,
      .marcar {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $grafite;
      }
      .data {
        line-height: 16px;
        margin-bottom: 10px;
      }
      .descricao {
        font-weight: normal;
        line-height: 20px;
        margin-bottom: 20px;
      }
      .marcar {
        line-height: 11px;
        text-align: right;
        color: $cor-laranja;
        margin-bottom: 15px;
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      padding-top: 45px;
      padding-bottom: 29px;
      button {
        width: 302px;
        height: 50px;
        border-radius: 5px;
        background: linear-gradient(#50b07c 0%, #a2e323 100%);
        border: none;

        font-weight: 500;
        font-size: 14px;
        color: $branco;
      }
    }
  }
}

.ativo {
  transform: translateY(0%);
  @media (min-width: 768px) {
    display: block;
    left: 0;
    top: 0;
  }
}

.notificacoes__wrapper {
  height: 500px;
  overflow: scroll;
}
</style>
