import client from "@/api";
import store from "@/store";
import { _rotaListarChamados, _rotaListarMensagensChamado, _rotaCadastrarMensagemChamado, _rotaListarStatusChamado } from "@/api/_caminhosApi";

export async function listarChamados(paginacao) {
    store.commit("interacoes/SET_LOADING", true);
    try {
        const resp = await client.get(_rotaListarChamados(paginacao))
        return resp;
    } catch (error) {
        store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data.menssage
        })
    } finally {
        store.commit("interacoes/SET_LOADING", false);
    }
}

export async function listarMensagensChamado(id, pagina, qtdPorPagina) {
    store.commit("interacoes/SET_LOADING", true);
    try {
        const resp = await client.get(_rotaListarMensagensChamado(id, pagina, qtdPorPagina))
        return resp;
    } catch (error) {
        store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data
        })
    } finally {
        store.commit("interacoes/SET_LOADING", false);
    }
    
}

export async function listarStatusChamado() {
    try {
        const resp = await client.get(_rotaListarStatusChamado())
        return resp;
    } catch (error) {
        store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data
        })
    }
}

export async function cadastrarMensagemChamado(mensagem) {
    try {
        store.commit("interacoes/SET_LOADING", true);
        const resp = await client.post(_rotaCadastrarMensagemChamado(), mensagem)
        return resp;
    } catch (error) {
        store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data
        });
    }
}
