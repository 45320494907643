export default {
  namespaced: true,
  state: {
    telaAtivada: "Lista",
    linkAtivado: "",
    componenteAtivado: "",
    formularioTipo: "Novo",
    formularioEditarId: "",
    tabela: ""
  },
  mutations: {
    SET_COMUNICACAO_TELA(state, tela) {
      state.telaAtivada = tela;
    },
    SET_LINKATIVADO(state, link) {
      state.linkAtivado = link;
    },
    SET_COMPONENTE(state, componente) {
      state.componenteAtivado = componente;
    },
    SET_TIPO_FORMULARIO(state, tipo) {
      state.formularioTipo = tipo;
    },
    SET_ID_FORMULARIO_EDITAR(state, id) {
      state.formularioEditarId = id;
    },
    SET_TABELA(state, dados) {
      state.tabela = dados;
    }
  }
};
