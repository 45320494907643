<template>
  <div class="alerta" :class="[alertaAtivado, alertaTipo]" @click="fecharAlerta">
    <transition mode="out-in" name="fade">
      <div v-if="alerta.show">
        <p>{{ alerta.mensagem }}</p>
        <div class="alerta-fechar">
          <span></span>
          <span></span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
			alerta: state => state.alerta
		}),
    // alerta() {
    //   return this.$store.state.alerta;
    // },
    alertaAtivado() {
      return this.alerta.show ? "active" : "";
    },
    alertaTipo() {
      return this.alerta.tipo;
    },
  },
  methods: {
    fecharAlerta() {
      this.$store.commit("SET_ALERTA", {
        show: false,
        mensagem: "",
        tipo: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.alerta {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 10px 50px;
  cursor: pointer;
  transition: 0.3s;
  transform: rotateX(90deg);
  opacity: 0;
  background: $corCinzaClaro;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  &.active {
    visibility: visible;
    transform: rotateX(0deg);
    opacity: 1;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
  }
  @media (max-width: 795px) {
        height: 100px;
      }
}
.alerta-fechar {
  position: absolute;
  top: 50%;
  right: 10px;
  overflow: hidden;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
  span {
    position: absolute;
    height: 3px;
    transition: 0.3s;
    border-radius: 8px;
    background: $corCinzaClaro;
    &:first-child {
      top: calc(50% - 5px);
      left: 0;
      width: 30px;
      transform: translate(0, -50%);
    }
    &:last-child {
      top: calc(50% + 5px);
      left: 0;
      width: 30px;
      transform: translate(0%, -50%);
    }
  }
}
.alerta {
  background: $corAlerta;
  p {
    color: #fff;
  }
  span {
    background: $corAlerta;
    &:first-child {
      top: 21px;
      left: 50%;
      width: 5px;
      transform: translate(-50%, 0%) rotate(90deg);
    }
    &:last-child {
      top: 10px;
      left: 50%;
      width: 12px;
      transform: translate(-50%, 0%) rotate(-90deg);
    }
  }
}
.sucesso {
  background: $corSucesso;
  p {
    color: #fff;
  }
  span {
    background: $corSucesso;
    &:first-child {
      top: 17px;
      left: 6px;
      width: 8px;
      transform: translate(0%, 0%) rotate(45deg);
    }
    &:last-child {
      top: 14px;
      left: 9px;
      width: 17px;
      transform: translate(0%, 0%) rotate(-45deg);
    }
  }
}
.erro {
  background: $corErro;
  p {
    color: #fff;
  }
  span {
    background: $corErro;
    &:first-child {
      top: 50%;
      left: 50%;
      width: 20px;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:last-child {
      top: 50%;
      left: 50%;
      width: 20px;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
</style>