<template>
  <div class="campanhasAtivasCarrousel">
    <div class="campanhasAtivasCarrousel__textos">
      <span class="campanhas">Campanhas ativas</span>
      <!-- <span class="verMais">Ver mais</span> -->
    </div>
    <div v-if="!temCamp" class="campanhasAtivasCarrousel__carrouselItem campanhas-inativas">
      <div>
        <img class="desktop" src="~@/assets/images/tema/campanhas-inativas-desktop.png" />
        <img class="mobile" src="~@/assets/images/tema/campanhas-inativas.png" />
      </div>
    </div>
    <div class="imgs-carrossel" v-else-if="temCamp">
      <carousel :autoplay="true" :autoHeight="true" :responsive="{
        0: { items: 1, margin: 10 },
        768: { items: 3 },
      }">
        <template slot="prev"><img :src="setaEsquerda" alt="" class="slider-prev" v-if="quantidade > 3"></template>
        <template slot="next"><img :src="setaDireita" alt="" class="slider-next" v-if="quantidade > 3"></template>
        <div class="campanhasAtivasCarrousel__carrouselItem" v-for="(campanha, index) in campanhas" :key="index">
          <router-link :to="`/campanha/${campanha.id}`">
            <img :src="`${imgCampanhaEnv + campanha.imagemGradeMobile}`" />
          </router-link>
          <!--<button>ACESSAR</button>-->
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import { listarCampanhas, listarCampanhasConsultor, participarDaCampanha } from "@/api/campanhas";
import setaDireita from "@/assets/images/icones/seta-direita.png";
import setaEsquerda from "@/assets/images/icones/seta-esquerda.png";
import { mapState } from "vuex";

export default {
  name: "campanhasAtivasCarrousel",
  components: {
    carousel
  },
  data() {
    return {
      campanhas: {},
      temCamp: false,
      bblend: false,
      idcamp: 0,
      quantidade: 0,
      setaDireita,
      setaEsquerda,
      imgCampanhaDesk: "",
      imgCampanhaMobi: "",
      imgCampanhaEnv: process.env.VUE_APP_CAMPANHAS_IMG,
    };
  },
  computed: {
    ...mapState({
      cargoId: state => state.usuario.cargoUsuario,
      autorizadaAtiva: state => state.usuario.autorizadaAtiva,
    })
  },
  mounted() {
    this.buscarCampanhas();
  },
  watch: {
    autorizadaAtiva() {
      setTimeout(() => {
        this.buscarCampanhas();
      }, 500)
    }
  },
  methods: {
    buscarCampanhas() {
      this.temCamp = false;
      if (this.cargoId != 10) {
        listarCampanhas().then((resp) => {
          this.campanhas = resp.data.lista;
          this.quantidade = resp.data.lista.length;
          if (this.quantidade > 0) {
            this.temCamp = true;
          }
        });
      } else {
        listarCampanhasConsultor(this.autorizadaAtiva).then((resp) => {
          this.campanhas = resp.data.lista;
          this.quantidade = resp.data.lista.length;
          if (this.quantidade > 0) {
            this.temCamp = true;
          }
        });
      }
    },

    participarCampanha(id) {
      participarDaCampanha(id).then((resp) => {
        this.$store.commit("SET_ALERTA", {
          show: true,
          tipo: "sucesso",
          mensagem: resp.data.mensagem,
        });

        this.buscarCampanhas();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.campanhasAtivasCarrousel {
  .campanhas-inativas {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
      display: inline-block;
    }

    img.desktop {
      width: 100%;
      height: auto;
      margin: auto;
    }

    img.mobile {
      width: 100%;
    }
  }

  margin: 1rem;

  @media (min-width: 768px) {
    width: 800px;
    padding-left: 0px;
    margin: 0;
  }

  &__textos {
    margin: 2rem 0 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;

    @media (min-width: 768px) {
      width: 100%;
    }

    span {
      color: $grafite;
    }

    .campanhas {
      font-weight: 600;
      font-size: 16px;
    }

    .verMais {
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__carrouselItem {
    position: relative;

    @media (min-width: 768px) {
      display: flex;
    }

    .mobile {
      @media (min-width: 768px) {
        display: none;
      }
    }

    button,
    .desktop {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    button {
      position: absolute;
      bottom: 20px;
      left: calc(50% - 75px);
      width: 150px;
      height: 30px;
      background: $branco;
      border: none;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: $grafite;
      cursor: pointer;
    }

    img {
      width: 256px;
      height: 256px;

      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .block-desktop {
    @media (min-width: 768px) {
      display: none;
    }
  }
}
</style>