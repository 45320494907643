<template>
  <div>
    <ModalAceiteRegulamentoAssistencia v-if="$store.state.interacoes.modalAceiteRegulamento.modalAtivado" :dadosUsuario="dadosUsuarioModal"/>

    <div class="notificacao-mobile">
      <Notificacoes />
    </div>
    <div class="perfil">
      <div class="notificacao-desktop">
        <Notificacoes />
      </div>
      <div class="perfil__container">
        <div class="perfil__imagem flex-center-row">
          <img
            v-if="dadosUsuario.foto && dadosUsuario.foto !== 'null'"
            :src="dadosUsuario.foto"
            alt="Foto de perfil"
          />
          <div class="semFoto" v-else>
            <p>{{ iniciaisDoNome }}</p>
          </div>
          <div @click="abrirNotificacoes" class="flex-center-row">
            <p>{{ qtdNotificacoes }}</p>
          </div>
        </div>
        <div class="perfil__info">
          <p class="texto-center">
            Olá, <span>{{ dadosUsuario.nome }}</span>
          </p>
          <p class="texto-center">{{ dadosUsuario.email }}</p>
          <p class="texto-center">{{ celular }}</p>
        </div>

        <div class="perfil__ultimaAtualizacao">
          <p class="texto-center">
            {{ dataAtualizacao }}
          </p>
        </div>

        <div class="perfil__pontuacao">
          <img src="~@/assets/images/tema/pontuacao.png" alt="Pontuação" />
          <div class="perfil__pontuacao__total">
            <p>Pontuação total</p>
            <p>
              <span>{{ totalPontos | formataValorReais }}</span>
            </p>
          </div>
        </div>

        <div class="perfil__btn">
          <button @click="catalogoPremios" v-if="cargo != 9 && cargo != 10">
            <span class="texto">RESGATE JÁ</span>
          </button>
          <!-- <a :href="resgateJaLink" ref="linkResgate" class="link-resgate" target="_blank">Acessar</a> -->
        </div>

        <div class="perfil__rankingTreinamento" v-if="rankingNacional[0]">
          <div class="perfil__rankingTreinamento__ranking container ranking">
            <div style="display: flex; align-items: center">
              <img src="~@/assets/images/tema/ranking.png" alt="Ranking" />
              <p style="margin-bottom: 0px; font-size: 18px; font-weight: 700">
                Top 5 Nacional {{ rankingNacional[0].mesVigente }}
              </p>
            </div>

            <div v-if="cargo == 1 || cargo == 4">
              <div v-for="(colocado, index) in rankingNacional" :key="index">
                <p class="colocacao">
                  {{ colocado.posicaoNacional }}° -
                  {{ colocado.nomeParticipante.split(" ")[0] }}
                </p>
                <p class="sub">{{ colocado.nomeAutorizadas[0] }}</p>
              </div>
            </div>

            <div v-if="cargo == 7">
              <div v-for="(colocado, index) in rankingNacional" :key="index">
                <p class="colocacao">
                  {{ colocado.posicaoNacional }}° -
                  {{ colocado.nomeParticipante.split(" ")[0] }}
                </p>
                <p class="sub">{{ colocado.nomeAutorizadas[0] }}</p>
              </div>
            </div>

            <div v-if="cargo == 8 || cargo == 9 || cargo == 10">
              <div v-for="(colocado, index) in rankingNacional" :key="index">
                <p class="colocacao">
                  {{ colocado.posicaoNacional }}° -
                  {{ colocado.nomeFantasiaAutorizada }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listarNotificacoes } from "@/api/usuario";
import { formataValorReais } from "@/utils/filters.js";
import Notificacoes from "@/components/elementos/home/Notificacoes.vue";
import { ultimaAtualizacaoExtrato, obterDadosUsuario, buscarPerfilUsuarioModal } from "@/api/usuario";
import { consultaPontosUsuario } from "@/api/catalogo";
import { buscarRankingNacional } from "@/api/performance";
import { irParaCatalogo } from "@/api/catalogo";
import { mapActions, mapState } from "vuex";
import ModalAceiteRegulamentoAssistencia from "@/components/modal/ModalAceiteRegulamentoAssistencia.vue";
import moment from "moment";
export default {
  name: "PerfilUsuario",

  filters: {
    formataValorReais,
  },

  components: {
    Notificacoes,
    ModalAceiteRegulamentoAssistencia
  },

  data() {
    return {
      qtdNotificacoes: 0,
      totalPontos: "",
      dadosUsuario: {
        foto: null,
        ranking: {
          posicaoNacional: 0,
        },
      },
      dadosUsuarioModal: {},
      rankingNacional: [],
      celular: null,
      dataAtualizacao: null,
      iniciaisDoNome: null,
      cargo: null,
    };
  },
  computed: {
    ...mapState({
      fotoUsuario: (state) => state.usuario.fotoUsuario,
      autorizadaSelect: (state) => state.usuario.autorizadaSelect,
      autorizadaAtiva: (state) => state.usuario.autorizadaAtiva,
    }),
  },

  created() {
    this.buscarDadosUsuario();
  },

  watch: {
    autorizadaAtiva() {
      this.buscaDadosUsuarioPorAutorizada();
    },
  },

  methods: {
    ...mapActions({
      defineAutorizacaoTelas: "usuario/defineAutorizacaoTelas",
      defineCargoUsuario: "usuario/defineCargoUsuario",
      defineFotoUsuario: "usuario/defineFotoUsuario",
      defineAutorizadas: "usuario/defineAutorizadaUsuario",
      defineSelectAutorizada: "usuario/defineSelectAutorizada",
      defineStatusUsuario: "usuario/defineStatusUsuario",
      selecionaAutorizada: "usuario/selecionaAutorizada",
      defineTotalPontos: "usuario/defineTotalPontos",
    }),
    catalogoPremios() {
      if (localStorage.getItem("espelho")) {
        return;
      }

      // eslint-disable-next-line no-unused-vars
      irParaCatalogo().then((resp) => {
        // this.$store.commit("SET_ALERTA", {
        //   show: true,
        //   tipo: "erro",
        //   mensagem: 'O Catálogo de Prêmios está desabilitado momentaneamente.',
        // });
        if (resp.data.url == null) {
          this.$store.commit("interacoes/SET_MODAL_CADASTRO_COMPLETO", {
            ativado: true,
            mensagem: "Modal",
            tipo: "",
          });
        } else {
          //--->MANTER COMENTADO this.resgateJaLink = resp.data.url;
          //--->MANTER COMENTADO this.$refs.linkResgate.click();

          /* O método window.open não funciona no IOs */
          window.open(resp.data.url, "_blank");
        }
      });
    },
    abrirNotificacoes() {
      this.$store.commit("interacoes/SET_NOTIFICACOES", true);
    },

    formatarCelular(celular) {
      return celular
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)(\d{4})(\d{4})$/, "($1) $2$3-$4");
    },

    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },

    pegarDuasLetrasDoNome(nome) {
      const nomeArray = nome.split(" ");

      let segundaLetra = "";

      const primeiraLetra = nomeArray[0].substr(0, 1);
      if (nomeArray[1]) {
        segundaLetra = nomeArray[1].substr(0, 1);
      }

      const inciais = `${primeiraLetra}${segundaLetra}`;
      this.iniciaisDoNome = inciais;
      this.$store.commit("usuario/SET_INICIAIS_NOME", inciais);
    },

    async buscarDadosUsuario() {
      obterDadosUsuario().then((resp) => {
        this.dadosUsuario = resp.data;

        this.ArrayAutorizada();
        this.buscaDadosUsuarioPorAutorizada();

        this.cargo = resp.data.idCargo;
        localStorage.setItem("fotoUsuario", resp.data.foto);
        this.defineFotoUsuario(resp.data.foto);
        this.defineCargoUsuario(resp.data.idCargo);
        this.celular = this.formatarCelular(resp.data.celular);

        if (!this.$store.state.usuario.iniciaisNome) {
          this.pegarDuasLetrasDoNome(resp.data.nome);
        }

        if (this.dadosUsuario.idCargo == "5") {
          this.defineAutorizacaoTelas(false);
        } else {
          this.defineAutorizacaoTelas(true);
        }

        if (this.dadosUsuario.idCargo != 6 && this.dadosUsuario.idCargo != 5) {
          this.obterRankigNacional();
        }

        if (this.dadosUsuario.aceiteRegulamento === 0 && !localStorage.getItem("espelho")) {
          buscarPerfilUsuarioModal().then((resp) => {
            this.dadosUsuarioModal = resp.data
          });
          this.$store.commit("interacoes/SET_MODAL_ACEITE_DE_REGULAMENTO", {
            ativado: true,
            mensagem: "Regulamento",
            tipo: "",
          });
        }
      });

      const notificacoes = await listarNotificacoes(
        localStorage.getItem("idUser")
      );

      this.qtdNotificacoes = notificacoes.data.qtdNaoVisualizada;
      this.obterUltimaAtualizacao();
    },

    async buscaDadosUsuarioPorAutorizada() {
      consultaPontosUsuario().then((resp) => {
        this.totalPontos = resp.data.balance;
        this.defineTotalPontos(this.totalPontos);
      });

      // if (this.dadosUsuario.idCargo == 10) {
      //   obterDadosUsuarioPorAutorizada(this.autorizadaAtiva).then((resp) => {
      //     this.totalPontos = resp.data.totalPontos;
      //     this.defineTotalPontos(this.totalPontos);
      //   })
      // } else {
      //   this.totalPontos = this.dadosUsuario.totalPontos;
      //   this.defineTotalPontos(this.totalPontos);
      // }
    },

    listaOrdemAlfabetica(value) {
      let listaOrdenada = value.sort(function (a, b) {
        if (a.autorizadaNome < b.autorizadaNome) return -1;
        return true;
      });
      return listaOrdenada;
    },

    ArrayAutorizada() {
      let autorizadas = [];
      this.dadosUsuario.autorizadaId.forEach((item, index) => {
        autorizadas.push({
          autorizadaId: item,
          autorizadaNome: this.dadosUsuario.autorizada[index],
          autorizadaNomeFantasia: `${this.dadosUsuario.autorizadaCodigoSap[index]} - ${this.dadosUsuario.autorizadaNomeFantasia[index]}`,
        });
      });

      //Envia lista de autorizadas para Vuex

      this.defineAutorizadas(this.listaOrdemAlfabetica(autorizadas));

      if (this.autorizadaSelect.length < 1) {
        this.selecionaAutorizada(autorizadas[0].autorizadaId);
        this.defineSelectAutorizada(autorizadas[0]);
      }
    },

    async obterUltimaAtualizacao() {
      const { data } = await ultimaAtualizacaoExtrato();
      this.dataAtualizacao = data;
    },

    async obterRankigNacional() {
      let payload = {
        quantidade: 5,
        idCargo: this.dadosUsuario.idCargo,
      };
      await buscarRankingNacional(payload).then((res) => {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          let mes = res.data[i].mesVigente;
          switch (mes) {
            case 1:
              mes = "Janeiro";
              break;
            case 2:
              mes = "Fevereiro";
              break;
            case 3:
              mes = "Março";
              break;
            case 4:
              mes = "Abril";
              break;
            case 5:
              mes = "Maio";
              break;
            case 6:
              mes = "Junho";
              break;
            case 7:
              mes = "Julho";
              break;
            case 8:
              mes = "Agosto";
              break;
            case 9:
              mes = "Setembro";
              break;
            case 10:
              mes = "Outubro";
              break;
            case 11:
              mes = "Novembro";
              break;
            case 12:
              mes = "Dezembro";
              break;
            default:
              mes = "Mês inexistente";
          }
          data[i].mesVigente = mes;
          this.rankingNacional = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link-resgate {
  display: none;
}

.white {
  width: 100%;
  height: 20px;
}

.notificacao-mobile {
  @media (min-width: 768px) {
    display: none;
  }
}

.perfil {
  .notificacao-desktop {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  background-color: $cor-gelo;
  position: relative;
  top: -90px;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  width: calc(100% - 26px);
  max-width: 400px;
  height: auto;
  margin: 0 13px;
  border-radius: 5px;

  @media (min-width: 768px) {
    position: relative;
    top: 0px;
    height: 100%;
    overflow: hidden;
  }

  &__container {
    padding: 48px 37px 23px 37px;
    height: 100%;
  }

  &__imagem {
    position: relative;
    margin-bottom: 28px;

    .semFoto {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 110px;
      background: $branco;
      position: static;

      p {
        color: orangered;
        font-size: 30px;
      }
    }

    img {
      width: 110px;
      height: 110px;
      border-radius: 50%;
    }

    div {
      position: absolute;
      top: 10px;
      right: 85px;
      background: $bg-verde;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      cursor: pointer;

      p {
        font-weight: bold;
        margin: 0;
        font-size: 15px;
        color: $cor-gelo;
      }
    }
  }

  &__info {
    p {
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
    }

    span {
      font-weight: bold;
    }
  }

  &__ultimaAtualizacao {
    margin: 49px 0 15px 0px;

    p {
      font-size: 16px;
      line-height: 24px;
      color: $cor-laranja;
    }

    span {
      font-weight: bold;
    }
  }

  &__pontuacao {
    width: 302px;
    height: 81px;
    border-radius: 5px;
    background: $cinza-claro;
    display: flex;
    align-items: center;

    img {
      width: 29px;
      height: 45px;
      margin: 0px 28px 0px 20px;
      margin-top: 5px;
    }

    &__total {
      display: flex;
      flex-direction: column;

      p {
        font-size: 14px;
        line-height: 21px;
        color: $grafite;
      }

      span {
        font-weight: bold;
        font-size: 32px;
        line-height: 28px;
        color: $grafite;
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-top: 15px;

    a {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
      color: inherit;
    }

    button {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: none;
      font-weight: 500;
      font-size: 14px;
      color: $branco;
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }

      &:nth-child(1) {
        background-color: #50b07c;
      }

      // &:nth-child(2) {
      //   background-color: #50b07c;
      // }
    }
  }

  &__rankingTreinamento {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .container {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: $cinza-claro;
    }

    .ranking {
      padding: 15px;
    }

    .treinamentos {
      padding: 18px 0px 13px 9px;
    }

    &__ranking {
      :nth-child(2) {
        p {
          &:nth-child(1) {
            color: $cor-laranja !important;
          }
        }
      }

      img {
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $grafite;
      }

      .colocacao {
        font-weight: bold;
        font-size: 17px;
        line-height: 30px;
        color: $grafite;
        margin-top: 10px;
        text-transform: capitalize;
      }

      .barra {
        font-weight: bold;
        font-size: 35px;
        line-height: 39px;
        color: #bcbcbc;
      }

      .total {
        font-weight: bold;
        font-size: 16px;
        line-height: 39px;
        color: #bcbcbc;
      }

      .sub {
        margin-top: -10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 280px;
      }
    }

    &__treinamento {
      img {
        margin-bottom: 10px;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $grafite;
      }

      span {
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        color: $grafite;
        margin-top: 10px;
      }

      .numero {
        margin-top: 8px;

        span {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
