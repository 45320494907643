<template>
  <div class="backPage">
    <button @click="back"><img src="@/assets/images/icones/arrow-left.svg">voltar</button>
  </div>
</template>

<script>
export default {
  name: "BackPage",

  methods: {
    back() {
      // Volta para a página anterior
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.backPage {
  button {
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
    @media (min-width: 768px) {
      margin: 0;
    }
  }
}
</style>