<template>
  <div class="faleConosco">
    <ModalFaleConoscoParabens v-if="$store.state.interacoes.faleConosco.modalAtivado" />
    <Header :headerGrande="false" :exibeAutorizadas="true" />
    <div class="bread">
      <Breadcrumb :bold="true" caminhoUm="FAQ" paginaAtual="Fale conosco" />
    </div>
    <div class="faleConosco__container">
      <div class="faleConosco__descricao">
        <h2 class="text-center">FALE CONOSCO</h2>
        <p class="text-center">
          Para abrir um novo chamado, selecione o assunto e escreva sua mensagem
          no formulário abaixo ou <br /> Ligue para <a target="_blank"
            href="https://api.whatsapp.com/send?phone=5508002360011"><span><img src="/img/icons/wp.png"
                alt="Whatsapp"></span><span class="laranja">0800 236 0011</span></a> e fale com um de nossos atendentes.
        </p>
        <p class="text-center mb-30">
          <span>O atendimento é feito de segunda a sexta-feira, das 9h às 18h
            (exceto feriados).</span>
        </p>
      </div>

      <div class="faleConosco__historic">
        <button @click="toHistoricPage" class="btn btn-historic">
          ver historico de chamados
        </button>
      </div>

      <div class="faleConosco__form">
        <div class="faleConosco__form__item">
          <label>Assunto</label>
          <Multiselect v-model="dados.assunto" label="descricao" track-by="id" :options="assunto" :multiple="false"
            :options-limit="200" placeholder="Assunto" select-label="Selecionar" deselect-label="Remover"
            selected-label="Selecionado" :searchable="false" />
        </div>

        <div class="faleConosco__form__item">
          <label>Mensagem</label>
          <textarea v-model="dados.mensagem"></textarea>
        </div>

        <div class="faleConosco__form__btns">
          <button @click="faleConoscoEnviar">ENVIAR</button>
          <button @click="fechar">FECHAR</button>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { Header, Footer, Breadcrumb } from '@/components/elementos'
import ModalFaleConoscoParabens from "@/components/modal/ModalFaleConoscoParabens.vue";
import Multiselect from "vue-multiselect";
import { listarAssuntos, faleConosco } from "@/api/faleConosco";
export default {
  name: "FaleConosoco",

  components: {
    Header,
    Footer,
    Breadcrumb,
    Multiselect,
    ModalFaleConoscoParabens,
  },

  data() {
    return {
      dados: {
        assunto: '',
        mensagem: '',
      },
      errors: {
        mensagem: '',
        dadosValidos: false
      },
      assunto: [],
    };
  },

  created() {
    this.faleConoscoAssuntos();
  },

  methods: {
    faleConoscoAssuntos() {
      listarAssuntos().then((resp) => {
        this.assunto = resp.data;
      });
    },
    verificarVariavel(data) {
      if (data == undefined || data == null || data == '') return false
      return true
    },
    verificarCampos() {
      if (!this.verificarVariavel(this.dados.assunto) && !this.verificarVariavel(this.dados.mensagem)) return this.errors.mensagem = 'O assunto e o campo mensagem deverão ser preenchidos'
      else if (!this.verificarVariavel(this.dados.assunto)) return this.errors.mensagem = 'O assunto é obrigatório e deve ser selecionado'
      else if (!this.verificarVariavel(this.dados.mensagem)) return this.errors.mensagem = 'O campo mensagem é obrigatório'
      this.errors.dadosValidos = true
    },
    limparDados() {
      this.dados.assunto = '';
      this.dados.mensagem = '';
      this.errors.dadosValidos = false
    },
    faleConoscoEnviar() {
      this.verificarCampos()
      if (this.errors.dadosValidos) {
        let data = new FormData();
        data.append("idUsuario", parseInt(localStorage.getItem("idUser")));
        data.append("sacAssuntoId", parseInt(this.dados.assunto.id));
        data.append("sacAssuntoDescricao", this.dados.assunto.descricao);
        data.append("mensagem", this.dados.mensagem);
        data.append("sacMensagemDestinatarioId", 4);
        data.append("sacOcorrenciaMeioDeContatoId", 2);
        data.append("critico", false);
        data.append("sacStatusId", 2);
        
        faleConosco(data).then((resp) => {
          if (resp) {
            this.limparDados()
            this.$store.commit("interacoes/SET_MODAL_FALE_CONOSCO", {
              ativado: true,
              mensagem: "Parabens",
              tipo: "",
            });
          }
        })
      } else {
        this.$store.commit("SET_ALERTA", {
          show: true,
          tipo: "erro",
          mensagem: this.errors.mensagem
        });
      }
    },

    fechar() {
      this.$router.push("/");
    },

    toHistoricPage() {
      this.$router.push("/historico-de-chamados");
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style lang="scss" scoped>
.bread {
  width: 1200px;
  margin: 0 auto;
}

.faleConosco {
  &__container {
    margin: 0 36px;

    @media (min-width: 768px) {
      width: 1200px;
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 134px;
    }
  }

  &__descricao {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text-center {
      text-align: center;
    }

    h2,
    p {
      color: $grafite;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    h2,
    span {
      font-weight: bold;
    }

    p {
      font-size: 12px;
      line-height: 20px;
    }

    img {
      width: 15px;
      height: 15px;
    }

    .laranja {
      color: $laranja;
      font-weight: bold;
      text-decoration: underline;
    }

    .mb-30 {
      margin-bottom: 30px;
    }

    @media (min-width: 768px) {
      h2 {
        font-size: 32px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      padding: 0 300px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;

      textarea {
        height: 88px;
        border: 1px solid #acacac;
        border-radius: 5px;
        color: #707070;
        font-size: 18px;
        font-family: Montserrat;
      }

      label {
        font-weight: 500;
        font-size: 14px;
        color: #4b4b4b;
        margin-bottom: 2px;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &__btns {
      display: flex;
      flex-direction: column;

      button {
        border: none;
        width: 302px;
        height: 50px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;

        &:first-child {
          background: $bg-laranja;
          margin-bottom: 15px;
          color: $branco;
        }

        &:last-child {
          background: transparent;
          color: $grafite;
        }
      }
    }
  }

  &__historic {
    @extend .faleConosco__form;
    margin: 30px 0;

    .btn-historic {
      background: $bg-btn-padrao-inverse;
      color: $branco;

      &:disabled {
        cursor: not-allowed;
        background: $bg-btn-padrao-inverse;
        opacity: 0.6;
      }
    }
  }
}
</style>
