<template>
  <div class="modalParabens">
    <div class="modalParabens__container">
      <img src="/img/icons/excluirConta.png" />
      <h1>CONTA EXCLUÍDA</h1>
      <div>
        <div class="modalParabens__mensagem">
          <p>
            É uma pena que você vai embora :( Sua conta foi excluída, assim como
            todos os seus dados, incluindo o seu perfil.
          </p>
          <h2>Esperamos te ver de novo em breve!</h2>
        </div>
      </div>

      <div class="modalParabens__btn">
        <button @click="fecharModal" class="continuar">FECHAR</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalExcluirContaMeuPerfil",

  data() {
    return {
      senha: null,
      passwordField: "password",
      imgShow: "/img/icons/hide.png",
    };
  },

  methods: {
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_CONTA_EXCLUIDA", {
        ativado: false,
        mensagem: "Excluida",
        tipo: "",
      });

      this.$router.push("/")
    },
  },
};
</script>


<style lang="scss" scoped>
.ps {
  height: 200px;
}
.modalParabens {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    // width: 812px;
    width: 712px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    // padding: 138px;
    padding: 80px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 24px;
    }

    img {
      margin-bottom: 30px;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }
  }

  &__mensagem {
    padding: 0 45px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p, h2 {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 30px;
      text-align: center;
    }

    h2 {
      font-weight: bold;
    }
  }

  &__btn {
    display: flex;
    align-items: center;

    button {
      width: 210px !important;
      &:nth-child(2) {
        background: transparent;
        color: $laranja;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 24px;
      flex-direction: column;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 260px;
      // margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;
      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>