<template>
	<div class="modal">
		<div class="modal__container">
			<img :src="iconesImgs.iconeFechar" alt="Botão para fechar a janela de exportação" class="btn-fechar"
				@click="$emit('fechaModal', false)" />
			<h1>EXPORTAR DADOS</h1>
			<div>
				<div class="modal__mensagem">
					<p>
						Gestor(a), utilize as bases para incentivar e motivar sua equipe a entregar melhores resultados a cada dia.
					</p>
				</div>
			</div>

			<div class="combo-selects">
				<div class="campo">
					<Multiselect v-model="form.ano" :options="anos" :multiple="false" placeholder="Escolha o ano"
						select-label=" " deselect-label=" " selected-label=" " :searchable="false" :empty="false"
						class="select campo-maior" :allow-empty="false" @input="obterMesesDownload()" />
				</div>
			</div>

			<div class="meses" v-if="form.ano != ''">
				<div v-for="(mes, index) in meses" :key="index">
					<label :for="mes.id" :class="!mesesDownload.some(item => item == mes.id) && 'mes-inativo inativo'">
						<p :class="form.meses.some((item) => item == `${form.ano}-${mes.id}-01`)
							? 'mes mes-selecionado'
							: 'mes'
							">
							{{ mes.mes }}
						</p>
					</label>
					<input type="checkbox" :id="mes.id" :value="`${form.ano}-${mes.id}-01`" v-model="form.meses"
						class="checkbox-nome" />
				</div>
			</div>

			<div>
				<div class="modal__mensagem">
					<p :class="temErro && 'erro'">
						Você deve selecionar no mínimo 1 e no máximo 12 meses.
					</p>
					<p class="msg-download" v-if="inicioDownload">
						Seu download será iniciado automaticamente, aguarde.
					</p>
				</div>
			</div>

			<div class="modal__btn box-botoes">
				<button class="modal__btn__btn-enviar" @click="gerarPlanilhaDownload()">
					EXPORTAR
				</button>
				<a class="btn-download" ref="btnDownload">Fazer download</a>
			</div>
		</div>
	</div>
</template>

<script>
import { downloadPlanilhaPerformance, obterMesesComPerformance } from "@/api/performance";
import { downloadPlanilhaTreinamento, obterMesesComTreinamento } from "@/api/treinamentos";
import Multiselect from "vue-multiselect";
import iconesImgs from "@/utils/icones.js";
import moment from "moment";
export default {
	name: "ModalExportarDados",

	props: ["idAutorizada", "tipoDownload"],

	components: {
		Multiselect,
	},

	data() {
		return {
			inicioDownload: false,
			temErro: false,
			iconesImgs,
			anos: [],
			arquivoResp: "",
			form: {
				ano: "",
				meses: [],
			},
			mesesDownload: [],
			meses: [
				{ id: "01", mes: "Jan" },
				{ id: "02", mes: "Fev" },
				{ id: "03", mes: "Mar" },
				{ id: "04", mes: "Abr" },
				{ id: "05", mes: "Mai" },
				{ id: "06", mes: "Jun" },
				{ id: "07", mes: "Jul" },
				{ id: "08", mes: "Ago" },
				{ id: "09", mes: "Set" },
				{ id: "10", mes: "Out" },
				{ id: "11", mes: "Nov" },
				{ id: "12", mes: "Dez" },
			]
		};
	},

	mounted() {
		this.form.ano = moment().year();
		this.geraAnos();
		this.obterMesesDownload();
	},

	methods: {
		obterMesesDownload() {
			if (this.tipoDownload == "Performance") {
				obterMesesComPerformance(this.form.ano, this.idAutorizada).then(resp => {
					this.mesesDownload = resp.data
				})
			}
			if (this.tipoDownload == "Treinamentos") {
				obterMesesComTreinamento(this.form.ano, this.idAutorizada).then(resp => {
					this.mesesDownload = resp.data
				})
			}
		},

		geraAnos() {
			let total = moment().year() - 2023;
			if (total > 0) {
				for (let i = 0; i <= total; i++) {
					this.anos.push(2023 + i);
				}
			} else {
				this.anos = [moment().year()];
			}
		},
		downloadTreinamento(info) {
			this.inicioDownload = true;
			downloadPlanilhaTreinamento(info).then((resp) => {
				let url = window.URL.createObjectURL(new Blob([resp.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				}));
				this.$refs.btnDownload.href = url;
				this.$refs.btnDownload.download = `Planilha ${this.tipoDownload}.xlsx`;
				setTimeout(() => {
					this.$refs.btnDownload.click();
					this.inicioDownload = false;
				}, 600)
			});
		},
		downloadPerformance(info) {
			this.inicioDownload = true;
			downloadPlanilhaPerformance(info).then((resp) => {
				let url = window.URL.createObjectURL(new Blob([resp.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				}));
				this.$refs.btnDownload.href = url;
				this.$refs.btnDownload.download = `Planilha ${this.tipoDownload}.xlsx`;
				setTimeout(() => {
					this.$refs.btnDownload.click();
					this.inicioDownload = false;
				}, 600)
			});
		},
		gerarPlanilhaDownload() {
			if (this.inicioDownload) return;
			if (this.form.meses.length == 0) {
				this.temErro = true;
				return;
			}
			let meses = this.form.meses
				.toString()
				.split(",")
				.join(";");

			let info = {
				autorizadaId: parseInt(this.idAutorizada),
				periodo: meses,
			};

			if (this.tipoDownload == "Performance") this.downloadPerformance(info);
			if (this.tipoDownload == "Treinamentos") this.downloadTreinamento(info);

		},
	},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.erro {
	color: $erro-1 !important;
	font-weight: bold !important;
}

.btn-fechar {
	filter: brightness(0);
	width: 20px;
	position: absolute;
	top: 9px;
	right: 10px;
	cursor: pointer;
}

.btn-download {
	display: none;
}

.msg-download {
	font-size: 14px;
	color: $erro-1 !important;
}

.meses {
	width: 100%;
	max-width: 350px;
	gap: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 20px;

	.mes {
		width: 71px;
		height: 41px;
		background: $branco;
		border: 1px solid $corVerde;
		border-radius: 5px;
		color: $corVerde;
		font-size: 18px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mes-selecionado {
		background: $corVerde;
		color: $branco;
	}

	.checkbox-nome {
		display: none;
	}

	.mes-inativo {
		pointer-events: none;
		opacity: 0.8;

		p {
			color: $cinza-escuro;
			border-color: $cinza-escuro;
			opacity: 0.6;
		}
	}
}

.box-botoes {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 10px;
}

.modal__container {
	padding: 20px 50px;
	background: $cor-gelo;
	position: relative;

	@media (max-width: 768px) {
		padding: 40px 20px;
		height: auto;
		max-height: 100%;
		overflow-y: visible;
	}
}

.combo-selects {
	width: 100%;
	max-width: 600px;
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 20px;

	p {
		display: block;
	}

	.campo {
		width: 250px;
		margin-bottom: 20px;
		display: grid;
		align-items: center;
		grid-auto-flow: column;
		justify-content: end;
		grid-gap: 5px;
		color: $cinza-interno;
	}
}

.select {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	box-sizing: border-box;
	width: 200px;
	height: 55px;
	padding: 0px;
	color: $textos-login;
	border-radius: 5px;
	outline: none;
	background: #fff;
	margin-right: 20px;

	&::placeholder {
		color: $textos-login;
	}

	&:disabled {
		color: $textos-login;
	}

	&:-webkit-autofill {
		-webkit-text-fill-color: $textos-login;
		transition: background-color 5000s ease-in-out 0s;
		background-color: transparent;
	}
}
</style>
