<template>
  <div class="input-erro-container">
    <slot></slot>
    <transition name="errofade">
      <span class="input-erro-mensagem" v-if="erro != ''">{{ erro }}</span>
      <span class="input-erro-mensagem" v-if="nomeInvalido != null">{{ nomeInvalido }}</span>
      <span class="input-erro-mensagem" v-if="idadeInvalida != null">{{ idadeInvalida }}</span>
      <span class="input-erro-mensagem" v-if="cpfInvalido != null">{{ cpfInvalido }}</span>
      <span class="input-erro-mensagem" v-if="emailInavalido != null">{{ emailInavalido }}</span>
      <span class="input-erro-mensagem" v-if="celularInvalido != null">{{ celularInvalido }}</span>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["erro", "nomeInvalido", "idadeInvalida", "cpfInvalido", "emailInavalido", "celularInvalido"]
};
</script>

<style lang="scss" scoped>
.errofade-enter-active {
  animation: fadeEnter 0.3s;
}
.errofade-leave-active {
  animation: fadeOut 0.3s;
}
.input-erro-container {
  position: relative;
}
.input-erro-mensagem {
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: $erro;
}
</style>
