export default {
  namespaced: true,
  state: {
    filtroExtrato: {
      mes: null,
      ano: null,
    },
    filtroTreinamentos: {
      mes: null,
      ano: null
    }
  },
  mutations: {
    SET_FILTRO_EXTRATO(state, payload) {
      state.filtroExtrato.mes = payload.mes;
      state.filtroExtrato.ano = payload.ano;
    },
    SET_DATA_TREINAMENTOS(state, payload) {
      state.filtroTreinamentos.mes = payload.mes.id;
      state.filtroTreinamentos.ano = payload.ano.id;
    }
  },
  actions: {
    defineMesAnoTreinamentos(context, payload){
      context.commit("SET_DATA_TREINAMENTOS", payload)
    }
  }
};
