<template>
  <div class="modalPolitica">
    <div class="modalPolitica__container">
      <h1>REGULAMENTO DO PROGRAMA TEC CLUB+</h1>

      <div>
        <perfect-scrollbar ref="scroll">
          <div class="modalPolitica__politica">
            <regulamento-geral />
          </div>
        </perfect-scrollbar>
      </div>

      <div class="modalPolitica__btn">
        <button
          v-if="mostrarBotao"
          @click="aceiteRegulamento"
          class="continuar"
        >
          LI E ACEITO O REGULAMENTO
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { utualizarCadastroUsuario } from "@/api/usuario";
import RegulamentoGeral from "@/components/elementos/conteudoRegulamentos/RegulamentoGeral.vue";
export default {
  name: "ModalRegulamento",

  emits: ["aceitoRegulamento"],

  props: {
    mostrarBotao: {
      type: Boolean,
      default: true,
    },
    dadosUsuario: Object,
  },

  components: {
    PerfectScrollbar,
    RegulamentoGeral,
  },

  data() {
    return {
      aceite: false,
    };
  },

  methods: {
    aceiteRegulamento() {
      console.log(this.dadosUsuario);
      const data = new FormData();
      data.append("IdUsuario", this.dadosUsuario.idUsuario);
      data.append("NovaFoto", null);
      data.append("Nome", this.dadosUsuario.nome);
      data.append("CEP", this.dadosUsuario.cep);
      data.append("Endereco", this.dadosUsuario.endereco);
      data.append("Numero", this.dadosUsuario.numero);
      data.append("Complemento", this.dadosUsuario.complemento);
      data.append("Bairro", this.dadosUsuario.bairro);
      data.append("Cidade", this.dadosUsuario.cidade);
      data.append("UF", this.dadosUsuario.uf);
      data.append("PontoReferencia", this.dadosUsuario.pontoReferencia);
      data.append("Cargo", this.dadosUsuario.idCargo);
      data.append("AceiteReceberComunicacao", this.dadosUsuario.aceiteReceberComunicacao);
      this.dadosUsuario.idsAutorizadas.forEach((id) => {
        data.append("IdsAutorizadas", id); // Apenas usuário site
      });
      data.append("IdStatus", this.dadosUsuario.idStatus);
      data.append("AceiteRegulamento", true);

      utualizarCadastroUsuario(data).then(() => {
        this.$store.commit("interacoes/SET_MODAL_ACEITE_DE_REGULAMENTO", {
          ativado: false,
          mensagem: "Regulamento",
          tipo: "",
        });
      });
    },
  },

  created() {
    console.log(this.dadosUsuario);
  },
};
</script>

<style lang="scss" scoped>
.ps {
  height: 200px;
}

.modalPolitica {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;

  @media (max-width: 768px) {
    padding: 0 13px;
  }

  &__container {
    width: 762px;
    background: $branco;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;

    @media (max-width: 768px) {
      width: 100%;
    }

    h1 {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: $grafite;
      margin: 59px 0;

      @media (max-width: 768px) {
        width: 220px;
        font-size: 24px;
        margin-bottom: 23px;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 32px;
      color: $grafite;
      margin-bottom: 30px;
      // padding: 0 45px;
      font-weight: bold;
      width: 100%;

      @media (max-width: 768px) {
        padding: 0 24px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }

    .h1-tec {
      font-size: 23px;
      margin: 59px 20px;
      line-height: 25px;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  &__politica {
    padding: 0 45px;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grafite;
      margin-bottom: 30px;
    }

    span {
      font-weight: bold;
    }

    table {
      margin-bottom: 30px;
      width: 100%;
      border: 1px solid $grafite;
      color: $grafite;

      th,
      td {
        padding: 20px;
        border: 1px solid $grafite;
        text-align: center;
        vertical-align: middle;

        @media (max-width: 768px) {
          padding: 5px;
        }
      }
    }

    .azul {
      color: #0070c0;
      text-decoration: underline;
      font-weight: normal;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 24px;
    }

    .continuar {
      border: none;
      background: $bg-header-cadastro;
      width: 260px;
      margin: 20px 0 8px 0;
      height: 49px;
      border-radius: 5px;
      color: $texto-botao;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;

      @media (max-width: 768px) {
        width: 100%;
        background: $bg-header-cadastro;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .voltar {
      border: none;
      background: transparent;
      width: 260px;
      margin: 16px 0 42px 0;
      height: 49px;
      border-radius: 5px;
      color: $grafite;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      transition: hover 0.3;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 30px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
