<template>
	<div class="rankings">
		<div class="box-ranking">
			<div class="nacional">
				<img :src="iconesImgs.iconeRanking" />


				<p>Ranking Nacional</p>
				<h1>
					{{ ranking.rankingPerformance.posicaoNacional }}º<span class="barra">/</span><span class="geral">{{
						ranking.rankingPerformance.totalNacional }}º</span>
				</h1>
			</div>
			<div class="regional">
				<img :src="iconesImgs.iconeRanking" />
				<p>Ranking Regional</p>
				<h1>
					{{ ranking.rankingPerformance.posicaoRegional }}º<span class="barra">/</span><span class="geral">{{
						ranking.rankingPerformance.totalRegional }}º</span>
				</h1>
			</div>
		</div>

		<h2 class="h2">
			{{ ultimaDataDeAtualizacao }}
		</h2>

		<div v-if="performances.length > 0">
			<h3 class="h3">Percentual de atingimento das metas</h3>
			<barra-de-progresso :performances="performances" />
		</div>

	</div>
</template>

<script>
import iconesImgs from "@/utils/icones.js";
import { buscarRankings, obterPerformance, ultimaAtualizacaoPerformance } from "@/api/performance";
import BarraDeProgresso from "@/components/estrutura/BarraDeProgresso/BarraDeProgresso.vue";
import { mapState } from "vuex";
export default {
	name: "Rankings",
	components: {
		BarraDeProgresso
	},
	data() {
		return {
			iconesImgs,
			ranking: {
				rankingPerformance: {
					posicaoNacional: 0,
					posicaoRegional: 0,
					totalNacional: 0,
					totalRegional: 0
				}
			},
			ultimaDataDeAtualizacao: null,
			performances: [],
		};
	},

	computed: {
		...mapState({
			autorizadaAtiva: state => state.usuario.autorizadaAtiva
		})
	},

	mounted() {
		this.buscarRanking();
		this.obterPerformance();
		this.buscarUltimaDataAtualizacao();
	},

	watch: {
		autorizadaAtiva() {
			this.obterPerformance();
			this.buscarRanking();
		}
	},

	methods: {
		buscarUltimaDataAtualizacao() {
			ultimaAtualizacaoPerformance().then(resp =>{
				this.ultimaDataDeAtualizacao = resp.data;
			});
		},
		buscarRanking() {
			buscarRankings(this.autorizadaAtiva).then(resp => {
				this.ranking = resp.data;
			});
		},
		obterPerformance() {
			obterPerformance(this.autorizadaAtiva).then(resp => {
				this.performances = resp.data.performances;
			})
		},
	}
};
</script>

<style lang="scss" scoped>
.rankings {
	.h2 {
		font-size: 16px;
		color: $laranja-escuro;
		font-weight: 100;
		width: 100%;
		text-align: center;
		margin-top: 16px;

		.data-atualizacao {
			font-weight: bold;
		}

		@media(max-width: 768px) {
			font-size: 14px;
		}
	}

	.h3 {
		font-size: 16px;
		font-weight: bold;
		margin-top: 32px;
		margin-bottom: 16px;

		@media (max-width: 768px) {
			font-size: 14px;
			text-align: center;
			font-weight: bold;
		}
	}


	.box-ranking {
		width: 400px;
		display: flex;
		justify-content: center;
		margin-top: 69px;

		@media (max-width: 768px) {
			margin: 30px;
			justify-content: left;
		}

		.nacional,
		.regional {
			background-color: $azul-claro;
			width: 192px;
			height: 199px;
			margin: 5px;
			padding: 20px;
			border-radius: 5px;

			p {
				font-size: 16px;
				margin-top: 14px;
			}

			h1 {
				color: $laranja-escuro;
				font-size: 41px;
				font-weight: 600;
				margin-top: 40px;

				.barra {
					font-size: 31px;
					color: #bcbcbc;
				}

				.geral {
					font-size: 29px;
					color: #bcbcbc;
				}
			}

			@media (max-width: 768px) {
				width: 146px;
				height: 150px;
				padding: 10px 14px;

				p {
					font-size: 12px;
					margin-top: 14px;
				}

				h1 {
					font-size: 40px;
					margin-top: 20px;
				}
			}
		}
	}
}
</style>
